import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-year-calendar',
  templateUrl: './year-calendar.component.html',
  styleUrls: ['./year-calendar.component.scss']
})
export class YearCalendarComponent implements OnInit {
    year: any = null;

    constructor(
        private httpClient: HttpClient,
        private titleService: Title,
    ) { }

    ngOnInit(): void {
        this.titleService.setTitle("Årskalender");
        this.getYear();
    }

    getYear(year = null) {
        let extra = "";
        if (year != null)
        {
            extra = "?year=" + year;
        }
        this.httpClient.get("https://ng.renner.se/year_calendar.api.php" + extra).subscribe((data)=>{
            console.log(data);
            this.year = data;
        });
    }

}
