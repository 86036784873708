<mat-card appearance="outlined" class="widget">
    <mat-card-title class="card-title">Kalendar</mat-card-title>
    <mat-card-content>
        <div class="month">
            <div class="days">
                <div class="prev-year navigation" (click)="getOtherMonth(month.prevYear.year, month.prevYear.month)">&lt;&lt;</div>
                <div class="year-header">{{ month?.year }}</div>
                <div class="next-year navigation" (click)="getOtherMonth(month.nextYear.year, month.nextYear.month)">&gt;&gt;</div>
                <div class="prev-month navigation" (click)="getOtherMonth(month.prevMonth.year, month.prevMonth.month)">&lt;&lt;</div>
                <div class="month-header">{{ month?.name }}</div>
                <div class="next-month navigation" (click)="getOtherMonth(month.nextMonth.year, month.nextMonth.month)">&gt;&gt;</div>
                <div>&nbsp;</div>
                <div class="day-header">M</div>
                <div class="day-header">T</div>
                <div class="day-header">O</div>
                <div class="day-header">T</div>
                <div class="day-header">F</div>
                <div class="day-header">L</div>
                <div class="day-header red">S</div>
                <ng-container *ngFor="let week of month?.weeks">
                    <div class="week-number">{{ week.days[0].week }}</div>
                    <div *ngFor="let i of [].constructor(week.days[0].wday - 1);" class="day"></div>
                    <div *ngFor="let day of week?.days" [ngClass]="{'red' : day.wday == 7 || day.holiday != '', 'underline' : day.holiday != '', 'today': isToday(day.day)}" matTooltip="{{day.holiday}}" matTooltipClass="tooltipClass" class="day">
                        {{ day.day }}
                    </div>    
                </ng-container>
            </div>
        </div>
    </mat-card-content>
</mat-card>
