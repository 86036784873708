import { AuthService } from '../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-anton-age',
  templateUrl: './anton-age.component.html',
  styleUrls: ['./anton-age.component.scss']
})
export class AntonAgeComponent implements OnInit {
    public age: any;

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
    ) { 

    }

    ngOnInit(): void {
        this.getAge();
    }

    getAge()
    {
        this.httpClient.get("https://ng.renner.se/age.api.php?name=Anton").subscribe((data)=>{
            this.age = data;
        });
    }

}
