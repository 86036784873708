import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ScrollDetectionService } from '../../services/scroll-detection.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

    movies: any = [];
    screenHeight: number;
    screenWidth: number;
    lastScrollTop: number = 0;
    headerTop: string = "0px";
    swipeCoord: [number, number]
    swipeTime: any
    loadedEntries: number = 0;
    fetchingEntries: boolean = false;
    scrollPos: number = 0;
    scrolling: boolean = false;

    constructor(
        private httpClient: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private scrollService: ScrollDetectionService,
    ) {}

    ngOnInit(): void {
        this.getMovies();

        this.scrollService.getScrollEvent().subscribe(event => {
            let scrollRemaining = event?.target.scrollHeight - event?.target.clientHeight - event?.target.scrollTop;
            if (this.router.url == '/movies/news')
            {
                if (!this.scrolling)
                {
                    this.scrollPos = event?.target.scrollTop;
                }
                else
                {
                    if (this.scrollPos == event.target.scrollTop)
                    {
                        this.scrolling = false;
                    }
                    else
                    {
                        var element = document.getElementById('container');
                        element.scrollTop = this.scrollPos;
                    }
                }
            }
            if (scrollRemaining < 600 && this.router.url == '/movies/news')
            {
                this.getMovies();
            }
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd && event.url == '/movies/news')
            {
                this.scrolling = true;
                // Restore scroll pos
                var element = document.getElementById('container');
                element.scrollTop = this.scrollPos;
            }
        });
    }

    getMovies() {
        console.log(this.fetchingEntries);
        if (!this.fetchingEntries)
        {
            this.fetchingEntries = true;
            console.log("length: " + this.movies.length);
            this.httpClient.get("https://ng.renner.se/movie.api.php?news&limit=20&offset=" + this.movies.length).subscribe((data)=>{
                console.log(data);
                this.movies = this.movies.concat(data);
                console.log(this.movies);
                this.fetchingEntries = false;
            });
        }
    }

    setTitle(newTitle: string)
    {
        this.titleService.setTitle(newTitle);
    }

    scrollToTop() {
        var element = document.getElementById('container');
        element.scrollTop = 0;
    }
}
