import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import * as moment from "moment";
import { LocalStorageService } from './local-storage.service';
import { StreamState } from '../interfaces/stream-state';
import { Platform } from '@angular/cdk/platform';
import { HttpClient } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
    providedIn: "root"
})

export class AudioService {
    private stop$ = new Subject();
    private audioObj = new Audio();
    audioEvents = [
        "ended",
        "error",
        "play",
        "playing",
        "pause",
        "timeupdate",
        "volumeupdate",
        "canplay",
        "loadedmetadata",
        "loadstart"
    ];

    private eventObservable: BehaviorSubject<any> = new BehaviorSubject({});
    private state: StreamState = {playing: false, readableCurrentTime: "", readableDuration: "", duration: 0, currentTime: 0, volume: 0, canplay: false, error: false};
    private stateChange: BehaviorSubject<StreamState> = new BehaviorSubject(this.state);
    private apiUrl = 'https://ng.renner.se/get_audio.php';

    constructor(
        private localStorageService: LocalStorageService,
        public platform: Platform,
        private http: HttpClient,
        private sanitizer: DomSanitizer,
    ) {
        var tmp_state = this.localStorageService.getStreamState();

        if (tmp_state == null)
        {
            this.resetState();
        }
        else
        {
            this.state = tmp_state;
        }

        this.volume(this.state.volume);

        const handler = (event: Event) => {
            this.updateStateEvents(event);
            this.eventObservable.next(event);
        };
        this.addEvents(this.audioObj, this.audioEvents, handler);

    }

    getState(): Observable<StreamState> {
        return this.stateChange.asObservable();
    }

    getEvent(): Observable<any> {
        return this.eventObservable.asObservable();
    }

    private addEvents(obj, events, handler) {
        events.forEach(event => {
            obj.addEventListener(event, handler);
        });
    }

    playStream(url, play = true, seekTo) {
        let paused = this.audioObj.paused;
        this.http.get(`${url}`, { responseType: 'blob' }).subscribe((blob) => {
            const objectUrl = URL.createObjectURL(blob);
            this.audioObj.src = objectUrl;
            this.audioObj.load();
            if (this.state.playing == true)
            {
                this.audioObj.play();
            }
            var activeTrack = this.localStorageService.getPlayerActiveTrack();
            var time = this.localStorageService.getPlayerCurrentTime();
            if (seekTo != null)
            {
                time = seekTo;
            }
            if (activeTrack && activeTrack.url == url && time != null)
            {
                this.seekTo(time);
            }
        });
    }

    play() {
        this.state.playing = true;
        this.localStorageService.storeStreamState(this.state);
        this.audioObj.play();
    }

    pause() {
        this.state.playing = false;
        this.localStorageService.storeStreamState(this.state);
        this.audioObj.pause();
    }

    stop() {
        this.stop$.next(void 0);
    }

    seekTo(seconds) {
        this.localStorageService.storePlayerCurrentTime(seconds);
        this.audioObj.currentTime = seconds;
    }

    volume(value) {
        if (this.platform.ANDROID || this.platform.IOS)
        {
            this.audioObj.volume = 1;
        }
        else
        {
            this.audioObj.volume = value / 100;
            this.localStorageService.storeStreamState(this.state);
        }
    }

    formatTime(time: number, format: string = "m:ss") {
        const momentTime = time * 1000;
        return moment.utc(momentTime).format(format);
    }

    private updateStateEvents(event: Event): void {
        switch (event.type) {
            case "canplay":
                this.state.duration = this.audioObj.duration;
                this.state.readableDuration = this.formatTime(this.state.duration);
                this.state.canplay = true;
                break;
            case "playing":
                break;
            case "pause":
                break;
            case "timeupdate":
                this.state.currentTime = this.audioObj.currentTime;
                this.state.readableCurrentTime = this.formatTime(
                    this.state.currentTime
                );
                this.localStorageService.storePlayerCurrentTime(this.state.currentTime);
                break;
            case "volumeupdate":
                this.state.volume = this.audioObj.volume * 100;
                this.localStorageService.storeStreamState(this.state);
                break;
            case "error":
                this.resetState();
                this.state.error = true;
                break;
        }
        this.stateChange.next(this.state);
    }

    private resetState() {
        this.state = {
            playing: false,
            readableCurrentTime: '',
            readableDuration: '',
            duration: 0,
            currentTime: 0,
            canplay: false,
            volume: 50,
            error: false
        };
    }
}