<div class="sunrise-container">
    <div class="sunrise-h1">Solens upp- och nedgång i Göteborg/Bäckedalen</div>
    <br>
    <table class="sunrises" *ngIf="some_sunrises">
        <tr>
            <th>Vecka</th>
            <th>Dag</th>
            <th>Månad</th>
            <th>Veckodag</th>
            <th>Soluppgång</th>
            <th>Solnedgång</th>
        </tr>
        <tr class="sunrise" *ngFor="let a_sunrise of some_sunrises; let i = index">
            <td [ngStyle]="(a_sunrise.ordinal_wday == 7) && {'color': 'red'}" *ngIf="some_sunrises[i-1] === undefined || a_sunrise.week != some_sunrises[i-1].week" class="week">v.{{ a_sunrise.week }}</td>
            <td [ngStyle]="(a_sunrise.ordinal_wday == 7) && {'color': 'red'}" *ngIf="some_sunrises[i-1] && a_sunrise.week == some_sunrises[i-1].week"></td>
            <td [ngStyle]="(a_sunrise.ordinal_wday == 7) && {'color': 'red'}">{{ a_sunrise.day }}</td>
            <td [ngStyle]="(a_sunrise.ordinal_wday == 7) && {'color': 'red'}">{{ a_sunrise.month }}</td>
            <td [ngStyle]="(a_sunrise.ordinal_wday == 7) && {'color': 'red'}">{{ a_sunrise.wday }}</td>
            <td [ngStyle]="(a_sunrise.ordinal_wday == 7) && {'color': 'red'}">{{ a_sunrise.sunrise }}</td>
            <td [ngStyle]="(a_sunrise.ordinal_wday == 7) && {'color': 'red'}">{{ a_sunrise.sunset }}</td>
        </tr>
    </table>
</div>