import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ScrollDetectionService {
    private scrollDirection: string = "";
    private scrollEvent: any = null;
    private scrollUpdate: BehaviorSubject<string> = new BehaviorSubject(this.scrollDirection);
    private scrollEventUpdate: BehaviorSubject<any> = new BehaviorSubject(this.scrollEvent);
    lastScrollTop: number = 0;


    constructor(

    ) {

    }

    scrollDetected(event)
    {
        this.scrollEventUpdate.next(event);
        let st = event.target.scrollTop;

        if (st > this.lastScrollTop)
        {
            this.scrollUpdate.next("down");
        }
        else
        {
            this.scrollUpdate.next("up");
        }
        this.lastScrollTop = st;
    }

    getScrollUpdate(): Observable<string> {
        return this.scrollUpdate.asObservable();
    }

    getScrollEvent(): Observable<any> {
        return this.scrollEventUpdate.asObservable();
    }
}
