import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { AuthService } from './services/auth.service';
import { AutomationModule } from './automation/automation.module';
import { BlogModule } from './blog/blog.module';
import { BooksModule } from './books/books.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule } from './calendar/calendar.module';
import { CustomRouteReuseStrategy } from './app-routing/custom-route-reuse-strategy';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { GalleryModule } from './gallery/gallery.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { MaterialModule } from './material.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MoviesModule } from './movies/movies.module';
import { PlayerComponent } from './music-player/player/player.component';
import { PlaylistComponent } from './music-player/playlist/playlist.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RecipesModule } from './recipes/recipes.module';
import { RecordsModule } from './records/records.module';
import { RouteReuseStrategy } from '@angular/router';
import { TemperatureModule } from './temperature/temperature.module';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { ErrorInterceptor } from './services/error-interceptor-service.service';
import { UserLoginComponent } from './user-login/user-login.component';
import { StartPageComponent } from './start-page/start-page.component';

@NgModule({
    declarations: [
        AppComponent,
        PlayerComponent,
        PlaylistComponent,
        UserLoginComponent,
        StartPageComponent,
    ],
    imports: [
        AppRoutingModule,
        AutomationModule,
        BlogModule,
        BooksModule,
        BrowserModule,
        BrowserAnimationsModule,
        CalendarModule,
        FontAwesomeModule,
        FormsModule,
        GalleryModule,
        HttpClientModule,
        MaterialModule,
        MatFormFieldModule,
        MatInputModule,
        MoviesModule,
        LoadingBarHttpClientModule,
        ReactiveFormsModule,
        RecipesModule,
        RecordsModule,
        TemperatureModule
    ],
    providers: [
        AuthService,
        MaterialModule,
        Title,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'sv-SE'
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
