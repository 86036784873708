import { Component } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { faThermometerHalf } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-current-temperature',
    templateUrl: './current-temperature.component.html',
    styleUrls: ['./current-temperature.component.scss']
})
export class CurrentTemperatureComponent {
    currentTemperature: string;
    pollingData: any;
    public faThemometerHalf = faThermometerHalf;

    constructor(
        private httpClient: HttpClient,
        private titleService: Title
    ) {
        this.titleService.setTitle("Temperaturen i Bäckedalen"); 
        this.poll().subscribe(reponse => {
            console.log('Request completed successfully');
          });
    }

    poll(): Observable<string> {
        return new Observable<string>(observer => {
    
            const subscription = timer(0, 60000).pipe(
                switchMap(() => this.makeRequest())).subscribe(response => {
                    this.currentTemperature = response[1];
                })
        });
    }

    makeRequest(): Observable<any> {
        return this.httpClient.get("https://ng.renner.se/temperature.api.php?current");
    }
}
