import { Component, OnInit } from '@angular/core';
import { HostListener } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ScrollDetectionService } from '../../services/scroll-detection.service';

@Component({
  selector: 'app-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.scss']
})
export class BrowseComponent implements OnInit {
    screenHeight: number;
    screenWidth: number;
    public firstLetter: string;
    public previousLetter: string;
    public nextLetter: string;
    albums: any;
    headerTop: string = "0px";
    public letters = "#ABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ".split("");
    constructor(
        private httpClient: HttpClient,
        private route: ActivatedRoute,
        private titleService: Title,
        private scrollService: ScrollDetectionService,
    ) {
        this.getScreenSize();
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            let letter = "A";
            this.firstLetter = params.get("letter");
            console.log(this.firstLetter);
            if (this.firstLetter)
            {
                letter = this.firstLetter;
            }
            else if (this.firstLetter = localStorage.getItem("records-firstLetter"))
            {
                letter = this.firstLetter;
            }
            console.log(letter);
            this.getAlbums(letter);
            this.firstLetter = letter;
            this.titleService.setTitle("Skivor på " + letter);
            this.previousLetter = this.getPreviousLetter();
            this.nextLetter = this.getNextLetter();
        });

        this.scrollService.getScrollUpdate().subscribe(direction => {
            console.log("scrolling: " + direction);
            switch(direction)
            {
                case "up":
                    this.headerTop = "0px";
                    break;
                case "down":
                    this.headerTop = "-110px";
                    break;
            }
        });
    }

    ngAfterViewChecked()
    {
    }

    getAlbums(letter: string) {
        this.httpClient.get("https://ng.renner.se/album.api.php?firstLetter=" + encodeURIComponent(letter)).subscribe((data)=>{
            console.log(data);
            this.albums = data;
            localStorage.setItem("records-firstLetter", letter);
            this.scrollToTop();
        });
    }

    getPreviousLetter()
    {
        let idx = this.letters.indexOf(this.firstLetter);
        console.log(idx);
        if (idx > 0)
        {
            return this.letters[idx-1];
        }
        else
        {
            return null;
        }
    }

    getNextLetter()
    {
        let idx = this.letters.indexOf(this.firstLetter);
        console.log(idx);
        if (idx < (this.letters.length - 1))
        {
            return this.letters[idx+1];
        }
        else
        {
            return null;
        }
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenHeight = window.innerHeight;
        let mainContainer = document.getElementById("container");
        let scrollbarWidth = mainContainer.offsetWidth - mainContainer.clientWidth;
        this.screenWidth = window.innerWidth - scrollbarWidth;
        console.log(this.screenHeight, this.screenWidth, scrollbarWidth);
    }

    getImageWidth() {
        let image_width = 300;
        let mainContainer = document.getElementById("container");
        let no_images = Math.round(this.screenWidth / image_width);

        image_width = this.screenWidth / no_images;

        return image_width;
    }

    getImageHeight() {
        let image_width_start = 300;
        let image_height_start = 300;
        let no_images = Math.round(this.screenWidth / image_width_start);

        let image_width = this.screenWidth / no_images;

        let ratio = image_width_start / image_width;

        return image_height_start / ratio;
    }

    setTitle(newTitle: string)
    {
        this.titleService.setTitle(newTitle);
    }

    scrollToTop() {
        var element = document.getElementById('container');
        element.scrollTop = 0;
    }

}
