<div class="director-wrapper">
    <div class="director">{{ director_movies?.director.name }}</div>
</div>
<div class="movie-wrapper">
    <div *ngFor="let movie of sortMoviesByYear(); let i = index">
        <br>
        <div class="movie"><a [routerLink]="['/movie', movie.id]">{{ movie?.title }} ({{ movie?.year}})</a></div>
        <div><a [routerLink]="['/movie', movie.id]"><img src="{{ movie?.cover_url }}" alt="" class="movie-cover" /></a></div>
        <br>
    </div>
</div>