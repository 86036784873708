import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import { HttpClient } from '@angular/common/http';
import { ImageCropperComponent, ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { ImageTransform } from 'ngx-image-cropper';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { of } from 'rxjs';

@Component({
  selector: 'app-creator',
  templateUrl: './creator.component.html',
  styleUrls: ['./creator.component.scss'],
})

export class CreatorComponent implements OnInit {
    year: any = null;
    years: Array<number> = Array.from({ length:  2030 - 2000 + 1 }, (_, i) => 2000 + i);
    selectedYear: number = 2025;
    selectFile: Array<any> = [];
    layout: string | null = null;
    layoutThumbnail: string | null = null;
    layoutLargeThumbnail: string | null = null;
    font: any;
    b64_font: any;
    max: number = 5;
    celebrations: Array<string> = [];
    celebrations_placeholder: Array<string> = [];
    listNames: Array<string> = ['Lista 1', 'Lista 2', 'Lista 3', 'Lista 4', 'Lista 5', 'Lista 6', 'Lista 7'];
    current_idx:  number | null = null;
    scale = 1;
    transformDefault: ImageTransform = { 
        translateUnit: 'px' 
    };
    transform: ImageTransform = this.transformDefault;
    rotation?: number;
    canvasRotation = 0;
    hidden = false;
    showCropper = false;
    cropperDefault = { x1: 0,
                x2: 373.693, 
                y1: 0, 
                y2: 373.693 
            };
    cropper = this.cropperDefault;
    imageChangedEvent: Event | null = null;
    croppedImage: SafeUrl = '';

    show_year_with_name_of_month = false;
    show_holidays = true;
    show_flagdays = true;
    show_namesdays = true;
    show_moon_phases = true;

    colour_weekdays = '#b3b3b3';
    colour_weeknumbers = '#b3b3b3';
    colour_grid = '#b3b3b3';

    namelist_columns = 4;
    celebrations_columns = 1;
    available_fonts = new Array(
        { value: 'Achafsex', name: 'A Charming Font', ttf: 'Achafsex-normal.ttf', compensate: 0 },
        { value: 'almosnow', name: 'Almonte Snow', ttf: 'almosnow-normal.ttf', compensate: 0 },
        { value: 'ARCADE', name: 'Arcade', ttf: 'ARCADE-normal.ttf', compensate: 0 },
        { value: 'arialbd', name: 'Arial Bold', ttf: 'arialbd-normal.ttf', compensate: 0 },
        { value: 'bauhaush', name: 'Bauhaus 93', ttf: 'bauhaush-normal.ttf', compensate: 0 },
        { value: 'BookmanOldStyle', name: 'Bookman Old Style', ttf: 'BookmanOldStyle-normal.ttf', compensate: 0 },
        { value: 'croisntn', name: 'Croissant', ttf: 'croissant-normal.ttf', compensate: 0 },
        { value: 'DarkCrystalScript', name: 'Dark Crystal Script', ttf: 'DarkCrystalScript-normal.ttf', compensate: 0 },
        { value: 'decoder', name: 'Decoder', ttf: 'decoder-normal.ttf', compensate: 0 },
        { value: 'Digiface', name: 'Digiface', ttf: 'Digiface-normal.ttf', compensate: 0 },
        { value: 'Flubber', name: 'Flubber', ttf: 'Flubber-normal.ttf', compensate: 0 },
        { value: 'frfm721k', name: 'Franklin Gothic Medium', ttf: 'frfm721k-normal.ttf', compensate: 0 },
        { value: 'Galleria', name: 'Galleria', ttf: 'Galleria-normal.ttf', compensate: -3 },
        { value: 'helvetica', name: 'Helvetica', ttf: 'helvetica-normal.ttf', compensate: 0 },
        { value: 'hillock', name: 'Hillock', ttf: 'hillock-normal.ttf', compensate: 0 },
        { value: 'Karaoke', name: 'Karaoke', ttf: 'Karaoke-normal.ttf', compensate: 0 },
        { value: 'kr', name: 'Kringle', ttf: 'kringle-normal.ttf', compensate: 5 },
        { value: 'MarlboroRegular', name: 'Marlboro Regular', ttf: 'MarlboroRegular-normal.ttf', compensate: 0 },
        { value: 'mistral', name: 'Mistral', ttf: 'mistral-normal.ttf', compensate: 0 },
        { value: 'neurochr', name: 'Neurochrome', ttf: 'Neurochrome-normal.ttf', compensate: 0 },
        { value: 'orbitbn', name: 'Orbit-B BT', ttf: 'orbitbn-normal.ttf', compensate: 0 },
        { value: 'Papyrus', name: 'Papyrus', ttf: 'Papyrus-normal.ttf', compensate: 0 },
        { value: 'PHANTOMSTENCIL', name: 'Phantom Stencil', ttf: 'PHANTOMSTENCIL-normal.ttf', compensate: 0 },
        { value: 'Phutura', name: 'Phutura', ttf: 'Phutura-normal.ttf', compensate: -2 },
        { value: 'Princetown', name: 'Princetown', ttf: 'Princetown-normal.ttf', compensate: 0 },
        { value: 'PumpTrilineD', name: 'Pump Triline D', ttf: 'PumpTrilineD-normal.ttf', compensate: 0 },
        { value: 'PunchLabelRegular', name: 'Punch Label Regular', ttf: 'PunchLabelRegular-normal.ttf', compensate: 0 },
        { value: 'riot_act_2', name: 'Riot Act 2', ttf: 'riot_act_2-normal.ttf', compensate: 0 },
        { value: 'RosewoodRegular', name: 'Rosewood Regular', ttf: 'RosewoodRegular-normal.ttf', compensate: 0 },
        { value: 'TimesNewRoman', name: 'Times New Roman', ttf: 'TimesNewRoman-normal.ttf', compensate: 0 },
        { value: 'Tranceform', name: 'Tranceform', ttf: 'Tranceform-normal.ttf', compensate: 0 },
        { value: 'TRATS', name: 'TRATS', ttf: 'TRATS-normal.ttf', compensate: 0 },
        { value: 'vectroic', name: 'Vectroic', ttf: 'vectroic-normal.ttf', compensate: -2 },
        { value: 'Vera', name: 'Vera', ttf: 'Vera-normal.ttf', compensate: 0 },
        { value: 'vinet', name: 'Vinet', ttf: 'vinet-normal.ttf', compensate: -3 },
    );

    layouts = [
        { value: 'portrait', enabled: true, name: 'A4 Stående', thumbnail: 'layout_portrait.jpg', thumb_large: 'layout_portrait_big.jpg' },
        { value: 'kristina', enabled: true, name: 'A4 Stående Rundade Hörn', thumbnail: 'layout_portrait_rounded.jpg', thumb_large: 'layout_portrait_rounded_big.jpg' },
        { value: 'landscape', enabled: true, name: '2 x A4 Liggande', thumbnail: 'layout_landscape.jpg', thumb_large: 'layout_landscape_big.jpg' },
        { value: 'namelist', enabled: true, name: 'A4 Månadsplanering', thumbnail: 'layout_namelist.jpg', thumb_large: 'layout_namelist_big.jpg' },
        { value: 'namelist_tall', enabled: true, name: '2 x A4 Månadsplanering', thumbnail: 'layout_namelist_tall.png', thumb_large: 'layout_namelist_tall_big.png' },
        { value: 'namelist_landscape', enabled: true, name: 'A4 Månadsplanering Liggande', thumbnail: 'layout_namelist_landscape.jpg', thumb_large: 'layout_namelist_landscape_big.jpg' },
        { value: 'namelist_saddle_stitch', enabled: true, name: 'A5 Månadsplanering Filofax', thumbnail: 'layout_name_list_saddle_stitch.jpg', thumb_large: 'layout_name_list_saddle_stitch_big.jpg' },
        { value: 'kenneth', enabled: true, name: 'A3 Årsplanering', thumbnail: 'layout_planering.jpg', thumb_large: 'layout_planering_big.jpg' },
        { value: 'anders', enabled: false, name: '27,5 x 15 cm Flerårs-planering', thumbnail: 'layout_yearplanner.jpg', thumb_large: 'layout_yearplanner_big.jpg' }
    ]

    months = [
        { name: "Januari", file: null, inputFile: null, transform: this.transformDefault },
        { name: "Februari", file: null, inputFile: null, transform: this.transformDefault },
        { name: "Mars", file: null, inputFile: null, transform: this.transformDefault },
        { name: "April", file: null, inputFile: null, transform: this.transformDefault },
        { name: "Maj", file: null, inputFile: null, transform: this.transformDefault },
        { name: "Juni", file: null, inputFile: null, transform: this.transformDefault },
        { name: "Juli", file: null, inputFile: null, transform: this.transformDefault },
        { name: "Augusti", file: null, inputFile: null, transform: this.transformDefault },
        { name: "September", file: null, inputFile: null, transform: this.transformDefault },
        { name: "Oktober", file: null, inputFile: null, transform: this.transformDefault },
        { name: "November", file: null, inputFile: null, transform: this.transformDefault },
        { name: "December", file: null, inputFile: null, transform: this.transformDefault },
    ];

    constructor(
        private httpClient: HttpClient,
    ) {
        this.font = this.available_fonts[13];
        this.celebrations_placeholder[0] = "1969-01-18: Jimmy fyller %Y år\n1970-01-19: Någon fyller %Y år\n1969-01-18: Något mer händer här\n2000-01-18: %Y-års jubiléum";
        this.celebrations_placeholder[1] = "1988-01-20: Aslan fyller %Y år\n2003-01-21: Tjoho fyller %Y år\n1970-01-20: Ett test till";
    }

    ngOnInit(): void {
        this.layout = 'portrait';
        this.layoutThumbnail = this.layouts.find(l => l.value == this.layout).thumbnail;
        this.layoutLargeThumbnail = this.layouts.find(l => l.value == this.layout).thumb_large;
    }

    setLayoutThumbnail(image, largeImage) {
        this.layoutThumbnail = image;
        this.layoutLargeThumbnail = largeImage;
    }

    setMaxColumns(event) {
        if (event.value == 'namelist_landscape')
        {
            this.max = 6;
            this.celebrations_columns = this.namelist_columns;
        }
        else if (event.value == 'namelist' || event.value == 'namelist_tall')
        {
            this.max = 5;
            this.celebrations_columns = this.namelist_columns;
        }
        else if (event.value == 'namelist_saddle_stitch')
        {
            this.max = 4;
            this.celebrations_columns = this.namelist_columns;            
        }
        else
        {
            this.celebrations_columns = 1;
        }
    }

    setCelebrationsColumns(event) {
        this.celebrations_columns = this.namelist_columns;
    }

    loadFont() {
        if (this.font.value != 'helvetica')
        {    
            this.httpClient.get('app/calendar/creator/fonts/' + this.font.value + '.b64', {responseType: 'text'}).subscribe((data)=>{
                this.b64_font = data;
            });
        }
    }

    addFont(doc) {
        if (this.font.value != 'helvetica')
        {
            doc.addFileToVFS(this.font.value + '.ttf', this.b64_font);
            doc.addFont(this.font.value + '.ttf', this.font.value, 'normal');
        }
    }

    parseCelebrations() {
        for (let i = 0; i < this.celebrations_columns; i++) {
            if (this.celebrations[i] != null)
            {
                let rows = this.celebrations[i].split('\n');
                for (let row of rows)
                {
                    let [date, text] = row.split(':');
                    let [year, month, day] = date.split('-');

                    text = text.trim().replace('%Y', String(parseInt(this.year.year) - parseInt(year)));

                    let month_index = parseInt(month) - 1;
                    let day_index = parseInt(day) - 1;

                    let cel_day = this.year.months[month_index].days.find(d => d.day_of_month == day && d.padding == false);
                    console.log(cel_day);
                    if (cel_day.celebration == null)
                    {
                        cel_day.celebration = [];
                    }
                    if (cel_day.celebration[i] == null)
                    {
                        cel_day.celebration[i] = [];
                    }
                    cel_day.celebration[i].push(text);
                }
            }
        }
        console.log(this.year)
    }
    
    generatePDF() {
        let padding = "";
        switch(this.layout)
        {
            case 'portrait':
            case 'kristina':
            case 'landscape':
                padding = "&padding";
                break;
            default:
                break;
        }
        
        // Create a new PDF document.
        this.httpClient.get("https://renner.se/calendar/api/year.php?year=" + this.selectedYear + padding, {responseType: 'json'}).subscribe((data)=>{
            this.year = data;
            this.parseCelebrations();
            switch(this.layout) {
                case 'portrait':
                    this.createPortrait();
                    break;
                case 'kristina':
                    this.createPortraitRounded();
                    break;
                case 'landscape':
                    this.createLandscape();
                    break;
                case 'namelist':
                    this.createNameList();
                    break;
                case 'namelist_tall':
                    this.createNameListTall();
                    break;
                case 'namelist_landscape':
                    this.createNameListLandscape();
                    break;
                case 'namelist_saddle_stitch':
                    this.createNameListSaddleStitch();
                case 'kenneth':
                    this.createYearPlanner();
                default:
                    break;
            }
        });
	}

    createPortrait()
    {
        // Choose font
        let font_name = 'helvetica';
        let font_type = 'bold';
        var doc = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts:true
        });
        
        const xtot = 210;
        const ytot = 297;

        const xmin = 5;
        const ymin = 5;

        const xmax  = xtot - xmin;
        const ymax  = ytot - ymin;

        this.addFont(doc);

        const row1 = "Kalender";
        const row2 = "" + this.year.year;
        const copy = "Creative Commons";

        doc.setFontSize(56 + this.font.compensate);
        doc.setFont(this.font.value, 'normal');
        doc.text(row1, xmin + (xmax - xmin) / 2, ymin + (ymax - ymin) / 2 - 27, {align: 'center'});
        doc.text(row2, xmin + (xmax - xmin) / 2, ymin + (ymax - ymin) / 2, {align: 'center'});

        doc.setFont(font_name, font_type);
        doc.setFontSize(9);
        doc.text(copy, xmin + (xmax - xmin) / 2, ymax - 5, {align: 'center'});
        doc.addImage("app/calendar/creator/assets/cc-by-sa.png", 'PNG', xmin + (xmax - xmin)/2 - 7.5, ymax - 3, 15, 4);

        const picwidth = xmax - xmin - 20;
        const picheight = picwidth * 0.665;

        let gridheight = (ymax - ymin - 25 - picheight - 11) / 6;
        let gridwidth  = ((xmax - xmin) - 16) / 7;

        for (let month of this.year.months)
        {
            doc.addPage();
            // Set custom font
            doc.setFont(this.font.value, 'normal');
            doc.setFontSize(48 + this.font.compensate);
            doc.setTextColor(0, 0, 0);
            let year_with_month = month.month_name + (this.show_year_with_name_of_month ? ' ' + month.year : '');
            doc.text(this.upperFirst(year_with_month), xtot / 2, 20, { align: 'center'});

            doc.setDrawColor(0, 0, 0);
            doc.setLineWidth(0.1);
            if (this.months[month.month-1].file) {
                doc.addImage(this.months[month.month-1].file.objectUrl, 'JPEG', xmin + 10, ymin + 23, picwidth, picheight);
            }
            doc.rect(xmin + 10, ymin + 23, picwidth, picheight);

            const weekdays = ['måndag', 'tisdag' , 'onsdag', 'torsdag', 'fredag', 'lördag', 'söndag'];

            const gridtop = (ymin + 25 + picheight + 9);

            doc.setDrawColor(this.colour_grid);
            doc.setTextColor(this.colour_weekdays);
            doc.setLineWidth(0.1);
            doc.setFontSize(14 + this.font.compensate);
            let y = [];
            let x = [];
            y[0] = gridtop - 0.1;
            y[1] = ymax - 2 - 0.1;
            for (let i = 0; i < 8; i++)
            {
                x[0] = xmin + 8 + 1.2 + (i * gridwidth);
                doc.line(x[0], y[0], x[0], y[1]);
                if (i < 7)
                {
                    doc.text(weekdays[i], x[0] + (gridwidth/2), gridtop - 2, {align: 'center'});
                }
            }

            // Horizontal lines
            x[0] = xmin + 8 + 1.2;
            x[1] = xmax - 8 + 1.2;
            for (let i = 0; i < 7; i++)
            {
                y[0] = gridtop + (i * gridheight);
                doc.line(x[0], y[0], x[1], y[0]);
            }

            let week_row = -1;
            let oldWeek = -1;
            let xpos = 0;
            let ypos = 0;
            let dow = 0;

            for (let day of month.days)
            {
                //console.log(day);
                let weekno = day.week;
                if(oldWeek != weekno)
                {
                    week_row++;
                    oldWeek = weekno;
                    doc.setFont(this.font.value, 'normal');
                    doc.setFontSize(12 + this.font.compensate);
                    doc.setTextColor(this.colour_weeknumbers);
                    ypos = gridtop + (week_row * gridheight) + (gridheight / 2) + 2;
                    xpos = xmin + 8;
                    doc.text("" + day.week, xpos, ypos, { align: "right" });
                }
                dow = day.weekday - 1;

                xpos = xmin + 8 + 2 + (dow * gridwidth);
                ypos = gridtop + (week_row * gridheight) + 5;
                doc.setFont(this.font.value, 'normal');
                doc.setFontSize(14 + this.font.compensate);
                doc.setTextColor(this.get_day_color(day));
                doc.text("" + day.day_of_month, xpos, ypos);
                if ((day.is_holiday || day.is_flagday || day.is_special_day) && this.show_holidays)
                {
                    let lines = 0;
                    let rows = [];
                    for (let desc of day.description)
                    {
                        var new_rows = doc.splitTextToSize(desc, 60);
                        rows = [...rows, ...new_rows];
                    }
                    lines += rows.length;
                    doc.setFontSize(6.5);
                    doc.setFont("helvetica", "normal");
                    doc.text(rows, xpos + 0.5, ypos + gridheight - 6.75 - (lines - 1) * 2.5);
                }

                // Display name days
                doc.setFont(font_name, 'normal');
                doc.setFontSize(6.5);
                doc.text(day.namesdays, xpos + gridwidth - 2, ypos - 2, { align: 'right'});

                // Display moon phases
                doc.setLineWidth(0.4);
                if (day.padding)
                {
                    doc.setDrawColor(179, 179, 179);
                    doc.setFillColor(179, 179, 179);
                }
                else
                {
                    doc.setDrawColor(0, 0, 0);
                    doc.setFillColor(0, 0, 0);
                }
                var r = 1.2
                xpos = xpos + 1.5;
                ypos = ypos + 2.2;
                if (this.show_moon_phases)
                {
                    if (day.moon_phase == "new")
                    {
                        doc.circle(xpos, ypos, r, 'DF');
                    }
                    if (day.moon_phase == "first quarter")
                    {
                        doc.circle(xpos, ypos, r, 'S');
                        doc.line(xpos - 0.2, ypos - r, xpos - 0.2, ypos + r);
                        doc.line(xpos - 0.3, ypos - r + 0.2, xpos - 0.3, ypos + r - 0.2);
                        doc.line(xpos - 0.6, ypos - r + 0.3, xpos - 0.6, ypos + r - 0.3);
                        doc.line(xpos - 0.9, ypos - r + 0.4, xpos - 0.9, ypos + r - 0.4);
                    }
                    if (day.moon_phase == "full")
                    {
                        doc.circle(xpos, ypos, r, 'S');
                    }
                    if (day.moon_phase == "third quarter")
                    {
                        doc.circle(xpos, ypos, r, 'S');
                        doc.line(xpos + 0.2, ypos - r, xpos + 0.2, ypos + r);
                        doc.line(xpos + 0.3, ypos - r + 0.2, xpos + 0.3, ypos + r - 0.2);
                        doc.line(xpos + 0.6, ypos - r + 0.3, xpos + 0.6, ypos + r - 0.3);
                        doc.line(xpos + 0.9, ypos - r + 0.4, xpos + 0.9, ypos + r - 0.4);
                        //doc.lines([[0,r], [0, r, r, 0, 0, r], [0, -r]], xpos, ypos, [1, 1]);
                    }
                }
                // Display flag
                var flag = false;
                var flagDiff = 0;
                xpos = xpos - 0.75;
                ypos = ypos + 7.5 - (day.description.length - 1) * 2.5;
                if (day.is_flagday && this.show_flagdays)
                {
                    if (day.padding)
                    {
                        doc.setDrawColor(179, 179, 179);
                        doc.setFillColor(179, 179, 179);
                    }
                    else
                    {
                        doc.setDrawColor(0, 81, 155);
                        doc.setFillColor(0, 81, 155);
                    }
                    doc.rect(xpos, ypos - flagDiff, 4.25, 2.5, 'F');
                    if (day.padding)
                    {
                        doc.setDrawColor(237, 236, 229);
                        doc.setFillColor(237, 236, 229);
                    }
                    else
                    {
                        doc.setDrawColor(255, 206, 0);
                        doc.setFillColor(255, 206, 0);
                    }
                    doc.rect(xpos + 1.375, ypos - flagDiff, 0.5, 2.5, 'F');
                    doc.rect(xpos, ypos + 1 - flagDiff, 4.25, 0.5, 'F');
                    flag = true;
                }

                xpos = xmin + 8 + 2 + (dow * gridwidth);
                ypos = gridtop + (week_row * gridheight) + 12;
                if (day.celebration && day.celebration[0])
                {
                    doc.setFont(font_name, font_type);
                    doc.setFontSize(6);
                    let lines = day.celebration[0].length;  
                    doc.text(day.celebration[0], xpos, ypos - (lines - 1) * 1.2, { align: 'left' });
                }
            }
        }
        doc.save('kalendar.pdf');
    }

    createPortraitRounded()
    {
        // Choose font
        let font_name = 'helvetica';
        let font_type = 'normal';
        var doc = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts:true
        });
        
        const xtot = 210;
        const ytot = 297;

        const xmin = 5;
        const ymin = 5;

        const xmax  = xtot - xmin;
        const ymax  = ytot - ymin;

        this.addFont(doc);

        const row1 = "Kalender";
        const row2 = "" + this.year.year;
        const copy = "Creative Commons";

        doc.setFontSize(56 + this.font.compensate);
        doc.setFont(this.font.value,'normal');
        doc.text(row1, xmin + (xmax - xmin) / 2, ymin + (ymax - ymin) / 2 - 27, {align: 'center'});
        doc.text(row2, xmin + (xmax - xmin) / 2, ymin + (ymax - ymin) / 2, {align: 'center'});

        doc.setFont(font_name, font_type);
        doc.setFontSize(9);
        doc.text(copy, xmin + (xmax - xmin) / 2, ymax - 5, {align: 'center'});
        doc.addImage("app/calendar/creator/assets/cc-by-sa.png", 'PNG', xmin + (xmax - xmin)/2 - 7.5, ymax - 3, 15, 4);

        const picwidth = xmax - xmin - 20;
        const picheight = picwidth * 0.665;

        let gridheight = (ymax - ymin - 25 - picheight - 11) / 6;
        let gridwidth  = ((xmax - xmin) - 16) / 7;

        for (let month of this.year.months)
        {
            doc.addPage();
            // Set custom font

            doc.setDrawColor(0, 0, 0);
            doc.setLineWidth(0.1);
            //doc.lines([[xmax - 10, 0], [0, picheight], [-(xmax - 10), 0], [0, -picheight]], xmin + 10, ymin + 65);
            if (this.months[month.month-1].file) {
                doc.addImage(this.months[month.month-1].file.objectUrl, 'JPEG', xmin + 10, ymin + 10, picwidth, picheight);
            }
            doc.rect(xmin + 10, ymin + 10, picwidth, picheight);

            doc.setFont(this.font.value,  this.font.value == 'helvetica' ? 'bold' : 'normal');
            doc.setFontSize(36 + this.font.compensate);
            let width = 0;
            doc.setTextColor(0, 0, 0);
            if (this.show_year_with_name_of_month) {
                doc.text("" + this.year.year, xmax - 10, ymin + picheight + 22, { align: 'right'});
                width = doc.getTextWidth("" + this.year.year) + 5;                    
                doc.setTextColor(255, 0, 0);
            }
            doc.text(month.month_name.toLowerCase(), xmax - 10 - width, ymin + picheight + 22, { align: 'right'});

            const weekdays = ['måndag', 'tisdag' , 'onsdag', 'torsdag', 'fredag', 'lördag', 'söndag'];

            const gridtop = (ymin + 25 + picheight + 9);

            doc.setDrawColor(this.colour_grid);
            doc.setTextColor(this.colour_weekdays);
            doc.setLineWidth(0.1);
            doc.setFont(this.font.value, 'normal');
            doc.setFontSize(14 + this.font.compensate);
            let y = [];
            let x = [];
            y[0] = gridtop - 0.1;
            y[1] = ymax - 2 - 0.1;
            doc.roundedRect(xmin + 8 + 1.2, y[0],  (7 * gridwidth), y[1] - y[0], 3, 3, 'S')
            for (let i = 0; i < 7; i++)
            {
                x[0] = xmin + 8 + 1.2 + (i * gridwidth);
                if (i > 0)
                {
                    doc.line(x[0], y[0], x[0], y[1]);
                }
                if (i < 7)
                {
                    doc.text(weekdays[i], x[0] + (gridwidth/2), gridtop - 2, {align: 'center'});
                }
            }

            // Horizontal lines
            x[0] = xmin + 8 + 1.2;
            x[1] = xmax - 8 + 1.2;
            for (let i = 1; i < 6; i++)
            {
                y[0] = gridtop + (i * gridheight);
                doc.line(x[0], y[0], x[1], y[0]);
            }

            let week_row = -1;
            let oldWeek = -1;
            let xpos = 0;
            let ypos = 0;
            let dow = 0;

            for (let day of month.days)
            {
                //console.log(day);
                let weekno = day.week;
                if(oldWeek != weekno)
                {
                    week_row++;
                    oldWeek = weekno;
                    doc.setFont(this.font.value, 'normal');
                    doc.setFontSize(12 + this.font.compensate);
                    doc.setTextColor(this.colour_weeknumbers);
                    ypos = gridtop + (week_row * gridheight) + (gridheight / 2) + 2;
                    xpos = xmin + 8;
                    doc.text("" + day.week, xpos, ypos, { align: "right" });
                }
                dow = day.weekday - 1;

                xpos = xmin + 8 + 2 + (dow * gridwidth);
                ypos = gridtop + (week_row * gridheight) + 5;
                doc.setFont(this.font.value, 'normal');
                doc.setFontSize(14 + this.font.compensate);
                doc.setTextColor(this.get_day_color(day));
                doc.text("" + day.day_of_month, xpos, ypos);
                if ((day.is_holiday || day.is_flagday || day.is_special_day) && this.show_holidays)
                {
                    let lines = 0;
                    let rows = [];
                    for (let desc of day.description)
                    {
                        var new_rows = doc.splitTextToSize(desc, 60);
                        rows = [...rows, ...new_rows];
                    }
                    lines += rows.length;
                    doc.setFontSize(6.5);
                    doc.setFont("helvetica", "normal");
                    doc.text(rows, xpos + 0.5, ypos + gridheight - 6.75 - (lines - 1) * 2.5);
                }

                // Display name days
                doc.setFont(font_name, 'normal');
                doc.setFontSize(6.5);
                doc.text(day.namesdays, xpos + gridwidth - 2, ypos - 2, { align: 'right'});

                // Display moon phase
                doc.setLineWidth(0.4);
                if (day.padding)
                {
                    doc.setDrawColor(179, 179, 179);
                    doc.setFillColor(179, 179, 179);
                }
                else
                {
                    doc.setDrawColor(0, 0, 0);
                    doc.setFillColor(0, 0, 0);
                }
                var r = 1.2
                xpos = xpos + 1.5;
                ypos = ypos + 3;
                if (this.show_moon_phases)
                {
                    if (day.moon_phase == "new")
                    {
                        doc.circle(xpos, ypos, r, 'DF');
                    }
                    if (day.moon_phase == "first quarter")
                    {
                        doc.circle(xpos, ypos, r, 'S');
                        doc.line(xpos - 0.2, ypos - r, xpos - 0.2, ypos + r);
                        doc.line(xpos - 0.3, ypos - r + 0.2, xpos - 0.3, ypos + r - 0.2);
                        doc.line(xpos - 0.6, ypos - r + 0.3, xpos - 0.6, ypos + r - 0.3);
                        doc.line(xpos - 0.9, ypos - r + 0.4, xpos - 0.9, ypos + r - 0.4);
                    }
                    if (day.moon_phase == "full")
                    {
                        doc.circle(xpos, ypos, r, 'S');
                    }
                    if (day.moon_phase == "third quarter")
                    {
                        doc.circle(xpos, ypos, r, 'S');
                        doc.line(xpos + 0.2, ypos - r, xpos + 0.2, ypos + r);
                        doc.line(xpos + 0.3, ypos - r + 0.2, xpos + 0.3, ypos + r - 0.2);
                        doc.line(xpos + 0.6, ypos - r + 0.3, xpos + 0.6, ypos + r - 0.3);
                        doc.line(xpos + 0.9, ypos - r + 0.4, xpos + 0.9, ypos + r - 0.4);
                        //doc.lines([[0,r], [0, r, r, 0, 0, r], [0, -r]], xpos, ypos, [1, 1]);
                    }
                }
                // Display flag
                var flag = false;
                var flagDiff = 0;
                xpos = xpos - 0.75;
                ypos = ypos + 6.25 - (day.description.length - 1) * 2.5;
                if (day.is_flagday && this.show_flagdays)
                {
                    if (day.padding)
                    {
                        doc.setDrawColor(179, 179, 179);
                        doc.setFillColor(179, 179, 179);
                    }
                    else
                    {
                        doc.setDrawColor(0, 81, 155);
                        doc.setFillColor(0, 81, 155);
                    }
                    doc.rect(xpos, ypos - flagDiff, 4.25, 2.5, 'F');
                    if (day.padding)
                    {
                        doc.setDrawColor(237, 236, 229);
                        doc.setFillColor(237, 236, 229);
                    }
                    else
                    {
                        doc.setDrawColor(255, 206, 0);
                        doc.setFillColor(255, 206, 0);
                    }
                    doc.rect(xpos + 1.375, ypos - flagDiff, 0.5, 2.5, 'F');
                    doc.rect(xpos, ypos + 1 - flagDiff, 4.25, 0.5, 'F');
                    flag = true;
                }

                xpos = xmin + 8 + 2 + (dow * gridwidth);
                ypos = gridtop + (week_row * gridheight) + 12;
                if (day.celebration && day.celebration[0])
                {
                    doc.setFont(font_name, font_type);
                    doc.setFontSize(6);
                    let lines = day.celebration[0].length;  
                    doc.text(day.celebration[0], xpos, ypos - (lines - 1) * 1.2, { align: 'left' });
                }

            }
        }
        doc.save('kalendar.pdf');
    }

    createLandscape()
    {
        // Choose font
        let font_name = 'helvetica';
        let font_type = 'normal';
        var doc = new jsPDF({
            orientation: 'l',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts:true
        });
        
        const xtot = 297;
        const ytot = 210;

        const xmin = 5;
        const ymin = 5;

        const xmax  = xtot - xmin;
        const ymax  = ytot - ymin;

        this.addFont(doc);

        const row1 = "Kalender";
        const row2 = "" + this.year.year;
        const copy = "Creative Commons";

        doc.setFontSize(56 + this.font.compensate);
        doc.setFont(this.font.value, 'normal');
        doc.text(row1, xmin + (xmax - xmin) / 2, ymin + (ymax - ymin) / 2 - 27, {align: 'center'});
        doc.text(row2, xmin + (xmax - xmin) / 2, ymin + (ymax - ymin) / 2, {align: 'center'});

        doc.setFont(font_name, font_type);
        doc.setFontSize(9);
        doc.text(copy, xmin + (xmax - xmin) / 2, ymax - 5, {align: 'center'});
        doc.addImage("app/calendar/creator/assets/cc-by-sa.png", 'PNG', xmin + (xmax - xmin)/2 - 7.5, ymax - 3, 15, 4);

        const picwidth = xmax - xmin - 20;
        const picheight = picwidth * 0.665;

        let gridheight = (ymax - ymin - 25) / 6;
        let gridwidth  = ((xmax - xmin) - 16) / 7;

        for (let month of this.year.months)
        {
            doc.addPage();

            doc.setDrawColor(0, 0, 0);
            doc.setLineWidth(0.1);
            if (this.months[month.month-1].file) {
                doc.addImage(this.months[month.month-1].file.objectUrl, 'JPEG', xmin + 10, ymin + 10, picwidth, picheight);
            }
            doc.rect(xmin + 10, ymin + 10, picwidth, picheight);

            const weekdays = ['måndag', 'tisdag' , 'onsdag', 'torsdag', 'fredag', 'lördag', 'söndag'];

            const gridtop = (ymin + 25);

            doc.addPage();

            doc.setFont(this.font.value, 'normal');
            doc.setFontSize(48);
            doc.setTextColor(0, 0, 0);
            let year_with_month = month.month_name + (this.show_year_with_name_of_month ? ' ' + month.year : '');
            doc.text(this.upperFirst(year_with_month), xtot / 2, 20, { align: 'center'});

            doc.setDrawColor(this.colour_grid);
            doc.setTextColor(this.colour_weekdays);
            doc.setLineWidth(0.1);
            doc.setFontSize(14);
            let y = [];
            let x = [];
            y[0] = gridtop - 0.1;
            y[1] = ymax - 0.1;

            for (let i = 0; i < 8; i++)
            {
                x[0] = xmin + 8 + 1.2 + (i * gridwidth);
                doc.line(x[0], y[0], x[0], y[1]);
                if (i < 7)
                {
                    doc.text(weekdays[i], x[0] + (gridwidth/2), gridtop - 2, {align: 'center'});
                }
            }

            // Horizontal lines
            x[0] = xmin + 8 + 1.2;
            x[1] = xmax - 8 + 1.2;
            for (let i = 0; i < 7; i++)
            {
                y[0] = gridtop + (i * gridheight);
                doc.line(x[0], y[0], x[1], y[0]);
            }

            let week_row = -1;
            let oldWeek = -1;
            let xpos = 0;
            let ypos = 0;
            let dow = 0;

            for (let day of month.days)
            {
                let weekno = day.week;
                if(oldWeek != weekno)
                {
                    week_row++;
                    oldWeek = weekno;
                    doc.setFont(this.font.value, 'normal');
                    doc.setFontSize(16 + this.font.compensate);
                    doc.setTextColor(this.colour_weeknumbers);
                    ypos = gridtop + (week_row * gridheight) + (gridheight / 2) + 2;
                    xpos = xmin + 8;
                    doc.text("" + day.week, xpos, ypos, { align: "right" });
                }
                dow = day.weekday - 1;

                // Display holidays
                xpos = xmin + 8 + 1.5 +(dow * gridwidth);
                ypos = gridtop + (week_row * gridheight) + 5;
                doc.setFont(this.font.value, 'normal');
                doc.setFontSize(18 + this.font.compensate);
                doc.setTextColor(this.get_day_color(day));
                doc.text("" + day.day_of_month, xpos + 1, ypos + 1.25);
                if ((day.is_holiday || day.is_flagday || day.is_special_day) && this.show_holidays)
                {
                    let lines = 0;
                    let rows = [];
                    for (let desc of day.description)
                    {
                        var new_rows = doc.splitTextToSize(desc, 90);
                        rows = [...rows, ...new_rows];
                    }
                    lines += rows.length;
                    doc.setFontSize(10);
                    doc.setFont("helvetica", "normal");
                    doc.text(rows, xpos + 0.75, ypos + gridheight - 7.25 - (lines - 1) * 4.4);
                }

                // Display name days
                doc.setFont(font_name, font_type);
                doc.setFontSize(10);
                doc.text(day.namesdays, xpos + gridwidth - 2, ypos, { align: 'right'});

                // Display moon phases
                doc.setLineWidth(0.4);
                if (day.padding)
                {
                    doc.setDrawColor(179, 179, 179);
                    doc.setFillColor(179, 179, 179);
                }
                else
                {
                    doc.setDrawColor(0, 0, 0);
                    doc.setFillColor(0, 0, 0);
                }
                var r = 1.2
                xpos = xpos + 2;
                ypos = ypos + 4;
                let moon_pad = 0;
                if (this.show_moon_phases)
                {
                    if (day.moon_phase == "new")
                    {
                        doc.circle(xpos, ypos, r, 'DF');
                        moon_pad = 3.5;
                    }
                    if (day.moon_phase == "first quarter")
                    {
                        doc.circle(xpos, ypos, r, 'S');
                        doc.line(xpos - 0.2, ypos - r, xpos - 0.2, ypos + r);
                        doc.line(xpos - 0.3, ypos - r + 0.2, xpos - 0.3, ypos + r - 0.2);
                        doc.line(xpos - 0.6, ypos - r + 0.3, xpos - 0.6, ypos + r - 0.3);
                        doc.line(xpos - 0.9, ypos - r + 0.4, xpos - 0.9, ypos + r - 0.4);
                        moon_pad = 3.5;
                    }
                    if (day.moon_phase == "full")
                    {
                        doc.circle(xpos, ypos, r, 'S');
                        moon_pad = 3.5;
                    }
                    if (day.moon_phase == "third quarter")
                    {
                        doc.circle(xpos, ypos, r, 'S');
                        doc.line(xpos + 0.2, ypos - r, xpos + 0.2, ypos + r);
                        doc.line(xpos + 0.3, ypos - r + 0.2, xpos + 0.3, ypos + r - 0.2);
                        doc.line(xpos + 0.6, ypos - r + 0.3, xpos + 0.6, ypos + r - 0.3);
                        doc.line(xpos + 0.9, ypos - r + 0.4, xpos + 0.9, ypos + r - 0.4);
                        moon_pad = 3.5;
                        //doc.lines([[0,r], [0, r, r, 0, 0, r], [0, -r]], xpos, ypos, [1, 1]);
                    }
                }
                // Display flag
                var flag = false;
                var flagDiff = 0;
                xpos = xpos - 1.3;
                ypos = ypos - 1.3 + moon_pad;
                if (day.is_flagday && this.show_flagdays)
                {
                    if (day.padding)
                    {
                        doc.setDrawColor(179, 179, 179);
                        doc.setFillColor(179, 179, 179);
                    }
                    else
                    {
                        doc.setDrawColor(0, 81, 155);
                        doc.setFillColor(0, 81, 155);
                    }
                    doc.rect(xpos, ypos - flagDiff, 5.3125, 3.125, 'F');
                    if (day.padding)
                    {
                        doc.setDrawColor(237, 236, 229);
                        doc.setFillColor(237, 236, 229);
                    }
                    else
                    {
                        doc.setDrawColor(255, 206, 0);
                        doc.setFillColor(255, 206, 0);
                    }
                    doc.rect(xpos + 1.71875, ypos - flagDiff, 0.625, 3.125, 'F');
                    doc.rect(xpos, ypos + 1.25 - flagDiff, 5.3125, 0.625, 'F');
                    flag = true;
                }

                xpos = xmin + 11 + (dow * gridwidth);
                ypos = gridtop + (week_row * gridheight) + 18;
                if (day.celebration && day.celebration[0])
                {
                    doc.setFont(font_name, font_type);
                    doc.setFontSize(8);
                    let lines = day.celebration[0].length;  
                    doc.text(day.celebration[0], xpos, ypos - (lines - 1) * 1.2, { align: 'left' });
                }

            }
        }
        doc.save('kalendar.pdf');
    }

    createNameList()
    {
        // Choose font
        let font_name = 'helvetica';
        let font_type = 'normal';
        var doc = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts:true
        });
        
        const xtot = 210;
        const ytot = 297;

        const xmin = 5;
        const ymin = 5;

        const xmax  = xtot - xmin;
        const ymax  = ytot - ymin - 5;

        this.addFont(doc);

        const row1 = "Kalender";
        const row2 = "" + this.year.year;
        const copy = "Creative Commons";

        doc.setFontSize(56 + this.font.compensate);
        doc.setFont(this.font.value, 'normal');
        doc.text(row1, xmin + (xmax - xmin) / 2, ymin + (ymax - ymin) / 2 - 27, {align: 'center'});
        doc.text(row2, xmin + (xmax - xmin) / 2, ymin + (ymax - ymin) / 2, {align: 'center'});

        doc.setFont(font_name, font_type);
        doc.setFontSize(9);
        doc.text(copy, xmin + (xmax - xmin) / 2, ymax - 5, {align: 'center'});
        doc.addImage("app/calendar/creator/assets/cc-by-sa.png", 'PNG', xmin + (xmax - xmin)/2 - 7.5, ymax - 3, 15, 4);

        
        let color = [];
        color[1] = '#f5e6ff';
        color[2] = '#ffffc8';
        color[3] = '#ebffeb';
        color[4] = '#ebebff';
        color[5] = '#f4e1bc';
        color[6] = '#d5d5d5';
        color[7] = '#f5e6ff';
        color[8] = '#ffffc8';
        color[9] = '#ebffeb';
        color[10] = '#ebebff';
        color[11] = '#f4e1bc';
        color[12] = '#ffe1e1';

        let gridtop = ymin + 20;
        let gridheight = (ymax - gridtop) / 32;
        let gridwidth = ((xmax - xmin) - 20) / (this.namelist_columns + 1);

        for (let month of this.year.months)
        {
            doc.addPage();
            doc.setFillColor(color[month.month]);
            doc.rect(0, 0, xtot, ytot, 'F');
            doc.setFont(this.font.value, 'normal');
            doc.setFontSize(48);
            doc.setTextColor(0, 0, 0);

            let year_with_month = month.month_name + (this.show_year_with_name_of_month ? ' ' + month.year : '');
            doc.text(this.upperFirst(year_with_month), xtot / 2, 20, { align: 'center'});
         

            doc.setFillColor(255, 255, 255);
            doc.setDrawColor(0, 0, 0);
            doc.rect(xmin + 15 + gridwidth, gridtop, gridwidth * (this.namelist_columns), gridheight * 32, 'F');

            // Vertical lines
            for (let i = 0; i < (this.namelist_columns + 2); i++)
            {
                let y0 = gridtop;
                let y1 = gridtop + gridheight * 32;
                let x = xmin + 15 + (i * gridwidth);
                doc.line(x, y0, x, y1);
            }

            // Horisontal lines
            for (let i = 0; i < 33; i++)
            {
                let x0 = xmin + 15;
                let x1 = xmin + 15 + gridwidth * (this.namelist_columns + 1);
                let y = gridtop + (i * gridheight);
                doc.line(x0, y, x1, y);
            }

            doc.setFont(font_name, font_type);
            doc.setFontSize(14 + this.font.compensate);
            for (let i = 0; i < this.namelist_columns; i++)
            {
                doc.text(this.listNames[i], xmin + 15 + gridwidth +(i * gridwidth) + (gridwidth/2), gridtop + 6, { align: 'center'});
            }

            let oldWeek = -1;
            for(let day of month.days)
            {
                if (!day.padding)
                {
                    let weekno = day.week;
                    if (weekno != oldWeek)
                    {
                        oldWeek = weekno;
                        doc.setFont(font_name, 'normal');
                        doc.setFontSize(10);
                        doc.setTextColor(this.colour_weeknumbers);
                        let ypos = gridtop + day.day_of_month * gridheight + gridheight - 2;
                        doc.text("v." + weekno, xmin + 13, ypos, { align: 'right'});
                    }

                    doc.setTextColor(this.get_day_color(day));
                    doc.setFont(this.font.value, 'normal');
                    doc.setFontSize(16 + this.font.compensate);
                    let xpos = xmin + 24;
                    let ypos = gridtop + ((day.day_of_month + 1) * gridheight) - 1.5;
                    doc.text(String(day.day_of_month), xpos - 8, ypos, { align: 'left'});

                    // Display name of weekdays
                    doc.setFont(font_name, 'bold');
                    doc.setFontSize(9);
                    let dayname = "";
                    if (this.namelist_columns >= 5)
                    {
                        dayname = day.dayname.substring(0, 3);
                    }
                    else
                    {
                        dayname = day.dayname;
                    }
                    doc.text(dayname, xpos + 0.5, ypos - 3.5, { align: 'left'});

                    // Display name days
                    doc.setFont(font_name, font_type);
                    doc.setFontSize(5);
                    doc.text(day.namesdays, xmin + 15 + gridwidth - 1, ypos - 4.5, { align: 'right'});

                    // Display holidays, special days and such
                    doc.setTextColor(this.get_day_color(day));
                    if ((day.is_holiday || day.is_flagday || day.is_special_day) && this.show_holidays)
                    {
                        let lines = 0;
                        let rows = [];
                        for (let desc of day.description)
                        {
                            var new_rows = doc.splitTextToSize(desc, 60);
                            rows = [...rows, ...new_rows];
                        }
                        lines += rows.length;
                        doc.setFontSize(5);
                        doc.setFont(font_name, font_type);
                        doc.text(rows, xpos + 0.5, ypos + 0.5 - (lines - 1) * 2, { align: 'left' });
                    }

                    // Display moon
                    doc.setLineWidth(0.4);
                    doc.setDrawColor(0, 0, 0);
                    doc.setFillColor(0, 0, 0);
                    var r = 1.2
                    xpos = xmin + 15 + gridwidth - 2.5;
                    ypos = ypos - 0.5;
                    let moon_pad = 0;
                    if (this.show_moon_phases)
                    {
                        if (day.moon_phase == "new")
                        {
                            doc.circle(xpos, ypos, r, 'DF');
                            moon_pad = 3.5;
                        }
                        if (day.moon_phase == "first quarter")
                        {
                            doc.circle(xpos, ypos, r, 'S');
                            doc.line(xpos - 0.2, ypos - r, xpos - 0.2, ypos + r);
                            doc.line(xpos - 0.3, ypos - r + 0.2, xpos - 0.3, ypos + r - 0.2);
                            doc.line(xpos - 0.6, ypos - r + 0.3, xpos - 0.6, ypos + r - 0.3);
                            doc.line(xpos - 0.9, ypos - r + 0.4, xpos - 0.9, ypos + r - 0.4);
                            moon_pad = 3.5;
                        }
                        if (day.moon_phase == "full")
                        {
                            doc.circle(xpos, ypos, r, 'S');
                            moon_pad = 3.5;
                        }
                        if (day.moon_phase == "third quarter")
                        {
                            doc.circle(xpos, ypos, r, 'S');
                            doc.line(xpos + 0.2, ypos - r, xpos + 0.2, ypos + r);
                            doc.line(xpos + 0.3, ypos - r + 0.2, xpos + 0.3, ypos + r - 0.2);
                            doc.line(xpos + 0.6, ypos - r + 0.3, xpos + 0.6, ypos + r - 0.3);
                            doc.line(xpos + 0.9, ypos - r + 0.4, xpos + 0.9, ypos + r - 0.4);
                            moon_pad = 3.5;
                            //doc.lines([[0,r], [0, r, r, 0, 0, r], [0, -r]], xpos, ypos, [1, 1]);
                        }
                    }
                    
                    // Display flag
                    var flag = false;
                    var flagDiff = 0;
                    xpos = xpos - moon_pad - 3;
                    ypos = ypos - 1.4;
                    if (day.is_flagday && this.show_flagdays)
                    {
                        if (day.padding)
                        {
                            doc.setDrawColor(179, 179, 179);
                            doc.setFillColor(179, 179, 179);
                        }
                        else
                        {
                            doc.setDrawColor(0, 81, 155);
                            doc.setFillColor(0, 81, 155);
                        }
                        doc.rect(xpos, ypos - flagDiff, 4, 2.5, 'F');
                        if (day.padding)
                        {
                            doc.setDrawColor(237, 236, 229);
                            doc.setFillColor(237, 236, 229);
                        }
                        else
                        {
                            doc.setDrawColor(255, 206, 0);
                            doc.setFillColor(255, 206, 0);
                        }
                        doc.rect(xpos + 1.25, ypos - flagDiff, 0.5, 2.5, 'F');
                        doc.rect(xpos, ypos + 1 - flagDiff, 4, 0.5, 'F');
                        flag = true;
                    }

                    xpos = xmin + 16 + gridwidth;
                    for (let i = 0; i < this.celebrations_columns; i++) 
                    {
                        if (day.celebration && day.celebration[i])
                        {
                            doc.setFont(font_name, font_type);
                            doc.setFontSize(6);
                            let lines = day.celebration[i].length;  
                            doc.text(day.celebration[i], xpos + i * gridwidth, ypos - (lines - 1) * 1.3, { align: 'left' });
                        }
                    }
                }
            }
        }

        doc.save('kalendar.pdf');
    }

    createNameListTall()
    {
        // Choose font
        let font_name = 'helvetica';
        let font_type = 'normal';
        var doc = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts:true
        });
        
        const xtot = 210;
        const ytot = 297;

        const xmin = 5;
        const ymin = 5;

        const xmax  = xtot - xmin;
        const ymax  = ytot - ymin - 5;

        this.addFont(doc);

        const row1 = "Kalender";
        const row2 = "" + this.year.year;
        const copy = "Creative Commons";

        doc.setFontSize(56 + this.font.compensate);
        doc.setFont(this.font.value, 'normal');
        doc.text(row1, xmin + (xmax - xmin) / 2, ymin + (ymax - ymin) / 2 - 27, {align: 'center'});
        doc.text(row2, xmin + (xmax - xmin) / 2, ymin + (ymax - ymin) / 2, {align: 'center'});

        doc.setFont(font_name, font_type);
        doc.setFontSize(9);
        doc.text(copy, xmin + (xmax - xmin) / 2, ymax - 5, {align: 'center'});
        doc.addImage("app/calendar/creator/assets/cc-by-sa.png", 'PNG', xmin + (xmax - xmin)/2 - 7.5, ymax - 3, 15, 4);

        
        let color = [];
        color[1] = '#f5e6ff';
        color[2] = '#ffffc8';
        color[3] = '#ebffeb';
        color[4] = '#ebebff';
        color[5] = '#f4e1bc';
        color[6] = '#d5d5d5';
        color[7] = '#f5e6ff';
        color[8] = '#ffffc8';
        color[9] = '#ebffeb';
        color[10] = '#ebebff';
        color[11] = '#f4e1bc';
        color[12] = '#ffe1e1';

        let gridtop = ymin + 20;
        let gridheight = (ymax - gridtop) / 17;
        let gridwidth = ((xmax - xmin) - 20) / (this.namelist_columns + 1);

        for (let month of this.year.months)
        {
            for (let half_month = 0; half_month < 2; half_month++)
            {
                doc.addPage();
                doc.setFillColor(color[month.month]);
                doc.rect(0, 0, xtot, ytot, 'F');
                doc.setFont(this.font.value, 'normal');
                doc.setFontSize(48);
                doc.setTextColor(0, 0, 0);

                let year_with_month = month.month_name + (this.show_year_with_name_of_month ? ' ' + month.year : '');
                doc.text(this.upperFirst(year_with_month), xtot / 2, 20, { align: 'center'});
                

                doc.setFillColor(255, 255, 255);
                doc.setDrawColor(0, 0, 0);
                doc.rect(xmin + 15 + gridwidth, gridtop, gridwidth * (this.namelist_columns), gridheight * 17, 'F');

                // Vertical lines
                for (let i = 0; i < (this.namelist_columns + 2); i++)
                {
                    let y0 = gridtop;
                    let y1 = gridtop + gridheight * 17;
                    let x = xmin + 15 + (i * gridwidth);
                    doc.line(x, y0, x, y1);
                }

                // Horisontal lines
                for (let i = 0; i < 18; i++)
                {
                    let x0 = xmin + 15;
                    let x1 = xmin + 15 + gridwidth * (this.namelist_columns + 1);
                    let y = gridtop + (i * gridheight);
                    doc.line(x0, y, x1, y);
                }

                doc.setFont(font_name, font_type);
                doc.setFontSize(14 + this.font.compensate);
                for (let i = 0; i < this.namelist_columns; i++)
                {
                    doc.text(this.listNames[i], xmin + 15 + gridwidth +(i * gridwidth) + (gridwidth/2), gridtop + 6, { align: 'center'});
                }

                let days;
                if (half_month == 0)
                {
                    days = month.days.slice(0, 16);
                }
                else
                {
                    days = month.days.slice(16);
                }

                let oldWeek = -1;
                for(let day of days)
                {
                    if (!day.padding)
                    {
                        let weekno = day.week;
                        if (weekno != oldWeek)
                        {
                            oldWeek = weekno;
                            doc.setFont(font_name, 'normal');
                            doc.setFontSize(10);
                            doc.setTextColor(this.colour_weeknumbers);
                            let ypos = gridtop + day.day_of_month * gridheight + gridheight - 2 - half_month * gridheight * 16;
                            doc.text("v." + weekno, xmin + 13, ypos, { align: 'right'});
                        }

                        doc.setTextColor(this.get_day_color(day));
                        doc.setFontSize(24 + this.font.compensate);
                        doc.setFont(this.font.value, 'normal');
                        let xpos = xmin + 25;
                        let ypos = gridtop + ((day.day_of_month + 1) * gridheight) - 3 - half_month * gridheight * 16;
                        doc.text(String(day.day_of_month), xpos, ypos, { align: 'right'});

                        // Display name of weekdays
                        doc.setFontSize(9);
                        doc.setFont(font_name, 'bold');
                        let dayname = "";
                        dayname = day.dayname.substring(0, 3);
                        doc.text(dayname, xpos + 0.5, ypos - 3.5, { align: 'left'});

                        // Display name days
                        doc.setFont(font_name, font_type);
                        doc.setFontSize(6);
                        doc.text(day.namesdays, xmin + 15 + gridwidth - 1, ypos - 9.5, { align: 'right'});

                        // Display holidays, special days and such
                        doc.setTextColor(this.get_day_color(day));
                        if ((day.is_holiday || day.is_flagday || day.is_special_day) && this.show_holidays)
                        {
                            let lines = 0;
                            let rows = [];
                            for (let desc of day.description)
                            {
                                var new_rows = doc.splitTextToSize(desc, 60);
                                rows = [...rows, ...new_rows];
                            }
                            lines += rows.length;
                            doc.setFontSize(5);
                            doc.setFont(font_name, font_type);
                            doc.text(rows, xpos + 0.5, ypos + 0.5 - (lines - 1) * 2, { align: 'left' });
                        }

                        // Display moon
                        doc.setLineWidth(0.4);
                        doc.setDrawColor(0, 0, 0);
                        doc.setFillColor(0, 0, 0);
                        var r = 1.2
                        xpos = xmin + 15 + gridwidth - 2.5;
                        ypos = ypos - 0.5;
                        let moon_pad = 0;
                        if (this.show_moon_phases)
                        {
                            if (day.moon_phase == "new")
                            {
                                doc.circle(xpos, ypos, r, 'DF');
                                moon_pad = 3.5;
                            }
                            if (day.moon_phase == "first quarter")
                            {
                                doc.circle(xpos, ypos, r, 'S');
                                doc.line(xpos - 0.2, ypos - r, xpos - 0.2, ypos + r);
                                doc.line(xpos - 0.3, ypos - r + 0.2, xpos - 0.3, ypos + r - 0.2);
                                doc.line(xpos - 0.6, ypos - r + 0.3, xpos - 0.6, ypos + r - 0.3);
                                doc.line(xpos - 0.9, ypos - r + 0.4, xpos - 0.9, ypos + r - 0.4);
                                moon_pad = 3.5;
                            }
                            if (day.moon_phase == "full")
                            {
                                doc.circle(xpos, ypos, r, 'S');
                                moon_pad = 3.5;
                            }
                            if (day.moon_phase == "third quarter")
                            {
                                doc.circle(xpos, ypos, r, 'S');
                                doc.line(xpos + 0.2, ypos - r, xpos + 0.2, ypos + r);
                                doc.line(xpos + 0.3, ypos - r + 0.2, xpos + 0.3, ypos + r - 0.2);
                                doc.line(xpos + 0.6, ypos - r + 0.3, xpos + 0.6, ypos + r - 0.3);
                                doc.line(xpos + 0.9, ypos - r + 0.4, xpos + 0.9, ypos + r - 0.4);
                                moon_pad = 3.5;
                                //doc.lines([[0,r], [0, r, r, 0, 0, r], [0, -r]], xpos, ypos, [1, 1]);
                            }
                        }
                        
                        // Display flag
                        var flag = false;
                        var flagDiff = 0;
                        xpos = xpos - moon_pad - 3;
                        ypos = ypos - 1.4;
                        if (day.is_flagday && this.show_flagdays)
                        {
                            if (day.padding)
                            {
                                doc.setDrawColor(179, 179, 179);
                                doc.setFillColor(179, 179, 179);
                            }
                            else
                            {
                                doc.setDrawColor(0, 81, 155);
                                doc.setFillColor(0, 81, 155);
                            }
                            doc.rect(xpos, ypos - flagDiff, 4, 2.5, 'F');
                            if (day.padding)
                            {
                                doc.setDrawColor(237, 236, 229);
                                doc.setFillColor(237, 236, 229);
                            }
                            else
                            {
                                doc.setDrawColor(255, 206, 0);
                                doc.setFillColor(255, 206, 0);
                            }
                            doc.rect(xpos + 1.25, ypos - flagDiff, 0.5, 2.5, 'F');
                            doc.rect(xpos, ypos + 1 - flagDiff, 4, 0.5, 'F');
                            flag = true;
                        }

                        xpos = xmin + 16 + gridwidth;
                        ypos = gridtop + ((day.day_of_month + 1) * gridheight) - 3 - half_month * gridheight * 16 - 4;
                        for (let i = 0; i < this.celebrations_columns; i++) 
                        {
                            if (day.celebration && day.celebration[i])
                            {
                                doc.setFont(font_name, font_type);
                                doc.setFontSize(7);
                                let lines = day.celebration[i].length;  
                                doc.text(day.celebration[i], xpos + i * gridwidth, ypos - (lines - 1) * 1.3, { align: 'left' });
                            }
                        }
                    }
                }
            }
        }

        doc.save('kalendar.pdf');
    }
    
    createNameListLandscape()
    {
        // Choose font
        let font_name = 'helvetica';
        let font_type = 'normal';
        var doc = new jsPDF({
            orientation: 'l',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts:true
        });
        
        const xtot = 297;
        const ytot = 210;

        const xmin = 5;
        const ymin = 5;

        const xmax  = xtot - xmin;
        const ymax  = ytot - ymin - 5;

        this.addFont(doc);

        const row1 = "Kalender";
        const row2 = "" + this.year.year;
        const copy = "Creative Commons";

        doc.setFontSize(56 + this.font.compensate);
        doc.setFont(this.font.value, 'normal');
        doc.text(row1, xmin + (xmax - xmin) / 2, ymin + (ymax - ymin) / 2 - 27, {align: 'center'});
        doc.text(row2, xmin + (xmax - xmin) / 2, ymin + (ymax - ymin) / 2, {align: 'center'});

        doc.setFont(font_name, font_type);
        doc.setFontSize(9);
        doc.text(copy, xmin + (xmax - xmin) / 2, ymax - 5, {align: 'center'});
        doc.addImage("app/calendar/creator/assets/cc-by-sa.png", 'PNG', xmin + (xmax - xmin)/2 - 7.5, ymax - 3, 15, 4);

        
        let color = [];
        color[1] = '#f5e6ff';
        color[2] = '#ffffc8';
        color[3] = '#ebffeb';
        color[4] = '#ebebff';
        color[5] = '#f4e1bc';
        color[6] = '#d5d5d5';
        color[7] = '#f5e6ff';
        color[8] = '#ffffc8';
        color[9] = '#ebffeb';
        color[10] = '#ebebff';
        color[11] = '#f4e1bc';
        color[12] = '#ffe1e1';

        let gridtop = ymin + 20;
        let gridheight = (ymax - gridtop) / 32;
        let gridwidth = ((xmax - xmin) - 20) / (this.namelist_columns + 1);

        for (let month of this.year.months)
        {
            doc.addPage();
            doc.setFillColor(color[month.month]);
            doc.rect(0, 0, xtot, ytot, 'F');
            doc.setFont(this.font.value, 'normal');
            doc.setFontSize(48);
            doc.setTextColor(0, 0, 0);

            let year_with_month = month.month_name + (this.show_year_with_name_of_month ? ' ' + month.year : '');
            doc.text(this.upperFirst(year_with_month), xtot / 2, 20, { align: 'center'});
         

            doc.setFillColor(255, 255, 255);
            doc.setDrawColor(0, 0, 0);
            doc.rect(xmin + 15 + gridwidth, gridtop, gridwidth * (this.namelist_columns), gridheight * 32, 'F');

            // Vertical lines
            for (let i = 0; i < (this.namelist_columns + 2); i++)
            {
                let y0 = gridtop;
                let y1 = gridtop + gridheight * 32;
                let x = xmin + 15 + (i * gridwidth);
                doc.line(x, y0, x, y1);
            }

            // Horisontal lines
            for (let i = 0; i < 33; i++)
            {
                let x0 = xmin + 15;
                let x1 = xmin + 15 + gridwidth * (this.namelist_columns + 1);
                let y = gridtop + (i * gridheight);
                doc.line(x0, y, x1, y);
            }

            doc.setFont(font_name, font_type);
            doc.setFontSize(12 + this.font.compensate);
            for (let i = 0; i < this.namelist_columns; i++)
            {
                doc.text(this.listNames[i], xmin + 15 + gridwidth +(i * gridwidth) + (gridwidth/2), gridtop + 4, { align: 'center'});
            }

            let oldWeek = -1;
            for(let day of month.days)
            {
                if (!day.padding)
                {
                    let weekno = day.week;
                    if (weekno != oldWeek)
                    {
                        oldWeek = weekno;
                        doc.setFont(font_name, 'normal');
                        doc.setFontSize(10);
                        doc.setTextColor(this.colour_weeknumbers);
                        let ypos = gridtop + day.day_of_month * gridheight + gridheight - 2;
                        doc.text("v." + weekno, xmin + 13, ypos, { align: 'right'});
                    }

                    doc.setTextColor(this.get_day_color(day));
                    doc.setFont(this.font.value, 'normal');
                    doc.setFontSize(14 + this.font.compensate);
                    let xpos = xmin + 22;
                    let ypos = gridtop + ((day.day_of_month + 1) * gridheight) - 1;
                    doc.text(String(day.day_of_month), xpos, ypos, { align: 'right'});

                    // Display name of weekdays
                    doc.setFont(font_name, 'bold');
                    doc.setFontSize(7);
                    doc.text(day.dayname, xpos + 0.5, ypos - 2, { align: 'left'});
                    let dayname_width = doc.getTextWidth(day.dayname);

                    // Display name days
                    doc.setFont(font_name, font_type);
                    doc.setFontSize(4);
                    doc.text(day.namesdays, xmin + 15 + gridwidth - 0.6, ypos - 2.9, { align: 'right'});

                    // Display holidays, special days and such
                    doc.setTextColor(this.get_day_color(day));
                    if ((day.is_holiday || day.is_flagday || day.is_special_day) && this.show_holidays)
                    {
                        let lines = 0;
                        let row = "";
                        for (let desc of day.description)
                        {
                            if (lines == 0)
                            {
                                row = desc.replace("\n", " ");
                            }
                            else
                            {
                                row = row + ", " + desc.replace("\n", " ");
                            }
                            lines++;
                        }
                        doc.setFontSize(5);
                        doc.setFont(font_name, font_type);
                        doc.text(row, xpos + 0.5, ypos + 0.2, { align: 'left' });
                    }

                    // Display moon
                    doc.setLineWidth(0.21);
                    doc.setDrawColor(0, 0, 0);
                    doc.setFillColor(0, 0, 0);
                    var r = 0.8
                    xpos = xmin + 22 + 0.5 + dayname_width + 2;
                    ypos = ypos - 2.7;
                    let moon_pad = 0;
                    if (this.show_moon_phases)
                    {
                        if (day.moon_phase == "new")
                        {
                            doc.circle(xpos, ypos, r, 'DF');
                            moon_pad = 3;
                        }
                        if (day.moon_phase == "first quarter")
                        {
                            doc.circle(xpos, ypos, r, 'S');
                            doc.line(xpos, ypos - r, xpos, ypos + r);
                            doc.line(xpos - 0.2, ypos - r + 0.1, xpos - 0.2, ypos + r - 0.1);
                            doc.line(xpos - 0.4, ypos - r + 0.15, xpos - 0.4, ypos + r - 0.15);
                            doc.line(xpos - 0.6, ypos - r + 0.3, xpos - 0.6, ypos + r - 0.3);
                            moon_pad = 3;
                        }
                        if (day.moon_phase == "full")
                        {
                            doc.circle(xpos, ypos, r, 'S');
                            moon_pad = 3;
                        }
                        if (day.moon_phase == "third quarter")
                        {
                            doc.circle(xpos, ypos, r, 'S');
                            doc.line(xpos, ypos - r, xpos, ypos + r);
                            doc.line(xpos + 0.2, ypos - r + 0.1, xpos + 0.2, ypos + r - 0.1);
                            doc.line(xpos + 0.4, ypos - r + 0.15, xpos + 0.4, ypos + r - 0.15);
                            doc.line(xpos + 0.6, ypos - r + 0.3, xpos + 0.6, ypos + r - 0.3);
                            moon_pad = 3;
                            //doc.lines([[0,r], [0, r, r, 0, 0, r], [0, -r]], xpos, ypos, [1, 1]);
                        }
                    }
                    
                    // Display flag
                    var flag = false;
                    var flagDiff = 0;
                    xpos = xpos + moon_pad - 1;
                    ypos = ypos - 1;
                    if (day.is_flagday && this.show_flagdays)
                    {
                        if (day.padding)
                        {
                            doc.setDrawColor(179, 179, 179);
                            doc.setFillColor(179, 179, 179);
                        }
                        else
                        {
                            doc.setDrawColor(0, 81, 155);
                            doc.setFillColor(0, 81, 155);
                        }
                        doc.rect(xpos, ypos - flagDiff, 4, 2.5, 'F');
                        if (day.padding)
                        {
                            doc.setDrawColor(237, 236, 229);
                            doc.setFillColor(237, 236, 229);
                        }
                        else
                        {
                            doc.setDrawColor(255, 206, 0);
                            doc.setFillColor(255, 206, 0);
                        }
                        doc.rect(xpos + 1.25, ypos - flagDiff, 0.5, 2.5, 'F');
                        doc.rect(xpos, ypos + 1 - flagDiff, 4, 0.5, 'F');
                        flag = true;
                    }

                    xpos = xmin + 16 + gridwidth;
                    ypos = gridtop + ((day.day_of_month + 1) * gridheight) - 2.4;
                    for (let i = 0; i < this.celebrations_columns; i++) 
                    {
                        if (day.celebration && day.celebration[i])
                        {
                            doc.setFont(font_name, font_type);
                            doc.setFontSize(4);
                            let lines = day.celebration[i].length;  
                            doc.text(day.celebration[i], xpos + i * gridwidth, ypos - (lines - 1) * 0.8, { align: 'left' });
                        }
                    }
                }
            }
        }

        doc.save('kalendar.pdf');
    }

    createNameListSaddleStitch()
    {
        // Choose font
        let font_name = 'helvetica';
        let font_type = 'normal';
        var doc = new jsPDF({
            orientation: 'l',
            unit: 'mm',
            format: 'a5',
            putOnlyUsedFonts:true
        });
        
        const xtot = 105;
        const ytot = 148;

        const xmin = 2.5;
        const ymin = 2.5;

        const xmax  = xtot - xmin;
        const ymax  = ytot - ymin - 2.5;

        this.addFont(doc);

        const row1 = "Kalender";
        const row2 = "" + this.year.year;
        const copy = "Creative Commons";

        doc.setFontSize(28 + this.font.compensate);
        doc.setFont(this.font.value, 'normal');
        doc.text(row1, xmin + (xmax - xmin) / 2, ymin + (ymax - ymin) / 2 - 14, {align: 'center'});
        doc.text(row2, xmin + (xmax - xmin) / 2, ymin + (ymax - ymin) / 2, {align: 'center'});

        // Add text       
        doc.setFont(font_name, font_type);
        doc.setFontSize(6);
        doc.text(copy, xmin + (xmax - xmin) / 2, ymax - 2.5, {align: 'center'});
        doc.addImage("app/calendar/creator/assets/cc-by-sa.png", 'PNG', xmin + (xmax - xmin)/2 - 4.25, ymax - 1.5, 7.5, 2);

        
        let color = [];
        color[1] = '#f5e6ff';
        color[2] = '#ffffc8';
        color[3] = '#ebffeb';
        color[4] = '#ebebff';
        color[5] = '#f4e1bc';
        color[6] = '#d5d5d5';
        color[7] = '#f5e6ff';
        color[8] = '#ffffc8';
        color[9] = '#ebffeb';
        color[10] = '#ebebff';
        color[11] = '#f4e1bc';
        color[12] = '#ffe1e1';

        let gridtop = ymin + 10;
        let gridheight = (ymax - gridtop) / 32;
        let gridwidth = ((xmax - xmin) - 10) / (this.namelist_columns + 1);

        const order = [11, 0, 1, 10, 9, 2, 3, 8, 7, 4, 5, 6];
        const ordered_months: Array<any> = order.map((entry: number) => this.year.months[entry]);

        doc.addPage();
        let count = 0;
        let page_pad = 0;
        for (let month of ordered_months)
        {
            if (count % 2 == 0)
            {
                doc.addPage();
                page_pad = 0;
            }
            else
            {
                page_pad = 105;
            }

            doc.setFillColor(color[month.month]);
            doc.rect(page_pad, 0, page_pad + xtot, ytot, 'F');
            doc.setFont(this.font.value, 'normal');
            doc.setFontSize(18);
            doc.setTextColor(0, 0, 0);

            let year_with_month = month.month_name + (this.show_year_with_name_of_month ? ' ' + month.year : '');
            doc.text(this.upperFirst(year_with_month), page_pad + xtot / 2, 10, { align: 'center'});
         

            doc.setFillColor(255, 255, 255);
            doc.setDrawColor(0, 0, 0);
            doc.rect(page_pad + xmin + 7.5 + gridwidth, gridtop, gridwidth * (this.namelist_columns), gridheight * 32, 'F');

            // Vertical lines
            for (let i = 0; i < (this.namelist_columns + 2); i++)
            {
                let y0 = gridtop;
                let y1 = gridtop + gridheight * 32;
                let x = page_pad + xmin + 7.5 + (i * gridwidth);
                doc.line(x, y0, x, y1);
            }

            // Horisontal lines
            for (let i = 0; i < 33; i++)
            {
                let x0 = page_pad + xmin + 7.5;
                let x1 = page_pad + xmin + 7.5 + gridwidth * (this.namelist_columns + 1);
                let y = gridtop + (i * gridheight);
                doc.line(x0, y, x1, y);
            }

            doc.setFont(font_name, font_type);
            doc.setFontSize(7 + this.font.compensate);
            for (let i = 0; i < this.namelist_columns; i++)
            {
                doc.text(this.listNames[i], page_pad + xmin + 7.5 + gridwidth +(i * gridwidth) + (gridwidth/2), gridtop + 3, { align: 'center'});
            }

            let oldWeek = -1;
            for(let day of month.days)
            {
                if (!day.padding)
                {
                    let weekno = day.week;
                    if (weekno != oldWeek)
                    {
                        oldWeek = weekno;
                        doc.setFont(font_name, 'normal');
                        doc.setFontSize(6);
                        doc.setTextColor(this.colour_weeknumbers);
                        let ypos = gridtop + day.day_of_month * gridheight + gridheight - 1;
                        doc.text("v." + weekno, page_pad + xmin + 6.5, ypos, { align: 'right'});
                    }

                    doc.setTextColor(this.get_day_color(day));
                    doc.setFont(this.font.value, 'normal');
                    doc.setFontSize(7 + this.font.compensate);
                    let xpos = page_pad + xmin + 12;
                    let ypos = gridtop + ((day.day_of_month + 1) * gridheight) - 1.5;
                    doc.text(String(day.day_of_month), xpos - 4, ypos + 0.4, { align: 'left'});

                    // Display name of weekdays
                    doc.setFont(font_name, 'bold');
                    doc.setFontSize(4);
                    let dayname = "";
                    if (this.namelist_columns >= 5)
                    {
                        dayname = day.dayname.substring(0, 3);
                    }
                    else
                    {
                        dayname = day.dayname;
                    }
                    doc.text(dayname, xpos - 1.2, ypos - 0.9, { align: 'left'});

                    // Display name days
                    doc.setFont(font_name, font_type);
                    doc.setFontSize(3);
                    doc.text(day.namesdays, page_pad + xmin + 7.8 + gridwidth - 1, ypos - 1.3, { align: 'right'});

                    // Display holidays, special days and such
                    doc.setTextColor(this.get_day_color(day));
                    if ((day.is_holiday || day.is_flagday || day.is_special_day) && this.show_holidays)
                    {
                        let lines = 0;
                        let rows = [];
                        for (let desc of day.description)
                        {
                            var new_rows = doc.splitTextToSize(desc, 20);
                            rows = [...rows, ...new_rows];
                        }
                        lines += rows.length;
                        doc.setFontSize(2);
                        doc.setFont(font_name, font_type);
                        doc.text(rows, page_pad + xpos - 1.2, ypos + 0.9 - (lines - 1) * 0.8, { align: 'left' });
                    }

                    // Display moon
                    doc.setLineWidth(0.1);
                    doc.setDrawColor(0, 0, 0);
                    doc.setFillColor(0, 0, 0);
                    var r = 0.4
                    xpos = page_pad + xmin - 1.1 + gridwidth;
                    ypos = ypos - 1.8;
                    let moon_pad = 0;
                    if (this.show_moon_phases)
                    {
                        if (day.moon_phase == "new")
                        {
                            doc.circle(xpos, ypos, r, 'DF');
                            moon_pad = 1.2;
                        }
                        if (day.moon_phase == "first quarter")
                        {
                            doc.circle(xpos, ypos, r, 'S');
                            doc.line(xpos - 0.05, ypos - r, xpos - 0.05, ypos + r);
                            doc.line(xpos - 0.1, ypos - r + 0.05, xpos - 0.1, ypos + r - 0.05);
                            doc.line(xpos - 0.15, ypos - r + 0.1, xpos - 0.15, ypos + r - 0.1);
                            doc.line(xpos - 0.2, ypos - r + 0.15, xpos - 0.2, ypos + r - 0.15);
                            doc.line(xpos - 0.27, ypos - r + 0.22, xpos - 0.27, ypos + r - 0.22);
                            moon_pad = 1.2;
                        }
                        if (day.moon_phase == "full")
                        {
                            doc.circle(xpos, ypos, r, 'S');
                            moon_pad = 1.2;
                        }
                        if (day.moon_phase == "third quarter")
                        {
                            doc.circle(xpos, ypos, r, 'S');
                            doc.line(xpos + 0.05, ypos - r, xpos + 0.05, ypos + r);
                            doc.line(xpos + 0.1, ypos - r + 0.05, xpos + 0.1, ypos + r - 0.05);
                            doc.line(xpos + 0.15, ypos - r + 0.1, xpos + 0.15, ypos + r - 0.1);
                            doc.line(xpos + 0.2, ypos - r + 0.15, xpos + 0.2, ypos + r - 0.15);
                            doc.line(xpos + 0.27, ypos - r + 0.22, xpos + 0.27, ypos + r - 0.22);
                            moon_pad = 1.2;
                            //doc.lines([[0,r], [0, r, r, 0, 0, r], [0, -r]], xpos, ypos, [1, 1]);
                        }
                    }
                    
                    // Display flag
                    xpos = xpos - r;
                    ypos = ypos + moon_pad - 0.4;
                    if (day.is_flagday && this.show_flagdays)
                    {
                        if (day.padding)
                        {
                            doc.setDrawColor(179, 179, 179);
                            doc.setFillColor(179, 179, 179);
                        }
                        else
                        {
                            doc.setDrawColor(0, 81, 155);
                            doc.setFillColor(0, 81, 155);
                        }
                        doc.rect(xpos, ypos, 1.6, 1, 'F');
                        if (day.padding)
                        {
                            doc.setDrawColor(237, 236, 229);
                            doc.setFillColor(237, 236, 229);
                        }
                        else
                        {
                            doc.setDrawColor(255, 206, 0);
                            doc.setFillColor(255, 206, 0);
                        }
                        doc.rect(xpos + 0.5, ypos, 0.2, 1, 'F');
                        doc.rect(xpos, ypos + 0.4, 1.6, 0.2, 'F');
                    }

                    xpos = page_pad + xmin + 8 + gridwidth;
                    for (let i = 0; i < this.celebrations_columns; i++) 
                    {
                        if (day.celebration && day.celebration[i])
                        {
                            doc.setFont(font_name, font_type);
                            doc.setFontSize(6);
                            let lines = day.celebration[i].length;  
                            doc.text(day.celebration[i], xpos + i * gridwidth, ypos - (lines - 1) * 1.3, { align: 'left' });
                        }
                    }
                }
            }
            count++;
        }
        doc.save('kalendar.pdf');
    }


    createYearPlanner()
    {
        // Choose font
        let font_name = 'helvetica';
        let font_type = 'normal';
        
        var doc = new jsPDF({
            orientation: 'l',
            unit: 'mm',
            format: 'a3',
            putOnlyUsedFonts:true
        })

        this.addFont(doc);

        const xtot = 420;
        const ytot = 297;

        const xmin = 10;
        const ymin = 15;

        const xmax  = xtot - xmin;
        const ymax  = ytot - ymin;


        let week_day_width = 0;
        let week_no_width = 10.8;

        let no_of_cells = 37;

        let cell_height = (ymax - ymin) / no_of_cells;
        let cell_width = (xmax - xmin - week_day_width) / 6;


        for (let half_year = 0; half_year < 2; half_year++)
        {
            if (half_year > 0)
            {
                doc.addPage();
            }

            let font_size = 248 + this.font.compensate;
            doc.setFontSize(font_size);
            doc.setFont(font_name, 'bold');
            doc.setTextColor('#fae6e6');
            doc.text(this.year.year, xtot / 2, ytot / 2, { align: 'center', baseline: 'middle' });

            let offset = 0;
            let idx = 0;
            for (idx = half_year * 6; idx < (6 + (half_year * 6)); idx++)
            {
                let month = this.year.months[idx];

                // Vertical lines
                offset = month.days[0].weekday * cell_height;
                doc.setDrawColor(0, 0, 0);
                let x = xmin + week_day_width + (cell_width * (month.month - 1 - (half_year * 6)));
                let y0 = ymin + offset - 5;
                let y1 = ymin + offset + (cell_height * month.days_in_month) - 5;
                doc.line(x, y0, x, y1);

                x = xmin + week_day_width + (cell_width * (month.month - 1 - (half_year * 6))) + week_no_width;
                doc.line(x, y0, x, y1);

                x = xmin + week_day_width + (cell_width * (month.month - 1 - (half_year * 6))) + cell_width;
                doc.line(x, y0, x, y1);

                for (let day of month.days)
                {
                    if (day.day_of_month == 1)
                    {
                        offset = day.weekday * cell_height;

                        doc.setFontSize(14 + this.font.compensate);
                        doc.setFont(this.font.value, 'normal');
                        doc.setTextColor('#000000');
                        doc.text(month.month_name.toLowerCase(), xmin + (cell_width * (month.month - 1 - (half_year * 6) + 0.5)), ymin + offset - 8, { align: 'center' });
                    }

                    let extra_width = 0;
                    if (day.weekday == 7)
                    {
                        if (day.day_of_month == 1)
                        {
                            extra_width = 0;
                        }
                        else
                        {
                            extra_width = week_no_width;
                        }

                        // Horisontal lines lines
                        doc.setDrawColor(255, 0, 0);
                        let x0 = xmin + (cell_width * (month.month - 1 - (half_year * 6)));
                        let y = ymin + (day.day_of_month * cell_height) + offset - 5;
                        let x1 = xmin + (cell_width * (month.month - (half_year * 6)));
                        doc.line(x0, y, x1, y);
                        x0 = xmin + (cell_width * (month.month - 1 - (half_year * 6))) + extra_width;
                        y = ymin + ((day.day_of_month - 1) * cell_height) + offset - 5;
                        x1 = xmin + (cell_width * (month.month - (half_year * 6)));
                        doc.line(x0, y, x1, y);
                    }
                    else if (day.day_of_month == month.days_in_month)
                    {
                        doc.setDrawColor(0, 0, 0);
                        let x0 = xmin + (cell_width * (month.month - 1 - (half_year * 6)));
                        let y = ymin + (day.day_of_month * cell_height) + offset - 5;
                        let x1 = xmin + (cell_width * (month.month - (half_year * 6)));
                        doc.line(x0, y, x1, y);
                    }
                    else
                    {
                        // Horisontal lines lines
                        doc.setDrawColor(this.get_day_color(day));
                        let x0 = xmin + (cell_width * (month.month - 1 - (half_year * 6))) + week_no_width;
                        let y = ymin + (day.day_of_month * cell_height) + offset - 5;
                        let x1 = xmin + (cell_width * (month.month - (half_year * 6)));
                        doc.line(x0, y, x1, y);
                        if (day.day_of_month == 1)
                        {
                            let x0 = xmin + (cell_width * (month.month - 1 - (half_year * 6)));
                            let y = ymin + ((day.day_of_month-1) * cell_height) + offset - 5;
                            let x1 = xmin + (cell_width * (month.month - (half_year * 6)));
                            doc.line(x0, y, x1, y);
                        }
                    }
                    // Day of the month
                    doc.setTextColor(this.get_day_color(day));
                    doc.setFontSize(12 + this.font.compensate);
                    doc.setFont(this.font.value, 'normal');
                    doc.text("" + day.day_of_month, xmin + 1 + (cell_width * (month.month - 1 - (half_year * 6))) + week_no_width, 
                        ymin + (day.day_of_month * cell_height) + offset - 8, { align: 'left' });
                    let day_pad = doc.getTextWidth("" + day.day_of_month);

                    doc.setFontSize(6);
                    doc.setFont(font_name, 'bold');
                    doc.text(day.dayname.toLowerCase().substring(0, 2), xmin + 1 + (cell_width * (month.month - 1 - (half_year * 6))) + week_no_width + day_pad/2, 
                        ymin + (day.day_of_month * cell_height) + offset - 5.8, { align: 'center' });

                    // Display name days
                    doc.setFont(font_name, font_type);
                    doc.setFontSize(5.5);
                    doc.text(day.namesdays, xmin + (cell_width * (month.month - (half_year * 6))) + week_no_width - 11.5, 
                        ymin + (day.day_of_month * cell_height) + offset - 10, { align: 'right'});

                    // Display holidays, special days and such
                    let xpos = xmin + 2 + (cell_width * (month.month - 1 - (half_year * 6))) + week_no_width + day_pad;
                    let ypos = ymin + (day.day_of_month * cell_height) + offset - 10;
                    doc.setFontSize(5.5);
                    doc.setFont(font_name, font_type);
                    let desc_pad = 0;
                    let lines = 0;
                    if ((day.is_holiday || day.is_flagday || day.is_special_day) && this.show_holidays)
                    {
                        let rows = [];
                        for (let desc of day.description)
                        {
                            var new_rows = doc.splitTextToSize(desc, 200);
                            rows = [...rows, ...new_rows];
                        }
                        lines += rows.length;
                        doc.text(rows, xpos, ypos, { align: 'left' });
                        for (let row of rows)
                        {
                            desc_pad = Math.max(desc_pad, doc.getTextWidth(row));
                        }
                    }

                    // Display flag
                    var flagDiff = 0;
                    xpos = xpos + desc_pad + 2;
                    ypos = ypos - 1.5;
                    let flag_pad = 0;
                    if (day.is_flagday && this.show_flagdays)
                    {
                        if (day.padding)
                        {
                            doc.setDrawColor(179, 179, 179);
                            doc.setFillColor(179, 179, 179);
                        }
                        else
                        {
                            doc.setDrawColor(0, 81, 155);
                            doc.setFillColor(0, 81, 155);
                        }
                        doc.rect(xpos, ypos - flagDiff, 4, 2.5, 'F');
                        if (day.padding)
                        {
                            doc.setDrawColor(237, 236, 229);
                            doc.setFillColor(237, 236, 229);
                        }
                        else
                        {
                            doc.setDrawColor(255, 206, 0);
                            doc.setFillColor(255, 206, 0);
                        }
                        doc.rect(xpos + 1.25, ypos - flagDiff, 0.5, 2.5, 'F');
                        doc.rect(xpos, ypos + 1 - flagDiff, 4, 0.5, 'F');
                        flag_pad = 4.5;
                    }

                    // Display moon
                    doc.setLineWidth(0.4);
                    doc.setDrawColor(0, 0, 0);
                    doc.setFillColor(0, 0, 0);
                    var r = 1.2
                    if (lines == 0)
                    {
                        xpos = xpos;
                        ypos = ypos + 1.5;
                    }
                    else if (lines == 1)
                    {
                        xpos = xpos - desc_pad;
                        ypos = ypos + 4;
                    }
                    else
                    {
                        xpos = xpos + 2;
                        ypos = ypos + flag_pad;
                    }
                    let moon_pad = 0;
                    if (this.show_moon_phases)
                    {
                        if (day.moon_phase == "new")
                        {
                            doc.circle(xpos, ypos, r, 'DF');
                            moon_pad = 3.5;
                        }
                        if (day.moon_phase == "first quarter")
                        {
                            doc.circle(xpos, ypos, r, 'S');
                            doc.line(xpos - 0.2, ypos - r, xpos - 0.2, ypos + r);
                            doc.line(xpos - 0.3, ypos - r + 0.2, xpos - 0.3, ypos + r - 0.2);
                            doc.line(xpos - 0.6, ypos - r + 0.3, xpos - 0.6, ypos + r - 0.3);
                            doc.line(xpos - 0.9, ypos - r + 0.4, xpos - 0.9, ypos + r - 0.4);
                            moon_pad = 3.5;
                        }
                        if (day.moon_phase == "full")
                        {
                            doc.circle(xpos, ypos, r, 'S');
                            moon_pad = 3.5;
                        }
                        if (day.moon_phase == "third quarter")
                        {
                            doc.circle(xpos, ypos, r, 'S');
                            doc.line(xpos + 0.2, ypos - r, xpos + 0.2, ypos + r);
                            doc.line(xpos + 0.3, ypos - r + 0.2, xpos + 0.3, ypos + r - 0.2);
                            doc.line(xpos + 0.6, ypos - r + 0.3, xpos + 0.6, ypos + r - 0.3);
                            doc.line(xpos + 0.9, ypos - r + 0.4, xpos + 0.9, ypos + r - 0.4);
                            moon_pad = 3.5;
                            //doc.lines([[0,r], [0, r, r, 0, 0, r], [0, -r]], xpos, ypos, [1, 1]);
                        }
                    }
                    
                    // Display birthday or whatever

                    // Week number
                    if (day.weekday == 7 || day.day_of_month == month.days_in_month)
                    {
                        doc.setFontSize(12 + this.font.compensate);
                        doc.setFont(this.font.value, 'normal');
                        doc.setTextColor(200, 200, 200);

                        let week_no = day.week;
                        let pad = (week_no_width - doc.getTextWidth("" + week_no)) / 2;
                        // Get offset if first week has less than seven days
                        let days_in_week = 7;
                        if (day.day_of_month < 7)
                        {
                            days_in_week = day.day_of_month;
                        }
                        else if (day.day_of_month == month.days_in_month)
                        {
                            days_in_week = day.weekday;
                        }
                        doc.text("" + week_no, xmin + week_day_width + (cell_width * (month.month - 1 - (half_year * 6))) + pad,
                                    ymin + (day.day_of_month * cell_height) - (cell_height * days_in_week / 2) + offset + (cell_height) - 10);
                    }
                }

                const copy = "Creative Commons";
                doc.setFont(font_name, font_type);
                doc.setFontSize(9);
                doc.setTextColor(0, 0, 0);
                doc.text(copy, xmin + (xmax - xmin) / 2, ymax + 7, {align: 'center'});
                doc.addImage("app/calendar/creator/assets/cc-by-sa.png", 'PNG', xmin + (xmax - xmin)/2 - 7.5, ymax + 9, 15, 4);        
            }
        }
        doc.save('kalendar.pdf');
    }

    upperFirst(word)
    {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    get_day_color(day) {
        if (day.padding)
        {
        return "#cccccc";
        }
        if (day.weekday == 7 || day.is_holiday)
        {
            return "#ff0000";
        }
        else
        {
            return "#000000";
        }
    }

    onFileSelected(event, month) {
        this.current_idx = month;
        this.resetImage();
        console.log('onfileselected', event, month);
        this.current_idx = month;
        this.months[this.current_idx].inputFile = event;
        this.imageChangedEvent = event;
        this.cropper = this.cropperDefault;
    }

    colourPickerReset()
    {
        this.colour_weekdays = "#b3b3b3";
        this.colour_weeknumbers = "#b3b3b3";
        this.colour_grid = "#b3b3b3";
    }

    imageCropped(event: ImageCroppedEvent) {
        console.log('imageCropped', event);
        this.months[this.current_idx].file = event;
        this.months[this.current_idx].transform = this.transform;
    }

    imageLoaded(image: LoadedImage) {
        console.log('imageLoaded', image);
        this.showCropper = true;
        this.transform = this.months[this.current_idx].transform;
    }

    cropperReady() {
        console.log('cropperReady');
        this.cropper = this.months[this.current_idx].file ? this.months[this.current_idx].file.cropperPosition : this.cropperDefault;
        document.getElementById('imageControlButtons').style.visibility = 'visible';
    }

    loadImageFailed() {

    }

    imageSelectClick(id)
    {
        document.getElementById(id).click();
    }

    zoomOut() {
        this.scale -= .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
        this.months[this.current_idx].transform = this.transform;
    }
    
    zoomIn() {
        this.scale += .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
        this.months[this.current_idx].transform = this.transform;
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = this.months[this.current_idx].transform ;
    }

    editImage(idx) {
        this.resetImage();
        this.current_idx = idx;
        this.imageChangedEvent = this.months[idx].inputFile;
    }

    getLayoutImage() {
        console.log('layout', this.layouts.find(l => l.value == this.layout).thumbnail);
        return this.layouts.find(l => l.value == this.layout).thumbnail;
    }
}
