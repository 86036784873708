import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LightboxComponent } from './lightbox/lightbox.component';


@NgModule({
  declarations: [ LightboxComponent ],
  imports: [
    CommonModule
  ]
})
export class LightboxModule { }
