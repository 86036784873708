
<div class="crumb-container">
    <div class="crumb" *ngFor="let crumb of crumbs">
        <a [routerLink]="['/gallery', crumb.link]">{{ crumb.crumb }}</a>&nbsp;/&nbsp;
    </div>
</div>

<div class="wrapper">
    <div mat-list-item class="item" *ngFor="let folder of folders; let i = index">
        <a [routerLink]="['/gallery', path == null || path == '' ? folder.path : path + '/' + folder.path]">
            <div class="polaroid">
                <picture>
                    <img src="" />
                </picture>
                <div class="caption folder"><i class="material-icons md-36">folder</i> {{ folder?.title }}</div>
                <div class="shadow">Shadow :-P</div>
            </div>
        </a>
    </div>
    <div mat-list-item class="item" *ngFor="let file of files; let i = index">
        <div class="polaroid">
            <picture>
                <img src="{{ file?.thumb_url }}" (click)="openModal(i)" />
            </picture>
            <div class="caption" *ngIf="file.comment != null">{{ file?.comment }}</div>
            <div class="caption" *ngIf="!file.comment">{{ file?.title }}</div>
            <div class="shadow">Shadow :-P</div>
        </div>
    </div>
</div>

<div id="imgModal" class="slide-modal" @myInsertRemoveTrigger *ngIf="showModal">
    <span class="close cursor" (click)="closeModal()">&times;</span>
    <div class="slide-modal-content">
        <div class="img-slides" (click)="onClick($event)" (touchstart)="onSwipe($event, 'start')" (touchend)="onSwipe($event, 'end')">
            <img src="{{ currentSlide?.src }}" *ngIf="currentSlide?.type == 'image'">
            <video id='gallery-video' *ngIf="currentSlide?.type == 'video'" controls>
                <source src="{{ currentSlide?.src }}" type="video/mp4">
            </video>
        </div>
        <div *ngIf="!(this.platform.ANDROID || this.platform.IOS)">
            <a class="prev" (click)="previousImage()">&#10094;</a>
            <a class="next" (click)="nextImage()">&#10095;</a>
        </div>
    </div>
</div>