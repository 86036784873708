<div class="all">
    <div class="loading-bar">
        <ngx-loading-bar></ngx-loading-bar>
    </div>
    <div class="main">
        <mat-toolbar color="primary" class="header" id="main-toolbar">
            <mat-toolbar-row>
                <button mat-icon-button (click)="sidenav.toggle()" class="menu-button">
                    <mat-icon>menu</mat-icon>
                </button>
                <button mat-button [routerLink]="['/start-page']">
                    <fa-icon [icon]="faRegistered"></fa-icon>
                    <span>&nbsp;renner.se</span>
                </button>
                <span class="spacer"></span>
                <div id="dynamic-menu">
                    <!-- The following menu items will be hidden on both SM and XS screen sizes -->
                    <button mat-button [matMenuTriggerFor]="blog">
                        <mat-icon>create</mat-icon>
                        Blog
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <button mat-button [matMenuTriggerFor]="albums">
                        <mat-icon>music_note</mat-icon>
                        Skivor
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <button mat-button [matMenuTriggerFor]="movies">
                        <mat-icon>movie</mat-icon>
                        Filmer
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <button mat-button [matMenuTriggerFor]="books">
                        <mat-icon>menu_book</mat-icon>
                        Böcker
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <button mat-button [routerLink]='["/gallery"]'>
                        <mat-icon>camera_alt</mat-icon>
                        Foton
                    </button>
                    <button mat-button [matMenuTriggerFor]="calendar">
                        <mat-icon>calendar_today</mat-icon>
                        Kalendar
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <button mat-button [routerLink]="['/recipes']" (click)="sidenav.close()">
                        <mat-icon>local_dining</mat-icon>
                        Recept
                    </button>
                    <button mat-button [matMenuTriggerFor]="admin" *ngIf="belongTo('admin')">
                        <mat-icon>settings</mat-icon>
                        Admin
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <button mat-button [matMenuTriggerFor]="links" *ngIf="onLocalNetwork()">
                        <mat-icon>link</mat-icon>
                        Länkar
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <button mat-button *ngIf="!logged_in" (click)="login()">
                        <mat-icon>login</mat-icon>
                        Login
                    </button>
                    <button mat-button *ngIf="logged_in" (click)="logoutUser()">
                        <mat-icon>power_settings_new</mat-icon>
                        Logout
                    </button>
                </div>
                <span class="spacer"></span>
                <div><app-current-temperature></app-current-temperature></div>

            <div class="top-menu">
                <div>
                    <mat-menu #animals="matMenu">
                    <button mat-menu-item [matMenuTriggerFor]="blog">Blog</button>
                    <button mat-menu-item [matMenuTriggerFor]="albums">Skivregister</button>
                    <button mat-menu-item [matMenuTriggerFor]="movies">Filmregister</button>
                    <button mat-menu-item [matMenuTriggerFor]="books">Bokregister</button>
                    <button mat-menu-item>Fotoalbum</button>
                    <button mat-menu-item [matMenuTriggerFor]="calendar">Kalender</button>
                    <button mat-menu-item>Recept</button>
                    <button mat-menu-item [matMenuTriggerFor]="software">Mjukvara</button>
                    </mat-menu>

                    <mat-menu #blog="matMenu">
                        <button mat-menu-item [routerLink]="['/blog']" (click)="sidenav.close()">Bläddra</button>
                        <button mat-menu-item [routerLink]="['/news']" (click)="sidenav.close()">Nyheter</button>
                    </mat-menu>


                    <mat-menu #albums="matMenu">
                        <button mat-menu-item [routerLink]="['/albums']" (click)="sidenav.close()">Bläddra</button>
                        <button mat-menu-item [routerLink]="['/albums/news']" (click)="sidenav.close()">Nyheter</button>
                        <button mat-menu-item disabled (click)="sidenav.close()">PDF-lista</button>
                        <button mat-menu-item disabled (click)="sidenav.close()">Toppbetyg</button>
                        <button mat-menu-item disabled (click)="sidenav.close()">Skämsskivor</button>
                        <button mat-menu-item [routerLink]="['/music']" [disabled]="!logged_in" (click)="sidenav.close()">Skivspelare</button>
                        <button mat-menu-item [routerLink]="['/album-add']" *ngIf="belongTo('admin')" (click)="sidenav.close()">Lägg till</button>
                    </mat-menu>

                    <mat-menu #movies="matMenu">
                        <button mat-menu-item [routerLink]="['/movies']" (click)="sidenav.close()">Bläddra</button>
                        <button mat-menu-item [routerLink]="['/movies/news']" (click)="sidenav.close()">Nyheter</button>
                        <button mat-menu-item disabled (click)="sidenav.close()">PDF-lista</button>
                        <button mat-menu-item disabled (click)="sidenav.close()">Bästa filmerna</button>
                        <button mat-menu-item disabled (click)="sidenav.close()">Sämsta filmerna</button>
                        <button mat-menu-item disabled (click)="sidenav.close()">Utlånade filmer</button>
                        <button mat-menu-item [routerLink]="['/movie-add']" *ngIf="belongTo('admin')" (click)="sidenav.close()">Lägg till</button>
                    </mat-menu>

                    <mat-menu #books="matMenu">
                        <button mat-menu-item [routerLink]="['/books']" (click)="sidenav.close()">Bläddra</button>
                        <button mat-menu-item [routerLink]="['/books/news']" (click)="sidenav.close()">Nyheter</button>
                        <button mat-menu-item disabled (click)="sidenav.close()">PDF-lista</button>
                        <button mat-menu-item [routerLink]="['/book-edit']" *ngIf="belongTo('admin')" (click)="sidenav.close()">Lägg till</button>
                    </mat-menu>

                    <mat-menu #calendar="matMenu">
                        <button mat-menu-item [routerLink]="['/calendarCreator']" (click)="sidenav.close()">Gör en kalender</button>
                        <button mat-menu-item [routerLink]="['/sunrise']" (click)="sidenav.close()">Soltider</button>
                        <button mat-menu-item [routerLink]="['/year-calendar']" (click)="sidenav.close()">Årskalender</button>
                    </mat-menu>

                    <mat-menu #admin="matMenu">
                        <button mat-menu-item [routerLink]="['/clocks']" (click)="sidenav.close()">Läggtider</button>
                        <button mat-menu-item (click)="sidenav.close()">Användare</button>
                        <button mat-menu-item (click)="sidenav.close()">Grupper</button>
                    </mat-menu>

                    <mat-menu #links="matMenu">
                        <a href="http://homeassistant.lan:8123" taget="_blank">
                            <button mat-menu-item (click)="sidenav.close()">Home Assistant</button>
                        </a>
                        <a href="http://kilroy.lan:8989" taget="_blank">
                            <button mat-menu-item (click)="sidenav.close()">Sonarr</button>
                        </a>
                        <a href="http://kilroy.lan:7878" taget="_blank">
                            <button mat-menu-item (click)="sidenav.close()">Radarr</button>
                        </a>
                        <a href="http://kilroy.lan:9696" taget="_blank">
                            <button mat-menu-item (click)="sidenav.close()">Prowlarr</button>
                        </a>
                        <a href="http://kilroy.lan:9092" taget="_blank">
                            <button mat-menu-item (click)="sidenav.close()">Transmission</button>
                        </a>
                        <a href="https://kilroy.lan:8443" taget="_blank">
                            <button mat-menu-item (click)="sidenav.close()">Unifi</button>
                        </a>
                        <a href="http://kilroy.lan:3000" taget="_blank">
                            <button mat-menu-item (click)="sidenav.close()">Grafana</button>
                        </a>
                        <a href="http://pcp.lan:9000" taget="_blank">
                            <button mat-menu-item (click)="sidenav.close()">LMS</button>
                        </a>
                    </mat-menu>

                    <mat-menu #software="matMenu">
                        <button mat-menu-item disabled (click)="sidenav.close()">Spel</button>
                        <button mat-menu-item disabled (click)="sidenav.close()">Arduino</button>
                        <button mat-menu-item disabled (click)="sidenav.close()">Raspberry Pi</button>
                        <button mat-menu-item disabled (click)="sidenav.close()">Web</button>
                    </mat-menu>
                </div>
            </div>
            </mat-toolbar-row>
        </mat-toolbar>
        <mat-sidenav-container fxFlexFill class="main-container">
            <mat-sidenav #sidenav>
                <mat-nav-list>
                    <button mat-button [matMenuTriggerFor]="blog">
                        <mat-icon>create</mat-icon>
                        Blog
                        <mat-icon>arrow_right</mat-icon>
                    </button>
                    <br>
                    <button mat-button [matMenuTriggerFor]="albums">
                        <mat-icon>music_note</mat-icon>
                        Skivor
                        <mat-icon>arrow_right</mat-icon>
                    </button>
                    <br>
                    <button mat-button [matMenuTriggerFor]="movies">
                        <mat-icon>movie</mat-icon>
                        Filmer
                        <mat-icon>arrow_right</mat-icon>
                    </button>
                    <br>
                    <button mat-button [matMenuTriggerFor]="books">
                        <mat-icon>menu_book</mat-icon>
                        Böcker
                        <mat-icon>arrow_right</mat-icon>
                    </button>
                    <br>
                    <button mat-button [routerLink]='["/gallery"]'>
                        <mat-icon>camera_alt</mat-icon>
                        Foton
                    </button>
                    <br>
                    <button mat-button [matMenuTriggerFor]="calendar">
                        <mat-icon>calendar_today</mat-icon>
                        Kalendar
                        <mat-icon>arrow_right</mat-icon>
                    </button>
                    <br>
                    <button mat-button [routerLink]="['/recipes']" (click)="sidenav.close()">
                        <mat-icon>local_dining</mat-icon>
                        Recept
                    </button>
                    <br>
                    <button mat-button [matMenuTriggerFor]="admin" *ngIf="belongTo('admin')">
                        <mat-icon>settings</mat-icon>
                        Admin
                        <mat-icon>arrow_right</mat-icon>
                    </button>
                    <br>
                    <button mat-button [matMenuTriggerFor]="links" *ngIf="onLocalNetwork()">
                        <mat-icon>link</mat-icon>
                        Länkar
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <br>
                    <button mat-button *ngIf="!logged_in" (click)="login()">
                        <mat-icon>login</mat-icon>
                        Login
                    </button>
                    <button mat-button *ngIf="logged_in" (click)="logoutUser()">
                        <mat-icon>power_settings_new</mat-icon>
                        Logout
                    </button>
                    <br>
                    <a (click)="sidenav.close()" mat-button>
                        <mat-icon>close</mat-icon> Close
                    </a>
                </mat-nav-list>
            </mat-sidenav>
            <mat-sidenav-content fxFlexFill>
                <div class="container" id="container" (scroll)="onScroll($event)">
                    <router-outlet></router-outlet>
                </div>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
    <div *ngIf="logged_in" class="media-footer">
        <app-player></app-player>
    </div>
</div>
