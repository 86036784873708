<mat-card appearance="outlined" class="clock-config" *ngFor="let clock of clock_configs; let i = index">
    <mat-card-title>{{ clock.name }}</mat-card-title>
    <mat-card-content>
        <mat-form-field>
            <mat-label>Monday</mat-label>
            <input matInput class="bedtime" type="text" [(ngModel)]="clock.bedtimes[0].mon" size="4">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Tuesday</mat-label>
            <input matInput class="bedtime" type="text" [(ngModel)]="clock.bedtimes[0].tue" size="4">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Wednesday</mat-label>
            <input matInput class="bedtime" type="text" [(ngModel)]="clock.bedtimes[0].wed" size="4">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Thursday</mat-label>
            <input matInput class="bedtime" type="text" [(ngModel)]="clock.bedtimes[0].thu" size="4">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Friday</mat-label>
            <input matInput class="bedtime" type="text" [(ngModel)]="clock.bedtimes[0].fri" size="4">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Saturday</mat-label>
            <input matInput class="bedtime" type="text" [(ngModel)]="clock.bedtimes[0].sat" size="4">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Sunday</mat-label>
            <input matInput class="bedtime" type="text" [(ngModel)]="clock.bedtimes[0].sun" size="4">
        </mat-form-field>
    </mat-card-content>
    <button mat-raised-button color="primary" class="save" (click)="saveBedtimes(clock)">Spara</button>
</mat-card>
