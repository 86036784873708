import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Title } from '@angular/platform-browser';

export interface Author {
    author_id: number;
    name: string;
    sort_name: string;
}

export interface Genre {
    id: number;
    genre: string;
}

export interface Binding {
    binding_id: number;
    binding: string;
}

export interface Book {
    id: number;
    title: string;
    bought: string;
    year: string;
    binding: Binding;
    cover: string;
    cover_url: string;
    authors: Author[];
    genres: Genre[];
    owner: string;
    language: string;
    next: number;
    previous: number;
}

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})

export class EditComponent implements OnInit {
    currentId: number;
    book: Book = <Book>{};

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        private route: ActivatedRoute,
        private titleService: Title
    ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.currentId = parseInt(params.get('id'), 10);
        });

        this.book = {
            id: null,
            title: "This is the greatest book ever",
            bought: "2021-05-27",
            year: "1991",
            binding: {
                binding_id: 1,
                binding: "Paperback"
            },
            cover: "E",
            cover_url: "F",
            authors: [{
                author_id: null,
                name: "Tõnis Tõnisson",
                sort_name: "Tõnisson, Tõnis"
            }],
            genres: [{
                id: 1,
                genre: "one genre"
            }],
            owner: "Jimmt",
            language: "Esperanto",
            next: null,
            previous: null
        };
    }

    addAuthor()
    {
        this.book.authors.push({author_id: null, name: "L", sort_name: "M"});
    }

    log()
    {
        console.log(this.book);
    }
}
