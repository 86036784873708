<mat-card appearance="outlined" class="edit-book">
    <mat-card-content class="album-container">
        <div class="book-all">
            <ng-container *ngFor="let author of book?.authors; let i = index">
                <mat-form-field class="wide">
                    <mat-label>Författare</mat-label>
                    <input matInput type='text' [(ngModel)]="author.name">
                </mat-form-field>
                <br>
                <mat-form-field class="wide">
                    <mat-label>Sortera efter</mat-label>
                    <input matInput type='text' name='discArtist' [(ngModel)]="author.sort_name">
                </mat-form-field>
            </ng-container>
            <div (click)="addAuthor()">+</div>
            <br>
            <mat-form-field class="wide">
                <mat-label>Boktitle</mat-label>
                <input matInput type='text' [(ngModel)]="book.title">
            </mat-form-field>
            <br>
            <mat-form-field>
                <mat-label>Genre</mat-label>
                <mat-select [(value)]='book.genres' multiple (change)="log()">
                    <mat-option *ngFor="let genre of book?.genres; let i = index" [value]='genre.id'>{{ genre.genre }}</mat-option>
                </mat-select>
            </mat-form-field>
            <br>
            <mat-form-field>
                <mat-label>År</mat-label>
                <input matInput type='text' [(ngModel)]="book.year">
            </mat-form-field>
            <br>
            <mat-form-field>
                <mat-label>Språk</mat-label>
                <input matInput type='text' [(ngModel)]="book.language">
            </mat-form-field>
            <br>
            <mat-form-field>
                <mat-label>Inbindning</mat-label>
                <input matInput type='text' [(ngModel)]="book.binding.binding">
            </mat-form-field>
            <br>
            <mat-form-field>
                <mat-label>Ägare</mat-label>
                <input matInput type='text' [(ngModel)]="book.owner">
            </mat-form-field>
        </div>
    </mat-card-content>
</mat-card>
