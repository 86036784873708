import { Component, Inject } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AuthService } from './services/auth.service';
import { ScrollDetectionService } from './services/scroll-detection.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserLoginComponent } from './user-login/user-login.component';
import { faRegistered } from '@fortawesome/free-regular-svg-icons';

export interface DialogData {
    user: string,
    password: string
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'my-app';
    public selectedIndex: number = 2;
    events: string[] = [];
    opened: boolean;
    user: string;
    password: string;
    logged_in: boolean;
    public faRegistered = faRegistered;

    public constructor(
        public loadingBarService: LoadingBarService,
        private authService: AuthService,
        private dialog: MatDialog,
        private scrollService: ScrollDetectionService
    ) {
        this.authService.user_authenticated.subscribe(x => this.logged_in = x);
    };

    logoutUser()
    {
        this.authService.logoutUser();
    }

    login()
    {
        this.dialog.open(UserLoginComponent, { data: {
            message: "Testing2"
        }})
    }

    onScroll(event)
    {
        this.scrollService.scrollDetected(event);
    }

    belongTo(group: string)
    {
        return this.authService.belongTo(group);
    }

    onLocalNetwork()
    {
        return this.authService.onLocalNetwork();
    }
}
