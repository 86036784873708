<div class="content">
    <span class="title">Now playing</span>
    <div class="now-playing">
        <div class="now-playing-cover">
            <picture>
                <source media='' [srcset]="activeTrack?.cover" />
                <img mat-card-image src="" alt="" />
            </picture>
        </div>
        <div class="info" *ngIf="activeTrack">
            <span class="artist" *ngFor="let artist of activeTrack.artist"><a [routerLink]="['/artist', artist.artist_id]" class="artist">{{ artist.artist_name }}</a> {{ artist.join_phrase }}</span><br>
            <span class="album">{{ activeTrack?.album }} ({{ activeTrack?.year }})</span><br>
            <span class="album" *ngIf="activeTrack.artist != activeTrack.track_artist">{{ activeTrack?.track_artist.artist_name }}</span><br>
            <span class="track-title">{{ activeTrack?.track_no }}. {{ activeTrack?.title }}</span>
        </div>
    </div>
    <div color="primary" class="playlist">
        <h3 mat-subheader>Songs</h3>
        <div class="playlist-table">
            <div *ngFor="let track of tracks; let i = index" (click)="playTrack(i)" (mouseover)="mouseOverItem(i)" (mouseout)="mouseOutItem(i)" [ngClass]="{'active-track' : getIndexOfActiveTrack() === i}" class="playlist-item">
                <div class="playlist-cell">
                    <mat-icon color="primary" mat-list-icon class="play">{{ icon[i] }}</mat-icon>
                </div>
                <div class="playlist-track-artist playlist-cell">
                    <h4 mat-line class="track-title">{{ track.title }}</h4>
                    <h5 mat-line *ngFor="let artist of track.track_artist"><a [routerLink]="['/artist', artist.artist_id]" class="artist">{{ artist.artist_name }}</a> {{ artist.join_phrase }}</h5>
                </div>
                <div class="playlist-cell">
                    <mat-icon color="primary" mat-list-icon (click)="removeTrack(i)">delete</mat-icon>
                    <mat-icon color="primary" *ngIf="getIndexOfActiveTrack() === i">volume_up</mat-icon>
                    <mat-icon color="primary" *ngIf="getIndexOfActiveTrack() !== i" class="invisible">volume_up</mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>
