<mat-card appearance="outlined" class="widget">
    <mat-card-title class="card-title">
        <div>Idag {{ currentDate.getDate() }}/{{ currentDate.getMonth() + 1 }}</div>
        <div>
            <span (click)="getEntries(getPreviousDate())" class="navigation">&lt;</span>
            &nbsp;
            <span (click)="getEntries(getNextDate())" class="navigation">&gt;</span>
        </div>
    </mat-card-title>
    <mat-card-content>
        <div class="day-history-container">
            <div *ngFor="let year of years; let i = index" class="blog-history-year">
                <div *ngFor="let entry of year.blogEntries; let j = index" [routerLink]="['/blog', entry.id]" class="blog-history-link">
                    <h3 *ngIf="year.year > 0">{{ year.year }} år sedan</h3>
                    <h3 *ngIf="year.year == 0">Idag</h3>
                    <div class="blog-title">
                        {{ entry.title }}
                    </div>
                    <img *ngIf="entry.media.length > 0 && entry.media[0].type == 'image'" src="{{ entry.media[0].src }}" class="blog-history" >
                    <video *ngIf="entry.media.length > 0 && entry.media[0].type == 'video'" class="blog-history"><source src="{{ entry.media[0].src }}" type="video/mp4"></video>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
