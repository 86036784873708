import { NgModule } from '@angular/core';
import { BrowseComponent } from './browse/browse.component';
import { BookComponent } from './book/book.component';
import { CommonModule } from '@angular/common';
import { EditComponent } from './edit/edit.component';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from "../material.module";
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { NewsComponent } from './news/news.component';
import { AuthorComponent } from './author/author.component';



@NgModule({
    declarations: [BrowseComponent, BookComponent, EditComponent, NewsComponent, AuthorComponent],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        MatSelectModule,
        RouterModule
    ]
})
export class BooksModule { }
