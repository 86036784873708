import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClockConfigComponent } from './clock-config/clock-config.component';
import { MaterialModule } from '../material.module';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';



@NgModule({
    declarations: [ClockConfigComponent],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        MaterialModule,
    ]
})
export class AutomationModule { }
