
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from "../material.module";
import { RecipeComponent } from './recipe/recipe.component';
import { RecipesComponent } from './recipes/recipes.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [RecipeComponent, RecipesComponent],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
  ]
})
export class RecipesModule { }
