import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-edit-entry',
    templateUrl: './edit-entry.component.html',
    styleUrls: ['./edit-entry.component.scss'],
})
export class EditEntryComponent implements OnInit {

    public blogEntry: any;
    public rawBlogEntry: any;
    private tmpBlogEntry: any;
    public id: string;
    public dummy_value: string = "";

    constructor(
        private httpClient: HttpClient,
        private location: Location,
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private authService: AuthService,
    ) {
        this.authService.user_authenticated.subscribe((x)=>{
            console.log("auth service")
        });
   }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.id = params.get("id");
            this.getEntry(this.id);
        });
    }

    public onReady( editor ) {
        editor.plugins.get("SpecialCharacters").addItems('Emoji', [
            { title: 'smiley face', character: '😊'},
            { title: 'rocket', character: '🚀' },
            { title: 'wind blowing face', character: '🌬️' },
            { title: 'floppy disk', character: '💾' },
            { title: 'heart', character: '❤️' }
        ]);
        editor.model.schema.extend( '$text', { allowedAttributes: 'src-full' } );
    }

    getEntry(id) {
        this.httpClient.get("https://ng.renner.se/blog.api.php?id=" + id).subscribe((data)=>{
            this.blogEntry = [];

            let entry = data[0];

            const blogEntry1 = {
                id: entry.id,
                author: entry.author,
                user_id: entry.user_id,
                date: entry.date,
                title: entry.title,
                entry: entry.entry,
                public: entry.public === '1',
                active_image: 0,
                previous: entry.previous,
                next: entry.next,
            }

            this.titleService.setTitle(entry.title + " - renner.se");

            this.blogEntry = blogEntry1;
            this.location.replaceState("/blog-edit/" + id);
        });
    }

    onChange(event)
    {
        console.log(event);
    }

    onSave()
    {
        this.httpClient.post("https://ng.renner.se/blog.api.php?update-entry", this.blogEntry).subscribe(
            (response) => {
                this.router.navigate(['/blog', this.blogEntry.id])
            },
            (error) => console.log(error)
        );
    }
}
