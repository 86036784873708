<mat-card appearance="outlined" class="widget">
    <mat-card-title class="card-title">
        <div>
            Temperatur
        </div>
        <div class="temperature-time">
            {{ current_date?.getHours() }}:{{ ('0'+current_date?.getMinutes()).slice(-2) }}
        </div>
    </mat-card-title>
    <mat-card-content>
        <div class="bar-chart">
            <div class="graph-content">
                <ng-container *ngFor="let temperature of temperature_history">
                    <div *ngIf="temperature.temperature >= 0"
                        [routerLink]="['/temperature', temperature.timestamp]"
                        class="bar" [style.height.px]='tempHeight(temperature.temperature)'
                        matTooltip="{{temperature.year}}: {{temperature.temperature}}°C"
                        matTooltipClass="tooltipClass">
                    </div>
                    <div *ngIf="temperature.temperature < 0" class="bar"></div>
                </ng-container>
            </div>
            <div class="line"></div>
            <div class="graph-neg-content">
                <ng-container *ngFor="let temperature of temperature_history">
                    <div *ngIf="temperature.temperature < 0.0"
                    [routerLink]="['/temperature', temperature.timestamp]"
                    class="neg-bar" [style.height.px]='tempHeight(temperature.temperature)'
                    matTooltip="{{temperature.year}}: {{temperature.temperature}}°C"
                    matTooltipClass="tooltipClass">
                </div>
                    <div *ngIf="temperature.temperature >= 0" class="neg-bar"></div>
                </ng-container>
            </div>
            <div class="legends">
                <span *ngFor="let temperature of temperature_history">
                    {{ temperature.year }}
                </span>
            </div>
        </div>
    </mat-card-content>
</mat-card>
