<div class="artist-wrapper">
    <div class="artist">{{ artist_albums?.artist.artist_name }}</div>
</div>
<div class="album-wrapper">
    <div *ngFor="let album of artist_albums?.albums; let i = index">
        <br>
        <div class="album"><a [routerLink]="['/album', album.id]">{{ album?.title }} ({{ album?.year}})</a></div>
        <div><a [routerLink]="['/album', album.id]"><img src="{{ album?.cover }}" alt="" class="album-cover" /></a></div>
        <br>
    </div>
</div>