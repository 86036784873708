<div class="author-wrapper">
    <div class="author">{{ author_books?.author.name }}</div>
</div>
<div class="book-wrapper">
    <div *ngFor="let book of sortBooksByYear(); let i = index">
        <br>
        <div class="book"><a [routerLink]="['/book', book.book_id]">{{ book?.title }} ({{ book?.year}})</a></div>
        <div><a [routerLink]="['/book', book.id]"><img src="{{ book?.cover_url }}" alt="" class="book-cover" /></a></div>
        <br>
    </div>
</div>