import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Platform } from '@angular/cdk/platform';
import { NavigationEnd, Router } from '@angular/router';
import { ScrollDetectionService } from '../../services/scroll-detection.service';
import { Title } from '@angular/platform-browser';
import { ViewportScroller } from "@angular/common";

@Component({
    selector: 'app-blog-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NewsComponent implements OnInit {
    blogEntries: any = [];
    fetchingEntries: boolean = false;
    swipeCoord: [number, number]
    swipeTime: any
    scrollPos: number = 0;
    scrolling: boolean = false;

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
        private scrollService: ScrollDetectionService,
        public platform: Platform,
        private router: Router,
        private titleService: Title,
        private scroller: ViewportScroller,
    )
    {
        this.authService.user_authenticated.subscribe((x)=>{
            this.getEntries();
        });
    }

    ngOnInit() {
        this.titleService.setTitle("Bloggen - renner.se");
        this.scrollService.getScrollEvent().subscribe(event => {
            let scrollRemaining = event?.target.scrollHeight - event?.target.clientHeight - event?.target.scrollTop;
            if (this.router.url == '/news')
            {
                if (!this.scrolling)
                {
                    this.scrollPos = event?.target.scrollTop;
                }
                else
                {
                    if (this.scrollPos == event.target.scrollTop)
                    {
                        this.scrolling = false;
                    }
                    else
                    {
                        var element = document.getElementById('container');
                        if (element)
                        {
                            element.scrollTop = this.scrollPos;
                        }
                    }
                }
            }

            if (scrollRemaining < 1000 && this.router.url == '/news')
            {
                this.getEntries();
            }
        });
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd && event.url == '/news')
            {
                this.scrolling = true;
                // Restore scroll pos
                var element = document.getElementById('container');
                if (element)
                {
                    element.scrollTop = this.scrollPos;
                }
            }
        });
    }

    getEntries() {
        if (!this.fetchingEntries)
        {
            this.fetchingEntries = true;
            this.httpClient.get("https://ng.renner.se/blog.api.php?limit=20&offset=" + this.blogEntries.length).subscribe((data)=>{
                let tmp_data: any = data;
                for(let entry of tmp_data)
                {
                    let parser = new DOMParser();
                    let parsedHtml = parser.parseFromString(entry.entry, 'text/html');
                    var elements = parsedHtml.getElementsByClassName("lightbox");
    
                    for(let j = 0; j < elements.length; j++)
                    {
                        let src_full = elements[j].getAttribute("href");
                        elements[j].removeAttribute("href");
                        let children = elements[j].children;
    
                        for(let k = 0; k < children.length; k++)
                        {
                            if (children[k].getAttribute("src-full") == null)
                            {
                                children[k].setAttribute("src-full", src_full);
                            }
                        }
                    }
    
                    tmp_data = parsedHtml.body.innerHTML;
                    parsedHtml = parser.parseFromString(tmp_data, 'text/html');
    
                    var photo_links = parsedHtml.getElementsByTagName("a");
                    for(let i = 0; i < photo_links.length; i++)
                    {
                        // Try to convert old links to the photo gallery
                        let href = decodeURIComponent(photo_links[i].getAttribute('href'));
                        if (href && href.startsWith("/foton/show_dir.php"))
                        {
                            href = href.replace("/foton/show_dir.php?dir=pics/", "");
                            console.log("href: " + href);
                            photo_links[i].setAttribute('routerlink', "/gallery/" + href);
                            photo_links[i].removeAttribute("href");
                        }
                    }
    
                    let videos = parsedHtml.getElementsByTagName('video');
                    for(let i = 0; i < videos.length; i++)
                    {
                        let src = videos[i].children[0].getAttribute("src");
    
                        if (src.startsWith('/'))
                        {
                            src = 'https://renner.se' + src;
                        }
                        videos[i].children[0].setAttribute("src", src);
                        console.log(videos[i].children[0]);
                    }
    
                    let blogImages = [];
                    let images = parsedHtml.getElementsByTagName("img");
                    for (let i = 0; i < images.length; i++)
                    {
                        console.log(images[i]);
                        let src = images[i].src;
                        let src_full = images[i].getAttribute("src-full");

                        let src_url = new URL(src);
                        if (src_url.host != 'renner.se')
                        {
                            src = 'https://renner.se' + src_url.pathname;
                        }
    
                        if (src_full != null)
                        {
                            if (src_full.startsWith('/'))
                            {
                                src_full = src_full.replace('/', 'https://renner.se/');
                            }
                            else
                            {
                                let src_full_url = new URL(src_full);
                                if (src_full_url.host != 'renner.se')
                                {
                                    src_full = 'https://renner.se' + src_full_url.pathname;
                                }
                            }
                        }
                        console.log("src_full: " + src_full);
                        const image = {
                            src: src,
                            src_full: src_full,
                            title: images[i].getAttribute("title"),
                            type: 'image',
                        }
                        blogImages.push(image);
                    }
    
                    const blogEntry = {
                        id: entry.id,
                        author: entry.author,
                        date: entry.date,
                        comments: entry.comments,
                        title: entry.title,
                        images: blogImages,
                        active_image: 0,
                        text: parsedHtml.all[0].textContent,
                        previous: entry.previous,
                        next: entry.next,
                        raw_entry: parsedHtml.body.innerHTML.replace(/src="\/pics/g, "src=\"https://renner.se/pics"),
                    }
        
                    console.log("Raw");
                    console.log(blogEntry.raw_entry);

                    this.blogEntries.push(blogEntry);
                }
                this.fetchingEntries = false;
            });
        }
    }

    previousImage(entry) {
        if (entry.active_image == 0)
        {
            entry.active_image = entry.images.length;
        }
        entry.active_image--;
    }

    nextImage(entry) {
        entry.active_image++;
        if (entry.active_image >= entry.images.length)
        {
            entry.active_image = 0;
        }
    }

    onSwipe(event: TouchEvent, action: String, entry) {
        const coord: [number, number] = [event.changedTouches[0].clientX, event.changedTouches[0].clientY];
        const time = new Date().getTime();

        if (action == 'start')
        {
            this.swipeCoord = coord;
            this.swipeTime = time;
        }
        else if (action == 'end')
        {
            const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
            const duration = time - this.swipeTime;

            if (duration < 1000 && Math.abs(direction[0]) > 30 && Math.abs(direction[0]) > Math.abs(direction[1] * 3))
            {
                const swipe = direction[0] < 0 ? 'next' : 'previous';

                if (swipe == 'next')
                {
                    this.nextImage(entry);
                }
                else if (swipe == 'previous')
                {
                    this.previousImage(entry);
                }
            }
        }
    }

    scroll() {
        console.log("Scrolling :-P");
        let pos = this.scroller.getScrollPosition();
        console.log(pos);
        this.scroller.scrollToPosition([pos[0], pos[1] + 600]);

    }

    logImg(images)
    {
        console.log(images);
    }
}
