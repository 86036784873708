<mat-card appearance="outlined" class="widget">
    <mat-card-title class="card-title">Namnsdagar</mat-card-title>
    <mat-card-content>
        <div class="namesdays">
            <div class="namelist">
                <div *ngFor="let name of names?.sa" class="name">
                    {{ name }}
                </div>
            </div>
            <div class="namelist">
                <div *ngFor="let name of names?.bolibompa" class="name">
                    {{ name }}
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
    