<div class="album-wrapper">
    <div *ngFor="let album of albums; let i = index" class="album-inner-wrapper">
        <br>
        <div>{{ album?.owner.name }}</div>
        <span class="artists"><ng-container *ngFor="let artist of album.artists"><a [routerLink]="['/artist', artist.artist_id]" class="artist">{{ artist.artist_name }}</a>{{ artist.join_phrase }}</ng-container></span>
        <span class="album"><a [routerLink]="['/album', album.id]">{{ album?.title }}</a></span>
        <div>{{ album?.bought }}</div>
        <div><a [routerLink]="['/album', album.id]"><img src="{{ album?.cover }}" alt="" class="record-cover" /></a></div>
        <br>
    </div>
</div>
