import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { PlaylistService } from '../../services/playlist.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Title } from '@angular/platform-browser';
import { AudioService } from '../../services/audio.service';


@Component({
  selector: 'app-album',
  templateUrl: './album.component.html',
  styleUrls: ['./album.component.scss']
})

export class AlbumComponent implements OnInit {
    currentId: number;
    nextId: number;
    previousId: number;
    albumData: any;
    displayedColumns: string[] = ['no', 'title', 'artist', 'length'];
    logged_in: boolean;

    constructor(
        private audioService: AudioService,
        private authService: AuthService,
        private httpClient: HttpClient,
        private playlistService: PlaylistService,
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title
    )
    {
        this.authService.user_authenticated.subscribe(x => this.logged_in = x);
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.currentId = parseInt(params.get('id'), 10);
            this.getAlbum(this.currentId);
        });
    }

    getAlbum(id=1) {
        this.httpClient.get("https://ng.renner.se/album.api.php?id=" + id).subscribe((data)=>{
            this.albumData = data;
            this.albumData.length = this.getAlbumLengthAsTime();
            this.setTitle(this.albumData.title + " - " + this.getArtistJoined(this.albumData.artists) + " (" + this.albumData.year + ")");
        });
        this.currentId = id;
    }

    addTrackToPlaylist(i, j) {
        let track_artist: any;
        if (this.albumData.records[i].tracks[j].artist)
        {
            track_artist = this.albumData.records[i].tracks[j].artists;
        }
        else
        {
            track_artist = this.albumData.artists;
        }
        var track = {
            url: this.albumData.records[i].tracks[j].url,
            artist: track_artist,
            track_artist: track_artist,
            album: this.albumData.title,
            year: this.albumData.year,
            cover: this.albumData.cover,
            position: this.albumData.records[i].tracks[j].position,
            track_no: this.albumData.records[i].tracks[j].no,
            track_length: this.albumData.records[i].tracks[j].length,
            title: this.albumData.records[i].tracks[j].title,
            path: this.albumData.records[i].tracks[j].path,
            missing: this.albumData.records[i].tracks[j].missing
        };
        if (!track.missing)
        {
            this.playlistService.addTrack(track);
        }
    }

    getArtistJoined(artists)
    {
        let artist_joined = "";

        if (artists)
        {
            for(let artist of artists)
            {
                artist_joined += artist.artist_name;
                if (artist.join_phrase)
                {
                    artist_joined += " " + artist.join_phrase + " ";
                }
            }
        }

        return artist_joined;
    }

    addAlbumToPlaylist() {
        for (var i = 0; i < this.albumData.records.length; i++)
        {
            for (var j = 0; j < this.albumData.records[i].tracks.length; j++)
            {
                this.addTrackToPlaylist(i, j);
            }
        }
    }


    allTracksMissing()
    {
        let missing = true;

        if (this.albumData)
        {            
            for (var i = 0; i < this.albumData.records.length; i++)
            {
                for (var j = 0; j < this.albumData.records[i].tracks.length; j++)
                {
                    if (!this.albumData.records[i].tracks[j].missing)
                    {
                        missing = false;
                    }
                }
            }
        }
        return missing;
    }


    playAlbum() {
        if (!this.allTracksMissing())
        {
            this.playlistService.removeAllTracks();
            this.addAlbumToPlaylist();
            this.playlistService.setActiveTrack(0);
            let track = this.playlistService.getTrack(0);
            this.audioService.playStream(track.url, true, 0);
            this.router.navigate(['/music']);
        }
    }

    pad(num:number, size:number): string {
        let s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    }

    convertSecondsToTime(seconds, textual = false)
    {
        var date = new Date(null);
        date.setSeconds(seconds);

        var time;

        var hours = date.getUTCHours();
        if (hours != 0)
        {
            if (textual)
            {
                time = hours + " hr " + date.getUTCMinutes() + " min"
            }
            else
            {
                time = hours + ":" + this.pad(date.getUTCMinutes(), 2) + ":" + this.pad(date.getUTCSeconds(), 2);
            }
        }
        else
        {
            if (textual)
            {
                time = date.getUTCMinutes() + " min " + date.getUTCSeconds() + " sec";
            }
            else
            {
                time = date.getUTCMinutes() + ":" + this.pad(date.getUTCSeconds(), 2);
            }
        }

        return time;
    }

    getAlbumLengthAsTime()
    {
        var seconds = 0;

        for (var i = 0; i < this.albumData.records.length; i++)
        {
            for (var j = 0; j < this.albumData.records[i].tracks.length; j++)
            {
                seconds = seconds + parseInt(this.albumData.records[i].tracks[j].length);
            }
        }
        return this.convertSecondsToTime(seconds, true);
    }

    setTitle(newTitle: string)
    {
        this.titleService.setTitle(newTitle);
    }
}
