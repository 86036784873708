<div class="over-all" *ngIf="blogEntry">
    <div class="editor-container">
        <div>
            <mat-form-field class="blog-title">
                <input matNativeControl type="text" name="blog-title" title="Titel" [(ngModel)]="blogEntry.title">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="blog-entry">
                <textarea matNativeControl [(ngModel)]="blogEntry.entry" (ngModelChange)="onChange($event)" title="Text"></textarea>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="blog-date">
                <input matNativeControl type="text" name="blog-date" [(ngModel)]="blogEntry.date" title="Datum">
            </mat-form-field>
        </div>
        <div>
            <mat-checkbox name="blog-public" [(ngModel)]="blogEntry.public">Publikt</mat-checkbox>
        </div>
        <div>
            <button mat-raised-button color="primary" class="blog-save" (click)="onSave()">Spara</button>
            <!-- <button mat-raised-button color="primary" class="blog-preview-btn" (click)="onPreview()">Förhandsgranska</button> -->
        </div>
    </div>
    <div class="blog-edit-container">
        <mat-card appearance="outlined" class="blog">
            <mat-card-subtitle>{{ blogEntry?.author }}</mat-card-subtitle>
            <mat-card-subtitle>{{ blogEntry?.date }}</mat-card-subtitle>
            <mat-card-title class="mat-headline-5">{{ blogEntry?.title }}</mat-card-title>
            <mat-card-content>
                <div [innerHtml]="blogEntry.entry"></div>
            </mat-card-content>
        </mat-card>
    </div>
</div>