import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-month',
  templateUrl: './month.component.html',
  styleUrls: ['./month.component.scss']
})
export class MonthComponent implements OnInit {
    public month: any;
    public currentYear: number = 0;
    public currentMonth: number = 0;
    public currentDay: number = 0;
    
    constructor(
        private httpClient: HttpClient,
    ) { }

    ngOnInit(): void {
        this.getMonth();
    }

    getMonth() {
        this.httpClient.get("https://ng.renner.se/month_calendar.api.php").subscribe((data)=>{
            console.log(data);
            this.month = data;
            this.getCurrentDate();
        });
    }

    getOtherMonth(year, month) {
        this.httpClient.get("https://ng.renner.se/month_calendar.api.php?year=" + year + "&month=" + month).subscribe((data)=>{
            console.log(data);
            this.month = data;
            this.getCurrentDate();
        });
    }

    getCurrentDate()
    {
            this.currentYear = new Date().getFullYear();
            this.currentMonth = new Date().getMonth() + 1;
            this.currentDay = new Date().getDate();

    }

    getWeekday

    isToday(day)
    {
        return (this.month.year == this.currentYear && this.month.month == this.currentMonth && day == this.currentDay);
    }
}
