import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit {

    constructor(
        private titleService: Title,
    ) { }

    ngOnInit(): void {
        this.titleService.setTitle("Nya renner.se");
    }

}
