import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Title } from '@angular/platform-browser';


@Component({
    selector: 'app-movie',
    templateUrl: './artist.component.html',
    styleUrls: ['./artist.component.scss']
})
export class ArtistComponent implements OnInit {
    artist_albums: any;
    currentId: number;

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        private route: ActivatedRoute,
        private titleService: Title
    ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.currentId = parseInt(params.get('id'), 10);
            console.log("Getting albums");
            this.getArtistAlbums(this.currentId);
        });
    }

    getArtistAlbums(id=1) {
        this.httpClient.get("https://ng.renner.se/album.api.php?artist-albums&id=" + id).subscribe((data)=>{
            this.artist_albums = data;
            this.artist_albums.albums = this.sortAlbumsByYear(this.artist_albums.albums);
            console.log(this.artist_albums);
            this.setTitle(this.artist_albums.artist.artist_name);
        });
        this.currentId = id;
    }

    setTitle(newTitle: string)
    {
        this.titleService.setTitle(newTitle);
    }

    sortAlbumsByYear(unsorted_albums)
    {
        return unsorted_albums.sort((a, b) => {
            return a.year - b.year;
        });
    }
}
