import { Component, OnInit, Output, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HostListener } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Title } from '@angular/platform-browser';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Platform } from '@angular/cdk/platform';

@Component({
    selector: 'app-gallery',
    templateUrl: './browse.component.html',
    styleUrls: ['./browse.component.scss'],
    animations: [
        trigger('myInsertRemoveTrigger', [
            transition(':enter', [
              style({ opacity: 0 }),
              animate('300ms', style({ opacity: 1 })),
            ]),
            transition(':leave', [
              animate('300ms', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class BrowseComponent implements OnInit {
    folders: Array<any>;
    files: Array<any>;
    currentIndex: number = 0;
    currentSlide = null;
    screenHeight: number;
    screenWidth: number;
    path: string = "pics/";
    private rootTitle = "Foton"
    crumbs: Array<any>;
    private albums: Array<any> = [];
    public showModal: boolean = false;
    swipeCoord: [number, number]
    swipeTime: any

    constructor(
        private route: ActivatedRoute,
        private titleService: Title,
        private httpClient: HttpClient,
        public platform: Platform
    ) {
        this.titleService.setTitle(this.rootTitle); 
        this.getScreenSize();
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            let path = "";
            this.path = params.get('path');

            if(this.path)
            {
                path = this.path;
            }

            let path_parts = path.split("/");
            let link = "";
            let title = "";
            this.crumbs = [];
            this.crumbs.push({ link: link, crumb: 'index'})
            if (path != "")
            {
                for (var crumb of path_parts)
                {
                    if (link.length > 0)
                    {
                        title = title + "-" + crumb;
                        link = link + "/" + crumb;
                    }
                    else
                    {
                        title = crumb;
                        link = crumb;
                    }
                    const tmp = {
                        link: link,
                        crumb: crumb
                    }
                    this.crumbs.push(tmp);
                }
                this.titleService.setTitle(this.rootTitle + " - " + title)
            }
            this.getGallery(path);
        });
    }

    getGallery(path) {
        this.httpClient.get("https://ng.renner.se/gallery.api.php?path=" + path).subscribe((data)=>{
            this.files = data['thumbs'];
            this.folders = data['dirs'];

            this.albums = [];

            for (var value of this.files)
            {
                let src = value.image_url !== undefined ? value.image_url : value.movie_url;
                if (src.startsWith('/'))
                {
                    src = 'https://renner.se' + src;
                }
                value.image_url = src;

                let thumb = value.thumb_url;
                if (thumb.startsWith('/'))
                {
                    thumb = 'https://renner.se' + thumb;
                }
                value.thumb_url = thumb;

                const album = {
                    src: src,
                    caption: value.comment,
                    thumb: thumb,
                    type: value.type,
                }

                this.albums.push(album);
            }
            console.log(this.albums);

        });
    
    }

    onClick(event) {
        console.log(event);
        console.log(event.target);
        if (!(this.platform.ANDROID || this.platform.IOS))
        {
            if (event.target.localName == "div" && event.target.classList.contains("img-slides"))
            {
                this.closeModal();
            }
            if (event.target.localName == "img" && event.target.classList.contains("ng-star-inserted"))
            {
                console.log(event.offsetX);
                console.log(event.srcElement.width/2);
                if (event.offsetX < (event.srcElement.width/2))
                {
                    this.previousImage();
                }
                else
                {
                    this.nextImage();
                }
            }
        }
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth - 8; // 8 is the width of the scrollbar 
        console.log(this.screenHeight, this.screenWidth);
    }

    getImageWidth() {
        let image_width = 350;
        let no_images = Math.round(this.screenWidth / image_width);

        image_width = this.screenWidth / no_images;

        return image_width;
    }

    getImageHeight() {
        let image_width_start = 350;
        let image_height_start = 480;
        let no_images = Math.round(this.screenWidth / image_width_start);

        let image_width = this.screenWidth / no_images;

        let ratio = image_width_start / image_width;

        return image_height_start / ratio;
    }

    openModal(index: number): void {
        this.currentIndex = index;
        this.currentSlide = this.albums[this.currentIndex];
        let player = document.getElementById('player-container');
        if (player)
        {
            player.style.display = "none";
        }
        let top_menu = document.getElementById('main-toolbar');
        if (top_menu)
        {
            top_menu.style.display = "none";
        }
        this.showModal = true;
    }

    closeModal(): void {
        console.log("Closing modal");
        let player = document.getElementById('player-container');
        if (player)
        {
            player.style.display = "flex";
        }
        let top_menu = document.getElementById('main-toolbar');
        if (top_menu)
        {
            top_menu.style.display = "inline";
        }
        this.showModal = false;
    }

    nextImage()
    {
        console.log("nextImage");
        console.log(this.currentIndex);
        this.currentIndex++;
        if (this.currentIndex >= this.albums.length)
        {
            this.currentIndex = 0;
        }
        this.currentSlide = this.albums[this.currentIndex];        
        console.log(this.currentIndex);

        if (this.currentSlide.type == 'video')
        {
            (<HTMLVideoElement>document.getElementById('gallery-video')).load();
        }

        this.preloadNext();
    }

    preloadNext()
    {
        let next = this.currentIndex + 1;
        if (next >= this.albums.length)
        {
            next = 0;
        }

        let img = new Image();
        img.src = this.albums[next].src;
        console.log("Preloaded: " + img.src);
    }

    previousImage()
    {
        console.log("previousImage");
        console.log(this.currentIndex);
        this.currentIndex--;
        if (this.currentIndex < 0)
        {
            this.currentIndex = this.albums.length - 1;
        }
        this.currentSlide = this.albums[this.currentIndex];
        console.log(this.currentIndex);

        if (this.currentSlide.type == 'video')
        {
            (<HTMLVideoElement>document.getElementById('gallery-video')).load();
        }

        this.preloadPrevious();
    }

    preloadPrevious()
    {
        let previous = this.currentIndex - 1;
        if (previous < this.albums.length)
        {
            previous = this.albums.length - 1;
        }

        let img = new Image();
        img.src = this.albums[previous].src;
        console.log("Preloaded: " + img.src);
    }

    onSwipe(event: TouchEvent, action: String) {
        const coord: [number, number] = [event.changedTouches[0].clientX, event.changedTouches[0].clientY];
        const time = new Date().getTime();

        if (action == 'start')
        {
            this.swipeCoord = coord;
            this.swipeTime = time;
        }
        else if (action == 'end')
        {
            const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
            const duration = time - this.swipeTime;

            if (duration < 1000 && Math.abs(direction[0]) > 30 && Math.abs(direction[0]) > Math.abs(direction[1] * 3))
            {
                const swipe = direction[0] < 0 ? 'next' : 'previous';
                console.log(swipe);

                if (swipe == 'next')
                {
                    this.nextImage();
                }
                else if (swipe == 'previous')
                {
                    this.previousImage();
                }
            }
        }


        console.log(event);
    }

}
