import { Component } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-day-history-graph',
  templateUrl: './day-history-graph.component.html',
  styleUrls: ['./day-history-graph.component.scss']
})
export class DayHistoryGraphComponent {
    public temperature_history: any;
    public current_date: Date;

    constructor(
        private httpClient: HttpClient,
    ) {
        this.poll().subscribe(reponse => {
            console.log('Request completed successfully');
          });

    }

    poll(): Observable<string> {
        return new Observable<string>(observer => {

            const subscription = timer(0, 60000).pipe(
                switchMap(() => this.makeRequest())).subscribe(response => {
                    this.temperature_history = response.map(this.parseTemp);
                    console.log(this.temperature_history);
                    this.current_date = new Date((this.temperature_history[0].timestamp + 60) * 1000);
                })
        });
    }

    makeRequest(): Observable<any> {
        return this.httpClient.get("https://ng.renner.se/temperature_day_history.api.php");
    }

    abs(value)
    {
        return Math.abs(value);
    }

    parseTemp(temp)
    {
        return({timestamp: temp.timestamp, year: temp.year, temperature: parseFloat(temp.temperature)});
    }

    heightRatio()
    {
        let min_val = 0.0;
        let max_val = 0.0;

        for (let value of this.temperature_history)
        {
            if (value.temperature > max_val)
            {
                max_val = value.temperature;
            }
            else if (value.temperature < min_val)
            {
                min_val = value.temperature;
            }
        }

        return (160 / (max_val - min_val));
    }

    tempHeight(temperature)
    {
        return Math.abs(temperature * this.heightRatio());
    }
}
