<mat-toolbar color="primary" class="player-container" id="player-container">
  <mat-toolbar-row class="player-progress">
    <div *ngIf="minimized" class="track-title-min">
      {{ activeTrack?.title }}&nbsp;&nbsp;&nbsp;
    </div>
        <div class="time">
            {{ state?.readableCurrentTime }}
        </div>
        <mat-slider class="time-slider" min="0" [max]="state?.duration" step="1" color="warn">
            <input matSliderThumb [ngModel]="state.currentTime" (dragEnd)="onSliderChangeEnd($event)">
        </mat-slider>
        <!--
        <mat-slider class="time-slider" min="0" [max]="state?.duration" step="1" #ngSlider><input matSliderThumb [value]="state?.currentTime" (input)="onSliderChangeEnd({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" #ngSliderThumb="matSliderThumb" /></mat-slider>
        -->
        <div class="time" *ngIf="!minimized">
            {{ state?.readableDuration }}
        </div>
        <button mat-button *ngIf="minimized">
            <mat-icon mat-list-icon *ngIf="!state?.playing" (click)="play()">play_circle_filled</mat-icon>
            <mat-icon mat-list-icon *ngIf="state?.playing" (click)="pause()">pause</mat-icon>
        </button>
        <button mat-button class="minimize" (click)="minimize()">
            <mat-icon mat-list-icon *ngIf="!minimized">keyboard_arrow_down</mat-icon>
            <mat-icon mat-list-icon *ngIf="minimized">keyboard_arrow_up</mat-icon>
        </button>
    </mat-toolbar-row>
    <mat-toolbar-row class="media-action-bar insert-remove-container" *ngIf="!minimized" @myInsertRemoveTrigger>
        <div class="player-info-now">
            <div class="player-info">
                <img class="cover" [src]="activeTrack?.cover" alt="Album cover"/>
            </div>
            <div class="player-info2">
                <span class="title">{{ activeTrack?.title }}</span>
                <span class="artist"><ng-container *ngFor="let artist of activeTrack.track_artist">{{ artist.artist_name }}</ng-container></span>
            </div>
        </div>
        <div class="player-controls">
            <button mat-button [style]="repeatState[activeRepeatState].style" (click)="changeRepeatMode()">
                <mat-icon mat-list-icon class="player-control">{{ repeatState[activeRepeatState].icon }}</mat-icon>
            </button>
            <button mat-button (click)="previous()">
                <mat-icon mat-list-icon class="player-control">skip_previous</mat-icon>
            </button>
            <button mat-button *ngIf="state?.playing" (click)="pause()">
                <mat-icon mat-list-icon class="player-control">pause</mat-icon>
            </button>
            <button mat-button *ngIf="!state?.playing" (click)="play()">
                <mat-icon mat-list-icon class="player-control">play_circle_filled</mat-icon>
            </button>
            <button  mat-button (click)="next()">
                <mat-icon mat-list-icon class="player-control">skip_next</mat-icon>
            </button>
            <button mat-button class="playlist-icon" [routerLink]="['/music']">
                <mat-icon mat-list-icon class="player-control">queue_music</mat-icon>
            </button>
        </div>
        <div class="volume" *ngIf="!(audioService.platform.ANDROID || audioService.platform.IOS)">
            <button mat-button (click)="volumeDown()" class="volume-down-button">
                <mat-icon mat-list-icon class="volume-icon">volume_down</mat-icon>
            </button>
            <mat-slider class="volume-slider" min="0" max="100" step="1" color="warn">
                <input matSliderThumb [(ngModel)]="this.state.volume" (valueChange)="onVolumeSliderChange($event)">
            </mat-slider>
            <!--
            <mat-slider class="volume-slider" min="0" max="100" step="1" #ngSlider><input matSliderThumb [value]="state?.volume" (input)="onVolumeSliderChange({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" #ngSliderThumb="matSliderThumb" /></mat-slider>
            -->
            <button mat-button (click)="volumeUp()" class="volume-up-button">
                <mat-icon mat-list-icon class="volume-icon">volume_up</mat-icon>
            </button>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
