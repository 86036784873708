import { Component, OnInit } from '@angular/core';
import { HostListener } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Platform } from '@angular/cdk/platform';
import { NavigationEnd, Router } from '@angular/router';
import { ScrollDetectionService } from '../../services/scroll-detection.service';
import { Title } from '@angular/platform-browser';
//import { URL } from 'url';

@Component({
  selector: 'app-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BrowseComponent implements OnInit {
    screenHeight: number;
    screenWidth: number;
    blogEntries: any = [];
    swipeCoord: [number, number]
    swipeTime: any
    loadedBlogEntries: number = 0;
    fetchingEntries: boolean = false;
    scrollPos: number = 0;
    scrolling: boolean = false;

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
        private router: Router,
        private titleService: Title,
        private scrollService: ScrollDetectionService,
        public platform: Platform
    )
    {
        this.authService.user_authenticated.subscribe((x)=>{
            this.getEntries();
        });
        this.getScreenSize();
    }

    ngOnInit()
    {
        this.titleService.setTitle("Bloggen - renner.se");
        this.scrollService.getScrollEvent().subscribe(event => {
            let scrollRemaining = event?.target.scrollHeight - event?.target.clientHeight - event?.target.scrollTop;
            if (this.router.url == '/blog')
            {
                if (!this.scrolling)
                {
                    this.scrollPos = event?.target.scrollTop;
                }
                else
                {
                    if (this.scrollPos == event.target.scrollTop)
                    {
                        this.scrolling = false;
                    }
                    else
                    {
                        var element = document.getElementById('container');
                        element.scrollTop = this.scrollPos;
                    }
                }
            }
            if (scrollRemaining < 600 && this.router.url == '/blog')
            {
                this.getEntries();
            }
        });
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd && event.url == '/blog')
            {
                this.scrolling = true;
                // Restore scroll pos
                var element = document.getElementById('container');
                element.scrollTop = this.scrollPos;
            }
        });
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth - 8; // 8 is the width of the scrollbar
    }

    getImageWidth() {
        let image_width = 300;
        let no_images = Math.round(this.screenWidth / image_width);

        image_width = this.screenWidth / no_images;

        return image_width;
    }

    getImageHeight() {
        let image_width_start = 300;
        let image_height_start = 300;
        let no_images = Math.round(this.screenWidth / image_width_start);

        let image_width = this.screenWidth / no_images;

        let ratio = image_width_start / image_width;

        return image_height_start / ratio;
    }

    getEntries() {
        if (!this.fetchingEntries)
        {
            this.fetchingEntries = true;
            this.httpClient.get("https://ng.renner.se/blog.api.php?limit=100&offset=" + this.loadedBlogEntries).subscribe((data)=>{
                let tmp_data: any = data;
                for(let entry of tmp_data)
                {
                    let parser = new DOMParser();
                    let parsedHtml = parser.parseFromString(entry.entry, 'text/html');
                    let images = parsedHtml.images;
                    let links = parsedHtml.links;
                    let blogImages = [];
                    for (let i = 0; i < images.length; i++)
                    {
                        let src = images[i].src;
                        let src_full = images[i].getAttribute("src-full");
                        let src_url = new URL(src);
                        if (src_url.host != 'renner.se')
                        {
                            src = 'https://renner.se' + src_url.pathname;
                        }

                        if (src_full != null && src_full.startsWith('https://ng.renner.se'))
                        {
                            src_full = src_full.replace('https://ng.renner.se', 'https://renner.se');
                        }

                        const image = {
                            id: entry.id,
                            author: entry.author,
                            src: src,
                            src_full: src_full,
                            title: images[i].getAttribute("title"),
                        }
                        blogImages.push(image);
                    }

                    const blogEntry = {
                        author: entry.author,
                        date: entry.date,
                        title: entry.title,
                        images: blogImages,
                        active_image: 0,
                        text: parsedHtml.all[0].textContent,
                    }

                    this.blogEntries.push(blogEntry);

                    for (let i = 0; i < links.length; i++)
                    {
                    }
                }
                this.loadedBlogEntries = this.blogEntries.length;
                this.fetchingEntries = false;
            });
        }
    }

    previousImage(entry) {
        if (entry.active_image == 0)
        {
            entry.active_image = entry.images.length;
        }
        entry.active_image--;
    }

    nextImage(entry) {
        entry.active_image++;
        if (entry.active_image >= entry.images.length)
        {
            entry.active_image = 0;
        }
    }

    onSwipe(event: TouchEvent, action: String, entry) {
        const coord: [number, number] = [event.changedTouches[0].clientX, event.changedTouches[0].clientY];
        const time = new Date().getTime();

        if (action == 'start')
        {
            this.swipeCoord = coord;
            this.swipeTime = time;
        }
        else if (action == 'end')
        {
            const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
            const duration = time - this.swipeTime;

            if (duration < 1000 && Math.abs(direction[0]) > 30 && Math.abs(direction[0]) > Math.abs(direction[1] * 3))
            {
                const swipe = direction[0] < 0 ? 'next' : 'previous';

                if (swipe == 'next')
                {
                    this.nextImage(entry);
                }
                else if (swipe == 'previous')
                {
                    this.previousImage(entry);
                }
            }
        }
    }
}
