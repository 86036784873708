import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular';
import { MaterialModule } from "../material.module";
import { RouterModule } from '@angular/router';
import { TemperatureComponent } from './temperature/temperature.component';
import { CurrentTemperatureComponent } from './current-temperature/current-temperature.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { DayHistoryGraphComponent } from './day-history-graph/day-history-graph.component';


@NgModule({
    declarations: [TemperatureComponent, CurrentTemperatureComponent, DayHistoryGraphComponent],
    imports: [
        FontAwesomeModule,
        HighchartsChartModule,
        CommonModule,
        MaterialModule,
        RouterModule
    ],
    exports: [
        CurrentTemperatureComponent,
        DayHistoryGraphComponent,
    ]
})
export class TemperatureModule { }
