<mat-error>{{ error }}</mat-error>

<form class="my-form" [formGroup]='loginForm' (ngSubmit)="login()">
    <mat-form-field class="cell">
        <mat-label>Användarnamn</mat-label>
        <input formControlName="username" matInput placeholder="Användarnamn" name="username" (keydown.enter)="login()">
    </mat-form-field>
    <mat-form-field class="cell">
        <mat-label>Lösenord</mat-label>
        <input formControlName="password" matInput type="password" placeholder="Lösenord" name="password" (keydown.enter)="login()">
    </mat-form-field>
</form>
<button mat-stroked-button (click)="login()" color="primary" type="submit">LOGIN</button>
