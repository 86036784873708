<div class="blog-container" >
    <ng-container *ngFor="let entry of blogEntries; let i = index">
        <div class="blog-browser-container" *ngFor="let image of entry.images; let j = index">
            <img src="{{ image.src }}" class="blog-insta" [ngStyle]="{'width.px': getImageWidth(), 'height.px': getImageHeight()}" [routerLink]="['/blog/', image.id]" />
            <div class="overlay">
                {{ entry?.title }}<br>{{entry?.date}}
            </div>
        </div>
    </ng-container>
</div>
