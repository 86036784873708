import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Component({
    selector: 'app-sunrise',
    templateUrl: './sunrise.component.html',
    styleUrls: ['./sunrise.component.scss']
})
export class SunriseComponent implements OnInit {
    some_sunrises: any = [];

    constructor(
        private httpClient: HttpClient,
    ) { }

    ngOnInit(): void {
        this.getSunrise();
    }

    getSunrise() {
        this.httpClient.get("https://ng.renner.se/sun_time.api.php").subscribe((data)=>{
            console.log(data);
            this.some_sunrises = data;
        });
    }
}
