import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Location } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-clock-config',
    templateUrl: './clock-config.component.html',
    styleUrls: ['./clock-config.component.scss']
})
export class ClockConfigComponent implements OnInit {
    clock_configs: any;
    focusedField: any = null;

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
        private location: Location,
        private route: ActivatedRoute,
        private router: Router,
        public platform: Platform,
        public titleService: Title,
    ) { }

    ngOnInit(): void {
        this.getClockConfigs();
    }

    getClockConfigs() {
        this.httpClient.get("https://ng.renner.se/clocks.api.php").subscribe((data)=>{
            this.clock_configs = data;
            if (this.focusedField != null)
            {
                console.log("setting focus to:");
                console.log(this.focusedField);
                this.focusedField.focus();
            }
        });
    }

    saveBedtimes(clock: any) {
        this.httpClient.post("https://ng.renner.se/clocks.api.php?update-clock-bedtimes", clock).subscribe(
            (response) => {
                this.getClockConfigs();
            },
            (error) => console.log(error)
        );
    }
}
