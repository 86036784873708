<div class="navigation">
    <a [routerLink]="['/book', book?.previous]"><mat-icon >navigate_before</mat-icon></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a [routerLink]="['/book', book?.next]"><mat-icon>navigate_next</mat-icon></a>
</div>
<div class="book-all">
    <h1 class="book-author" *ngFor="let author of book?.authors; let i = index"><a [routerLink]="['/author', author.author_id]" class="author">{{ author?.author_name }}</a></h1>
    <h1 class="book-title">{{ book?.title }}</h1>
    <div class="book-container">
        <div class="book-info-main">
            <div>
                <div>
                    <img mat-card-image src="{{ book?.cover_url }}" alt="" />
                </div>
                <table class="book-info">
                    <tr class="genres" *ngFor="let genre of book?.genres; let i = index">
                        <th *ngIf="i == 0">Genre</th>
                        <th *ngIf="i != 0">&nbsp;</th>
                        <td>{{ genre.genre }}</td>
                    </tr>
                    <tr>
                        <th>År</th><td>{{ book?.year }}</td>
                    </tr>
                    <tr>
                        <th>Språk</th><td>{{ book?.language }}</td>
                    </tr>
                    <tr>
                        <th>Bindning</th><td>{{ book?.binding }}</td>
                    </tr>
                    <tr>
                        <th>Ägare</th><td>{{ book?.owner.owner }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>