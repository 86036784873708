import { Component, OnInit, OnDestroy } from '@angular/core';
import { AudioService } from "../../services/audio.service";
import { PlaylistService } from "../../services/playlist.service";
import { StreamState } from "../../interfaces/stream-state";
import { LocalStorageService } from '../../services/local-storage.service';
import {
    trigger,
    state,
    style,
    animate,
    transition,
  } from '@angular/animations';

@Component({
    selector: 'app-player',
    templateUrl: './player.component.html',
    styleUrls: ['./player.component.scss'],
    animations: [
        trigger('myInsertRemoveTrigger', [
            transition(':enter', [
              style({ opacity: 0 }),
              animate('100ms', style({ opacity: 1 })),
            ]),
            transition(':leave', [
              animate('200ms', style({ opacity: 0 }))
            ])
        ])
    ]
})

export class PlayerComponent implements OnInit {
    tracks: Array<any> = [];
    activeTrack: any = null;
    state: StreamState;
    minimized: boolean = false;

    defaultCover = "https://renner.se/image.php?img=/records/pics/0.jpg;s,0,300";
    public repeatState = [
        {
            icon: "repeat",
            state: "all",
        },
        {
            icon: "repeat_one",
            state: "one",
        },
        {
            icon: "repeat",
            state: "none",
            style: "color: rgba(0, 0, 0, 0.24)"
        }
    ];
    public activeRepeatState: number = 0;

    constructor (
        public audioService: AudioService,
        private playlistService: PlaylistService,
        private localStorage: LocalStorageService
    ) {
        this.audioService.getState().subscribe(state => {
            this.state = state;
        });

        playlistService.getAllTracks().subscribe(tracks => {
            this.tracks = tracks;
        });

        playlistService.getActiveTrack().subscribe(track => {
            this.activeTrack = track;
            if (Object.keys(this.activeTrack).length)
            {
                let nav: any;
                nav = window.navigator;

                if (nav && nav.mediaSession)
                {
                    nav.mediaSession.metadata = new MediaMetadata({
                        title: this.activeTrack.title,
                        artist: this.get_joined_artists(),
                        album: this.activeTrack.album,
                        artwork: [
                            { src: this.activeTrack.cover, sizes: '300x300', type: 'image/jpg' },
                        ]
                    });
                }
            }
        });

        this.audioService.getEvent().subscribe(event => {
            switch(event.type) {
                case "ended":
                    switch (this.repeatState[this.activeRepeatState].state) {
                        case "all":
                            this.next(true);
                            break;
                        case "one":
                            this.audioService.seekTo(0);
                            break;
                    }
                    break;
                case "timeupdate":
                    let nav: any;
                    nav = window.navigator;

                    if (nav && nav.mediaSession && this.state.currentTime < this.state.duration)
                    {
                        nav.mediaSession.setPositionState({
                            position: this.state.currentTime,
                            duration: this.state.duration,
                            playbackRate: 1,
                        });
                    }
                    break;
            }
        });

        let nav: any;
        nav = window.navigator;

        if (nav && nav.mediaSession)
        {
            nav.mediaSession.setActionHandler('previoustrack', this.previous.bind(this));
            nav.mediaSession.setActionHandler('nexttrack', this.next.bind(this));
            nav.mediaSession.setActionHandler('play', this.play.bind(this));
            nav.mediaSession.setActionHandler('pause', this.pause.bind(this));
            nav.mediaSession.setActionHandler('stop', this.stop.bind(this));
        }
    }

    ngOnInit() {
        this.minimized = this.localStorage.getPlayerMinimized();
    }

    playStream(url, play = true, seekTo = 0) {
        this.audioService.playStream(url, play, seekTo);
    }

    isFirstPlaying() {
        return this.playlistService.getIndexOfActiveTrack() === 0;
    }
    isLastPlaying() {
        return this.playlistService.getIndexOfActiveTrack() === this.tracks.length - 1;
    }

    pause() {
        this.audioService.pause();
    }

    play() {
        this.audioService.play();
    }

    stop() {
        this.audioService.stop();
    }

    next(play = false) {
        var index = this.playlistService.getIndexOfActiveTrack() + 1;
        if (index >= this.tracks.length) {
            index = 0;
        }
        this.playlistService.setActiveTrack(index);

        this.playStream(this.tracks[index].url, play, 0);
    }

    previous() {
        var index;
        if (this.state.currentTime < 5)
        {
            index = this.playlistService.getIndexOfActiveTrack() - 1;
            if (index < 0)
            {
                index = 0;
                this.audioService.seekTo(0);
            }
            this.playlistService.setActiveTrack(index);
        }
        else
        {
            this.audioService.seekTo(0);
        }
    }

    onSliderChangeEnd(change) {
        this.audioService.seekTo(change.value);
    }

    onVolumeSliderChange(change) {
        this.state.volume = change;
        this.audioService.volume(this.state.volume);
    }

    showPlaylist() {

    }

    changeRepeatMode() {
        this.activeRepeatState = (this.activeRepeatState + 1) % this.repeatState.length;
    }

    volumeDown() {
        if (this.state.volume > 4)
        {
            this.state.volume = this.state.volume - 5;
        }
        else
        {
            this.state.volume = 0;
        }

        this.audioService.volume(this.state.volume);
    }

    volumeUp() {
        if (this.state.volume < 96)
        {
            this.state.volume = this.state.volume + 5;
        }
        else
        {
            this.state.volume = 100;
        }

        this.audioService.volume(this.state.volume);
    }

    minimize() {
        this.minimized = !this.minimized;
        this.localStorage.storePlayerMinimized(this.minimized);
    }

    get_joined_artists() {
        let joined_artists = "";

        for (let artist of this.activeTrack.track_artist)
        {
            if (artist.join_phrase == null)
            {
                artist.join_phrase = "";
            }
            joined_artists = joined_artists + artist.artist_name + artist.join_phrase;
        }

        return joined_artists;
    }
}

interface MediaImage {
    // URL from which the user agent can fetch the image’s data.
    src: string;
    // Specify the MediaImage object’s sizes. It follows the spec of sizes attribute in HTML link element.
    sizes?: string;
    // A hint as to the media type of the image.
    type?: string;
}

interface MediaMetadataInit {
    // Media's title.
    title?: string;
    // Media's artist.
    artist?: string;
    // Media's album.
    album?: string;
    // Media's artwork.
    artwork?: MediaImage[];
  }

  declare class MediaMetadata {
    constructor(init?: MediaMetadataInit);
    // Media's title.
    title: string;
    // Media's artist.
    artist: string;
    // Media's album.
    album: string;
    // Media's artwork.
    artwork: MediaImage[];
  }
