<div *ngIf="firstLetter" class="header" [ngStyle]="{'top': headerTop}">
    <div class="first-letter">
        <a *ngIf="previousLetter" [routerLink]="['/albums', previousLetter]">&lt;</a>&nbsp;{{ firstLetter }}&nbsp;<a *ngIf="nextLetter" [routerLink]="['/albums', nextLetter]">&gt;</a>
    </div>
    <div class="letters">
        <div *ngFor="let letter of letters" class="letter">
            <a [routerLink]="['/albums', letter]">{{ letter }}</a>
        </div>
    </div>
</div>
<div class="album-container">
    <div mat-list-item class="album" *ngFor="let album of albums; let i = index" [ngStyle]="{'width.px': getImageWidth(), 'height.px': getImageHeight()}">
        <a [routerLink]="['/album', album.id]">
            <img src="{{ album?.cover }}" alt="" [ngStyle]="{'width.px': getImageWidth(), 'height.px': getImageHeight()}" class="record-cover" />
            <div class="overlay">
                {{ album?.artist }}<br>{{ album.title }}<br>{{ album.year }}
            </div>
        </a>
    </div>
</div>
<button class="mat-fab-bottom-right" mat-fab>
    <mat-icon class="icon" (click)="scrollToTop()">keyboard_arrow_up</mat-icon>
</button>
