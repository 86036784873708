import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

    constructor(
        private authService: AuthService
    ) { }

    intercept(req, next) {
        let jwt = this.authService.getToken();
        let tokenizedReq = null;
        if (jwt != null)
        {
            tokenizedReq = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${jwt}`
                }
            })
        }
        else
        {
            tokenizedReq = req.clone();
        }
        return next.handle(tokenizedReq)
    }
}
