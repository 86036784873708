<div *ngIf="firstLetter" class="header" [ngStyle]="{'top': headerTop}">
    <div class="first-letter">
        <a *ngIf="previousLetter" [routerLink]="['/books', previousLetter]">&lt;</a>&nbsp;{{ firstLetter }}&nbsp;<a *ngIf="nextLetter" [routerLink]="['/books', nextLetter]">&gt;</a>
    </div>
    <div class="letters">
        <div *ngFor="let letter of letters" class="letter">
            <a [routerLink]="['/books', letter]">{{ letter }}</a>
        </div>
    </div>
</div>
<div class="book-container">
    <div mdc-list-item class="book" *ngFor="let book of books; let i = index" [ngStyle]="{'width.px': getImageWidth(), 'height.px': getImageHeight()}">
        <a [routerLink]="['/book', book.book_id]">
            <img src="{{ book?.cover_url }}" alt="" [ngStyle]="{'width.px': getImageWidth(), 'height.px': getImageHeight()}" class="book-cover" />
            <div class="overlay">
                <ng-container *ngFor="let author of book.authors">
                    {{ author.author_name }}<br>
                </ng-container>
                {{ book?.title }}<br>{{ book?.year }}
            </div>
        </a>
    </div>
</div>
<button class="mat-fab-bottom-right" mat-fab>
    <mat-icon class="icon" (click)="scrollToTop()">keyboard_arrow_up</mat-icon>
</button>
