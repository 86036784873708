import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AudioService } from '../services/audio.service';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, tap, delay, finalize } from 'rxjs/operators';

import * as jwt_decode from 'jwt-decode';

interface LoginResult
{
    username: string;
    role: string;
    originalUserName: string;
    accessToken: string;
    refreshToken: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private loginUrl = "https://ng.renner.se/user.api.php";
    private user_authenticated_subject: BehaviorSubject<boolean>;
    public user_authenticated: Observable<boolean>;
    private timer: Subscription;
    private user: string;

    constructor (
        private http: HttpClient,
        private audio: AudioService,
    ) {
        this.user_authenticated_subject = new BehaviorSubject<boolean>(this.loggedIn());
        this.user_authenticated = this.user_authenticated_subject.asObservable();
    }

    authenticate(user)
    {
        this.user = user.username;
        return this.http.post<any>(this.loginUrl, user);
    }

    getToken()
    {
        return localStorage.getItem('jwt');
    }

    getRefreshToken()
    {
        return localStorage.getItem('refreshToken');
    }

    loggedIn()
    {
        return !!localStorage.getItem('jwt');
    }

    loginUser(jwt, token)
    {
        localStorage.setItem('jwt', jwt);
        localStorage.setItem('refreshToken', token);
        this.user_authenticated_subject.next(true);
    }

    logoutUser()
    {
        localStorage.removeItem('jwt');
        localStorage.removeItem('refreshToken');
        this.user_authenticated_subject.next(false);
        this.audio.pause();
    }

    belongTo(group: string): boolean
    {
        let jwt = this.getToken();
        if (jwt != null)
        {
            let groups: Array<any> = jwt_decode(jwt).data.groups;
            return groups.some(gr => gr.name === group);
        }
        else
        {
            return false;
        }
    }

    onLocalNetwork() : boolean
    {
        let jwt = this.getToken();
        if (jwt != null)
        {
            let ip_address: any = jwt_decode(jwt).data.ip;
            return ip_address?.startsWith("192.168.");
        }
        else
        {
            return false;
        }
    }

    private getTokenRemainingTime()
    {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
          return 0;
        }
        const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));
        const expires = new Date(jwtToken.exp * 1000);
        return expires.getTime() - Date.now();
    }

    private startTokenTimer()
    {
        const timeout = this.getTokenRemainingTime();
        this.timer = of(true)
            .pipe(
                delay(timeout),
                tap(() => this.refreshToken().subscribe(            
                    res => {
                        this.loginUser(res.jwt, res.token);
                    }
                ))
            )
            .subscribe();
    }

    private stopTokenTimer()
    {
        this.timer?.unsubscribe();
    }

    refreshToken() {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
          this.logoutUser();
          return of(null);
        }

        return this.http
          .post<any>(`${this.loginUrl}?refresh-token`, { refreshToken });
    }
}
