import {
    RouteReuseStrategy,
    ActivatedRouteSnapshot,
    DetachedRouteHandle,
    RouterModule,
    Routes,
    UrlSegment,
} from "@angular/router";
  
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
    private cache: { [key: string]: DetachedRouteHandle } = {};
  
    shouldDetach(route: ActivatedRouteSnapshot): boolean 
    {
        return route.routeConfig.data && route.routeConfig.data.reuse;
    }

    store(route: ActivatedRouteSnapshot, handler: DetachedRouteHandle|null): void 
    {
        // if (handler) {
        //     this.cache[this.getUrl(route)] = handler;
        // }
        return null;
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean 
    {
        // return !!this.cache[this.getUrl(route)];
        return false;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle 
    {
        if (!route.routeConfig || route.routeConfig.loadChildren) 
        {
            return null;
        }
        return this.cache[this.getUrl(route)];
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean 
    {
        if (future.routeConfig && future.routeConfig.data && future.routeConfig.data.reuse !== undefined) 
        {
            return future.routeConfig.data.reuse;
        }
        return future.routeConfig === current.routeConfig;
    }

    getUrl(route: ActivatedRouteSnapshot): string 
    {
        if (route.routeConfig) 
        {
            return route.routeConfig.path;
        }
    }
}