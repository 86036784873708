import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss']
})
export class BookComponent implements OnInit {

    book: any;
    currentId: number;

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        private route: ActivatedRoute,
        private titleService: Title
    ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.currentId = parseInt(params.get('id'), 10);
            console.log("Getting movie");
            this.getBook(this.currentId);
        });
    }

    getBook(id=1) {
        this.httpClient.get("https://ng.renner.se/book.api.php?id=" + id).subscribe((data)=>{
            this.book = data;
            this.setTitle(this.book.title + " (" + this.book.year + ")");
            console.log(this.book);
        });
        this.currentId = id;
    }

    setTitle(newTitle: string)
    {
        this.titleService.setTitle(newTitle);
    }
}
