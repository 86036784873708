<div class="navigation">
    <a [routerLink]="['/movie', movie?.previous]"><mat-icon >navigate_before</mat-icon></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a [routerLink]="['/movie', movie?.next]"><mat-icon>navigate_next</mat-icon></a>
</div>
<div class="movie-all">
    <h1 class="movie-title">{{ movie?.title }} ({{ movie?.year }})</h1>
    <h3 class="movie-otitle" *ngIf="movie?.title != movie?.original_title">{{ movie?.original_title }}</h3>
    <div class="movie-container">
        <div class="movie-info-main">
            <div>
                <div>
                    <img mat-card-image src="{{ movie?.cover_url }}" alt="" />
                </div>
                <table class="movie-info">
                    <tr class="directors" *ngFor="let director of movie?.directors; let i = index">
                        <th *ngIf="i == 0">Regissör</th>
                        <th *ngIf="i != 0">&nbsp;</th>
                        <td><a [routerLink]="['/director', director.directorid]" class="director">{{ director.name }}</a></td>
                    </tr>
                    <tr class="genres" *ngFor="let genre of movie?.genres; let i = index">
                        <th *ngIf="i == 0">Genre</th>
                        <th *ngIf="i != 0">&nbsp;</th>
                        <td>{{ genre.category }}</td>
                    </tr>
                    <tr>
                        <th>Längd</th>
                        <td>{{ movie?.length }}</td>
                    </tr>
                    <tr>
                        <th>Media</th><td>{{ movie?.media }}</td>
                    </tr>
                    <tr class="genres" *ngFor="let format of movie?.formats">
                        <th>Bildformat</th>
                        <td>{{ format.format }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div>
            <table mat-table [dataSource]="movie?.actors" class="actors">

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Skådespelare </th>
                    <td mat-cell *matCellDef="let actor"> <a [routerLink]="['/actor', actor.actor_id]" class="actor">{{ actor.name }}</a> </td>
                </ng-container>

                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef> Roll </th>
                    <td mat-cell *matCellDef="let actor"> {{ actor.role }} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsActors"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsActors;"></tr>
            </table>
        </div>
    </div>
</div>