import { NgModule } from '@angular/core';
import { BrowseComponent } from './browse/browse.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from "../material.module";
import { MovieComponent } from './movie/movie.component';
import { RouterModule } from '@angular/router';
import { NewsComponent } from './news/news.component';
import { DirectorComponent } from './director/director.component';
import { ActorComponent } from './actor/actor.component';


@NgModule({
  declarations: [BrowseComponent, MovieComponent, NewsComponent, DirectorComponent, ActorComponent],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule
  ]
})
export class MoviesModule { }
