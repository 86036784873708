import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from "../material.module";
import { AntonAgeComponent } from './anton-age/anton-age.component';
import { BillieAgeComponent } from './billie-age/billie-age.component';
import { FrejaAgeComponent } from './freja-age/freja-age.component';
import { MonthComponent } from './month/month.component';
import { NamesdayComponent } from './namesday/namesday.component';
import { RouterModule } from '@angular/router';
import { SunriseComponent } from './sunrise/sunrise.component';
import { SunriseTodayComponent } from './sunrise-today/sunrise-today.component';
import { YearCalendarComponent } from './year-calendar/year-calendar.component';
import { CreatorComponent } from './creator/creator.component';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { ImageCropperComponent } from 'ngx-image-cropper';


@NgModule({
    declarations: [
        SunriseComponent,
        YearCalendarComponent,
        NamesdayComponent,
        SunriseTodayComponent,
        AntonAgeComponent,
        BillieAgeComponent,
        FrejaAgeComponent,
        MonthComponent,
        CreatorComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        FormsModule,
        ImageCropperComponent,
        MatSelectModule,
    ],
    exports: [
        AntonAgeComponent,
        BillieAgeComponent,
        FrejaAgeComponent,
        ImageCropperComponent,
        MonthComponent,
        NamesdayComponent,
        SunriseTodayComponent,
    ]
})
export class CalendarModule { }
