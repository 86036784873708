<mat-card appearance="outlined" class="add-album">
    <mat-card-content class="album-container">
        <mat-form-field class="full-width">
            <mat-label>Search string</mat-label>
            <input matInput type="text" name="q" size="100" placeholder="MusicBrainz disc id or barcode number" #mbdid (keydown.enter)="handleEnterKeyPress($event, mbdid.value)">
        </mat-form-field>
        <button mat-raised-button color="primary" type="button" (click)="search(mbdid.value)" >Search</button>
    </mat-card-content>
</mat-card>
<mat-card appearance="outlined" class="add-album" *ngFor="let release of releases; let i = index">
    <mat-card-content class="album-container">
        <div>
            <ng-container *ngFor="let album_artist of release.artists; let j = index">
                <mat-form-field class="wide">
                    <mat-label>Artist name</mat-label>
                    <input matInput type='text' name='discArtist' [(ngModel)]="album_artist.artist_name">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Join phrase</mat-label>
                    <input matInput type='text' [(ngModel)]="album_artist.join_phrase" size=40>
                </mat-form-field>
                <br>
                <mat-form-field class="wide">
                    <mat-label>Sort name</mat-label>
                    <input matInput type='text' name='sortArtist' [(ngModel)]="album_artist.sort_name">
                </mat-form-field>
            </ng-container>
            <br>
            <mat-form-field class="wide">
                <mat-label>Title</mat-label>
                <input matInput type='text' name='albumTitle' [(ngModel)]="release.title">
            </mat-form-field>
            <br>
            <mat-form-field>
                <mat-label>Release date</mat-label>
                <input matInput [matDatepicker]="picker" [(ngModel)]="release.release_date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Genre</mat-label>
                <input matInput type='text' name='discGenre' [(ngModel)]="release.genre">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Barcode</mat-label>
                <input matInput type='text' name='discBarcode' [(ngModel)]="release.barcode">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Format</mat-label>
                <input matInput type='text' name='discFormat' [(ngModel)]="release.format">
            </mat-form-field>
            <br>
            <mat-form-field class="wide">
                <mat-label>Extra</mat-label>
                <textarea matInput name='discExtra' cols=80 rows=6>{{ release.extra }}</textarea>
            </mat-form-field>
        </div>
        <div>
            <img src="{{ release['front_cover']['thumb_500'] }}" />
        </div>
        <div *ngFor="let disc of release.discs; let i = index">
            <mat-form-field class="wide">
                <mat-label>Disc title</mat-label>
                <input matInput type='text' name='discTitle' [(ngModel)]="disc.title">
            </mat-form-field>
            <table border=0>
                <tr *ngFor="let track of disc.tracks; let i = index">
                    <td align='right' valign='top'>
                        <mat-form-field>
                            <mat-label>Track #</mat-label>
                            <input matInput type='text' name='number[]' [(ngModel)]="track.number" size=2 readonly>
                        </mat-form-field>
                    </td>
                    <td valign='top'>
                        <mat-form-field class="wide">
                            <mat-label>Title</mat-label>
                            <input matInput type='text' name="trackTitle[]" [(ngModel)]="track.title" size=40>
                        </mat-form-field>
                        <br>
                        <ng-container *ngFor="let track_artist of track.artists; let j = index">
                            <mat-form-field class="wide">
                                <mat-label>Track artist</mat-label>
                                <input matInput type='text' name="trackArtist[]" [(ngModel)]="track_artist.artist_name" size=40>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Join phrase</mat-label>
                                <input matInput type='text' [(ngModel)]="track_artist.join_phrase" size=40>
                            </mat-form-field>
                        </ng-container>
                    </td>
                    <td valign='top'>
                        <mat-form-field>
                            <mat-label>Length (s)</mat-label>
                            <input matInput type='text' name="trackLength[]" [(ngModel)]="track.length" size=5>
                        </mat-form-field>
                    </td>
                </tr>
            </table>
        </div>
        <table border=0>
            <tr>
                <td colspan=3>
                    <table width='100%'>
                        <tr>
                            <td>
                                <mat-form-field>
                                    <mat-label>Owner</mat-label>
                                    <mat-select [(value)]='release.owner_id'>
                                        <mat-option [value]='12'>Anton</mat-option>
                                        <mat-option [value]='30'>Billie</mat-option>
                                        <mat-option [value]='1'>Jimmy</mat-option>
                                        <mat-option [value]='2'>Linda</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <mat-label>Bought</mat-label>
                                    <input matInput [matDatepicker]="picker2" [(ngModel)]="release.bought">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                            </td>
                            <td valign='bottom' align='right'>
                                <mat-checkbox name='force' [(ngModel)]="release.force_add">Force add</mat-checkbox>
                            </td>
                        </tr>
                    </table>
                </td>
                <td>
                    <br>
                    <br>
                    <button mat-raised-button color="primary" type='button' name='store' (click)="save(release)">Spara</button>
                </td>

            </tr>
        </table>
    </mat-card-content>
</mat-card>
<mat-card appearance="outlined" class="add-album" *ngIf="msg != ''">
    <mat-card-content>
        {{ msg }}
    </mat-card-content>
</mat-card>