import { Component, OnInit, Output, Input, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HostListener } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Title } from '@angular/platform-browser';
import { ScrollDetectionService } from '../../services/scroll-detection.service';

@Component({
  selector: 'app-book',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.scss']
})
export class BrowseComponent implements OnInit {
    public letters = "#ABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ".split("");
    public firstLetter: string;
    public nextLetter: string;
    public previousLetter: string;
    books: any;
    screenHeight: number;
    screenWidth: number;
    lastScrollTop: number = 0;
    headerTop: string = "0px";

    constructor(
        private httpClient: HttpClient,
        private route: ActivatedRoute,
        private titleService: Title,
        private scrollService: ScrollDetectionService
    ) {
        this.getScreenSize();
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            let letter = "A";
            this.firstLetter = params.get("letter");
            console.log(this.firstLetter);
            if (this.firstLetter)
            {
                letter = this.firstLetter;
            }
            else if (this.firstLetter = localStorage.getItem("books-firstLetter"))
            {
                letter = this.firstLetter;
            }
            this.firstLetter = letter;
            this.titleService.setTitle("Böcker på " + letter);
            this.previousLetter = this.getPreviousLetter();
            this.nextLetter = this.getNextLetter();
            this.getAllBooks(letter);
        });

        this.scrollService.getScrollUpdate().subscribe(direction => {
            console.log("scrolling: " + direction);
            switch(direction)
            {
                case "up":
                    this.headerTop = "0px";
                    break;
                case "down":
                    this.headerTop = "-110px";
                    break;
            }
        });
    }

    getAllBooks(letter) {
        this.httpClient.get("https://ng.renner.se/book.api.php?firstLetter=" + encodeURIComponent(letter)).subscribe((data)=>{
            console.log(data);
            this.books = data;
            localStorage.setItem("books-firstLetter", letter);
            this.scrollToTop();
        });
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenHeight = window.innerHeight;
        let mainContainer = document.getElementById("container");
        let scrollbarWidth = mainContainer.offsetWidth - mainContainer.clientWidth;
        this.screenWidth = window.innerWidth - scrollbarWidth;
        console.log(this.screenHeight, this.screenWidth, scrollbarWidth);
    }

    onScroll(event) {
        let st = event.target.scrollTop;

        if (st > this.lastScrollTop)
        {
            this.headerTop = "-110px";
        }
        else
        {
            this.headerTop = "0px";
        }
        console.log(st + " : " + this.lastScrollTop);
        this.lastScrollTop = st;
    }

    getImageWidth() {
        let image_width = 350;
        let no_images = Math.round(this.screenWidth / image_width);

        image_width = this.screenWidth / no_images;

        return image_width;
    }

    getImageHeight() {
        let image_width_start = 350;
        let image_height_start = 480;
        let no_images = Math.round(this.screenWidth / image_width_start);

        let image_width = this.screenWidth / no_images;

        let ratio = image_width_start / image_width;

        return image_height_start / ratio;
    }

    getPreviousLetter() {
        let idx = this.letters.indexOf(this.firstLetter);
        console.log(idx);
        if (idx > 0)
        {
            return this.letters[idx-1];
        }
        else
        {
            return null;
        }
    }

    getNextLetter() {
        let idx = this.letters.indexOf(this.firstLetter);
        console.log(idx);
        if (idx < (this.letters.length - 1))
        {
            return this.letters[idx+1];
        }
        else
        {
            return null;
        }
    }

    scrollToTop() {
        var element = document.getElementById('container');
        element.scrollTop = 0;
    }
}
