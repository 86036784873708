import { NgModule, Component } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AlbumComponent } from '../records/album/album.component';
import { AddComponent as AddAlbumComponent } from '../records/add/add.component';
import { BrowseComponent as BookBrowseComponent } from '../books/browse/browse.component';
import { BrowseComponent as MovieBrowseComponent } from '../movies/browse/browse.component';
import { BrowseComponent as RecordBrowseComponent } from '../records/browse/browse.component';
import { BrowseComponent  as GalleryBrowseComponent } from '../gallery/browse/browse.component';
import { BrowseComponent  as BlogBrowseComponent } from '../blog/browse/browse.component';
import { BookComponent } from '../books/book/book.component';
import { ClockConfigComponent } from '../automation/clock-config/clock-config.component';
import { CreatorComponent } from "../calendar/creator/creator.component";
import { EditComponent as EditBookComponent } from '../books/edit/edit.component';
import { EditEntryComponent } from '../blog/edit-entry/edit-entry.component';
import { TemperatureComponent } from '../temperature/temperature/temperature.component';
import { MovieComponent } from '../movies/movie/movie.component';
import { PlaylistComponent } from "../music-player/playlist/playlist.component";
import { UserLoginComponent } from '../user-login/user-login.component';
import { SingleEntryComponent as BlogSingleEntryComponent } from '../blog/single-entry/single-entry.component';
import { NewsComponent } from '../blog/news/news.component';
import { NewsComponent as AlbumNewsComponent } from '../records/news/news.component';
import { NewsComponent as BookNewsComponent } from '../books/news/news.component';
import { NewsComponent as MovieNewsComponent } from '../movies/news/news.component';
import { SunriseComponent } from '../calendar/sunrise/sunrise.component';
import { YearCalendarComponent } from '../calendar/year-calendar/year-calendar.component';
import { StartPageComponent } from '../start-page/start-page.component';
import { RecipeComponent } from '../recipes/recipe/recipe.component';
import { RecipesComponent } from '../recipes/recipes/recipes.component';
import { DirectorComponent } from "../movies/director/director.component";
import { ActorComponent } from "../movies/actor/actor.component";
import { ArtistComponent } from "../records/artist/artist.component";
import { AuthorComponent } from "../books/author/author.component";

const routes: Routes = [
    { path: "", component: StartPageComponent },
    { path: "actor/:id", component: ActorComponent },
    { path: "album/:id", component: AlbumComponent },
    { path: "albums", component: RecordBrowseComponent },
    { path: "albums/news", component: AlbumNewsComponent },
    { path: "albums/:letter", component: RecordBrowseComponent },
    { path: "album-add", component: AddAlbumComponent },
    { path: "artist/:id", component: ArtistComponent },
    { path: "author/:id", component: AuthorComponent },
    { path: "blog", component: BlogBrowseComponent },
    { path: "blog/:id", component: BlogSingleEntryComponent },
    { path: "blog-edit/:id", component: EditEntryComponent },
    { path: "book/:id", component: BookComponent },
    { path: "book-edit", component: EditBookComponent },
    { path: "book-edit/:id", component: EditBookComponent },
    { path: "books", component: BookBrowseComponent },
    { path: "books/news", component: BookNewsComponent },
    { path: "books/:letter", component: BookBrowseComponent },
    { path: "calendarCreator", component: CreatorComponent },
    { path: "clocks", component: ClockConfigComponent },
    { path: "director/:id", component: DirectorComponent },
    { path: "gallery", component: GalleryBrowseComponent },
    { path: "gallery/:path", component: GalleryBrowseComponent },
    { path: "login", component: UserLoginComponent },
    { path: "movies/news", component: MovieNewsComponent },
    { path: "movies/:letter", component: MovieBrowseComponent },
    { path: "movie/:id", component: MovieComponent },
    { path: "movies", component: MovieBrowseComponent },
    { path: "music", component: PlaylistComponent },
    { path: "news", component: NewsComponent },
    { path: "recipe/:id", component: RecipeComponent },
    { path: "recipes", component: RecipesComponent },
    { path: "start-page", component: StartPageComponent },
    { path: "sunrise", component: SunriseComponent },
    { path: "temperature", component: TemperatureComponent },
    { path: "temperature/:start", component: TemperatureComponent },
    { path: "year-calendar", component: YearCalendarComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
