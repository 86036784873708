<div class="start-page-container">
    <div class="start-page overflow start-width">
        <app-blog-news></app-blog-news>
    </div>
    <div class="start-page overflow">
        <blog-day-history></blog-day-history>
    </div>
    <div class="start-page2 overflow2">
        <app-anton-age></app-anton-age>
        <app-ella-age></app-ella-age>
        <app-freja-age></app-freja-age>
        <app-sunrise-today></app-sunrise-today>
        <app-namesday></app-namesday>
        <gallery-day-history></gallery-day-history>
        <app-month></app-month>
        <app-comments></app-comments>
        <app-day-history-graph></app-day-history-graph>
    </div>
</div>