import { AuthService } from '../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'blog-day-history',
  templateUrl: './day-history.component.html',
  styleUrls: ['./day-history.component.scss']
})
export class DayHistoryComponent implements OnInit {
    years: any = [];
    currentDate: Date;

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
    ) { }

    ngOnInit(): void {
        this.authService.user_authenticated.subscribe((x)=>{
            console.log("read blog entries")
            this.getEntries();
        });
    }

    getEntries(date = null) {
        let args = "";
        if (date != null)
        {
            args = "?month=" + (date.getMonth() + 1) + "&day=" + date.getDate();
            this.currentDate = date;
            console.log("args: " + args);
        }
        else
        {
            this.currentDate = new Date();
            console.log("date not set");
        }
        this.years = [];
        this.httpClient.get("https://ng.renner.se/blog_day_history.api.php" + args).subscribe((data)=>{
            let tmp_data: any = data;
            let currentYear = new Date().getFullYear();
            for(let entry of tmp_data)
            {
                let year = parseInt(entry.date);
                let parser = new DOMParser();
                let parsedHtml = parser.parseFromString(entry.entry, 'text/html');
                let images = parsedHtml.images;
                let links = parsedHtml.links;
                let videos = parsedHtml.getElementsByTagName('video');
                let blogMedia = [];

                for (let i = 0; i < images.length; i++)
                {
                    let src = images[i].src;
                    let src_full = images[i].getAttribute("src-full");

                    let tmp_hostname = location.protocol + '//' + location.hostname;
                    if (location.port)
                    {
                        tmp_hostname += ':' + location.port;
                    }

                    if (src.startsWith(tmp_hostname))
                    {
                        src = src.replace(tmp_hostname, 'https://renner.se');
                    }

                    if (src_full != null && src_full.startsWith(tmp_hostname))
                    {
                        src_full = src_full.replace(tmp_hostname, 'https://renner.se');
                    }
                    const image = {
                        type: 'image',
                        src: src,
                        src_full: src_full,
                        title: images[i].getAttribute("title"),
                    }
                    blogMedia.push(image);
                }
                
                for(let i = 0; i < videos.length; i++)
                {
                    let src = videos[i].children[0].getAttribute("src");

                    if (src.startsWith('/'))
                    {
                        src = 'https://renner.se' + src;
                    }

                    console.log("src: " + src);
                    const image = {
                        type: 'video',
                        src: src,
                        src_full: src,
                        title: "video",
                    }
                    blogMedia.push(image);
                }

                const blogEntry = {
                    author: entry.author,
                    id: entry.id,
                    date: entry.date,
                    title: entry.title,
                    media: blogMedia,
                    active_image: 0,
                    text: parsedHtml.all[0].textContent,
                }

                if (typeof this.years.find(x => x.year === (currentYear - year)) === 'undefined')
                {
                    let thisYear = {
                        year: currentYear - year,
                        blogEntries: [],
                        temperature: []
                    }
                    this.years.push(thisYear);
                }
                let xYear = this.years.find(x => x.year === (currentYear - year));
                xYear.blogEntries.push(blogEntry);
                console.log(xYear);
            }
            console.log(this.years);
        });
    }


    getPreviousDate()
    {
        this.currentDate.setDate(this.currentDate.getDate() - 1);
        return this.currentDate;
    }

    getNextDate()
    {
        this.currentDate.setDate(this.currentDate.getDate() + 1);
        return this.currentDate;
    }

}
