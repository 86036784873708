<div class="book-wrapper">
    <div *ngFor="let book of books; let i = index">
        <br>
        <div>{{ book?.owner.owner }}</div>
        <div class="author" *ngFor="let author of book?.authors"><a [routerLink]="['/author', author.author_id]" class="author">{{ author.author_name }}</a></div>
        <div class="book"><a [routerLink]="['/book', book.book_id]">{{ book?.title }} ({{ book?.year}})</a></div>
        <div>{{ book?.bought }}</div>
        <div><a [routerLink]="['/book', book.id]"><img src="{{ book?.cover_url }}" alt="" class="book-cover" /></a></div>
        <br>
    </div>
</div>