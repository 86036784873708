import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Component, OnInit, ɵConsole, Input } from '@angular/core';
import { HostListener } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Location } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { Router } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('myInsertRemoveTrigger', [
        transition(':enter', [
          style({ opacity: 0 }),
          animate('300ms', style({ opacity: 1 })),
        ]),
        transition(':leave', [
          animate('300ms', style({ opacity: 0 }))
        ])
    ])
]
})
export class EntryComponent implements OnInit {
    rawBlogEntry: any;
    @Input() blogEntry: any;
    swipeCoord: [number, number];
    swipeTime: any;
    id: string;
    currentIndex: number = 0;
    currentSlide = null;
    screenHeight: number;
    screenWidth: number;
    public ip_address: string = "";
    public showModal: boolean = false;
    comment_name: string = '';
    comment_email: string = '';
    comment_text: string = '';

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
        private location: Location,
        private route: ActivatedRoute,
        private router: Router,
        public platform: Platform,
    )
    {
        this.authService.user_authenticated.subscribe((x)=>{
            console.log("auth service")
        });
    }

    ngOnInit() {

        console.log("Got here");
        this.rawBlogEntry = this.blogEntry?.raw_entry;
        console.log("Raw");
        console.log(this.rawBlogEntry);
        this.getIPAddress();
}

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth - 8; // 8 is the width of the scrollbar
        console.log(this.screenHeight, this.screenWidth);
    }

    onClick(event) {
        console.log(event);
        console.log(event.target);
        if (!(this.platform.ANDROID || this.platform.IOS))
        {
            if (event.target.localName == "div" && event.target.classList.contains("img-slides"))
            {
                this.closeModal();
            }
            else if (event.target.localName == "img" && event.target.classList.contains("ng-star-inserted"))
            {
                console.log(event.offsetX);
                console.log(event.srcElement.width/2);
                if (event.offsetX < (event.srcElement.width/2))
                {
                    this.previousImage();
                }
                else
                {
                    this.nextImage();
                }
            }
            else if (event.target.localName == 'img' && event.target.className == 'blog' && event.target.getAttribute('src-full') != null)
            {
                console.log("Clicked on image, getting src");
                let src = event.target.currentSrc;
                console.log(src);
                let found = this.blogEntry.images.findIndex(image => image.src == src);
                console.log(found);
                this.openModal(found);
            }
            else if (event.target.localName == 'img' && event.target.parentNode.tagName == 'A' && event.target.parentNode.getAttribute('routerlink') != null)
            {
                if (event.target.parentNode.getAttribute('routerlink').startsWith('/gallery'))
                {
                    let href = event.target.parentNode.getAttribute('routerlink');
                    href = href.replace('/gallery/', '');
                    console.log("Routerlink: " + href);
                    this.router.navigate(['gallery', href]);
                }
            }
            else if (event.target.localName == 'a' && event.target.getAttribute('routerlink') != null)
            {
                if (event.target.getAttribute('routerlink').startsWith('/gallery'))
                {
                    let href = event.target.getAttribute('routerlink');
                    href = href.replace('/gallery/', '');
                    console.log("Routerlink: " + href);
                    this.router.navigate(['gallery', href]);
                }
            }
        }
    }


    onSwipe(event: TouchEvent, action: String) {
        const coord: [number, number] = [event.changedTouches[0].clientX, event.changedTouches[0].clientY];
        const time = new Date().getTime();

        if (action == 'start')
        {
            this.swipeCoord = coord;
            this.swipeTime = time;
        }
        else if (action == 'end')
        {
            const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
            const duration = time - this.swipeTime;

            if (duration < 1000 && Math.abs(direction[0]) > 30 && Math.abs(direction[0]) > Math.abs(direction[1] * 3))
            {
                const swipe = direction[0] < 0 ? 'next' : 'previous';
                console.log(swipe);

                if (swipe == 'next')
                {
                    this.nextImage();
                }
                else if (swipe == 'previous')
                {
                    this.previousImage();
                }
            }
        }


        console.log(event);
    }

    openModal(index): void {
        console.log("Opening modal");
        this.currentIndex = index;
        this.currentSlide = this.blogEntry.images[this.currentIndex];
        if (this.currentSlide.src_full == null)
        {
            this.currentSlide.src_full = this.currentSlide.src;
        }
        console.log(this.currentSlide);
        //this.currentSlide = image;
        document.getElementById('player-container').style.display = "none";
        this.showModal = true;
    }

    closeModal(): void {
        console.log("Closing modal");
        document.getElementById('player-container').style.display = "flex";
        this.showModal = false;
    }

    nextImage()
    {
        console.log("nextImage");
        console.log(this.currentIndex);
        this.currentIndex++;
        if (this.currentIndex >= this.blogEntry.images.length)
        {
            this.currentIndex = 0;
        }
        this.currentSlide = this.blogEntry.images[this.currentIndex];
        console.log(this.currentIndex);

        this.preloadNext();
    }

    preloadNext()
    {
        let next = this.currentIndex + 1;
        if (next >= this.blogEntry.images.length)
        {
            next = 0;
        }

        let img = new Image();
        img.src = this.blogEntry.images[next].src;
        console.log("Preloaded: " + img.src);
    }

    previousImage()
    {
        console.log("previousImage");
        console.log(this.currentIndex);
        this.currentIndex--;
        if (this.currentIndex < 0)
        {
            this.currentIndex = this.blogEntry.images.length - 1;
        }
        this.currentSlide = this.blogEntry.images[this.currentIndex];
        console.log(this.currentIndex);
    }

    onSaveComment()
    {
        let payload = {
            blog_id: this.blogEntry.id,
            ip: this.ip_address,
            name: this.comment_name,
            email: this.comment_email,
            comment: this.comment_text
        };

        this.httpClient.post("https://ng.renner.se/blog.api.php?save-comment", payload).subscribe(
            (response) => {
                console.log(response);
            },
            (error) => console.log(error)
        );
    }

    onHideComment(id)
    {
        let payload = {
            id: id
        }
        console.log("hiding comment: " + id);
        this.httpClient.post("https://ng.renner.se/blog.api.php?hide-comment", payload).subscribe(
            (response) => {
                console.log(response);
            },
            (error) => console.log(error)
        );
    }

    onBlockComment(id)
    {
        let payload = {
            id: id
        }
        this.httpClient.post("https://ng.renner.se/blog.api.php?block-comment", payload).subscribe(
            (response) => {
                console.log(response);
            },
            (error) => console.log(error)
        );
    }

    getIPAddress()
    {
        // this.httpClient.get("https://api.ipify.org?format=json").subscribe(
        //     (response) => {
        //         console.log(response);
        //         let tmp: any = response;
        //         this.ip_address = tmp.ip;
        //     },
        //     (error) => console.log(error)
        // );
    }

    belongTo(group)
    {
        return this.authService.belongTo(group);
    }

    loggedIn()
    {
        return this.authService.loggedIn();
    }
}
