import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NewsComponent } from './news/news.component';
import { MaterialModule } from '../material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowseComponent } from './browse/browse.component';
import { EntryComponent } from './entry/entry.component';
import { RouterModule } from '@angular/router';
import { DayHistoryComponent } from './day-history/day-history.component';
import { CommentsComponent } from './comments/comments.component';
import { EditEntryComponent } from './edit-entry/edit-entry.component';
import { SafeHtmlPipe } from '../safe-html.pipe';
import { SingleEntryComponent } from './single-entry/single-entry.component';

@NgModule({
    declarations: [
        NewsComponent,
        BrowseComponent,
        CommentsComponent,
        DayHistoryComponent,
        EntryComponent,
        EditEntryComponent,
        SafeHtmlPipe,
        SingleEntryComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        MaterialModule,
        RouterModule
    ],
    exports: [
        CommentsComponent,
        DayHistoryComponent,
        NewsComponent
    ]
})
export class BlogModule { }
