<div *ngIf="firstLetter" class="header" [ngStyle]="{'top': headerTop}">
    <div class="first-letter">
        <a *ngIf="previousLetter" [routerLink]="['/movies', previousLetter]">&lt;</a>&nbsp;{{ firstLetter }}&nbsp;<a *ngIf="nextLetter" [routerLink]="['/movies', nextLetter]">&gt;</a>
    </div>
    <div class="letters">
        <div *ngFor="let letter of letters" class="letter">
            <a [routerLink]="['/movies', letter]">{{ letter }}</a>
        </div>
    </div>
</div>
<div class="movie-container">
    <div mat-list-item class="movie" *ngFor="let movie of movies; let i = index" [ngStyle]="{'width.px': getImageWidth(), 'height.px': getImageHeight()}">
        <a [routerLink]="['/movie', movie.id]">
            <img src="{{ movie?.cover_url }}" alt="" [ngStyle]="{'width.px': getImageWidth(), 'height.px': getImageHeight()}" class="movie-cover" />
            <div class="overlay">
                {{ movie?.title }}<br>{{ movie?.year }}
            </div>
        </a>
    </div>
</div>
<button class="mat-fab-bottom-right" mat-fab>
    <mat-icon class="icon" (click)="scrollToTop()">keyboard_arrow_up</mat-icon>
</button>
