import { Injectable, OnInit } from '@angular/core';
import { of } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs";
import { LocalStorageService } from './local-storage.service'

@Injectable({
    providedIn: 'root'
})
export class PlaylistService {

    public indexOfActiveTrack: number = 0;
    tracks: Array<any> = [];

    private activeTrack: BehaviorSubject<any> = new BehaviorSubject({});

    constructor(
        private localStorageService: LocalStorageService,
    ) {
        var tracks = this.localStorageService.getPlaylist();
        if(tracks != null && tracks.length != 0)
        {
            this.tracks = tracks;
            this.indexOfActiveTrack = this.localStorageService.getIndexOfActiveTrack();
            this.activeTrack.next(this.tracks[this.indexOfActiveTrack]);
        }
    }

    getTrack(index) {
        return this.tracks[index];
    }

    getAllTracks() {
        return of(this.tracks);
    }

    addTrack(track) {
        this.tracks.push(track);
        this.localStorageService.storePlaylist(this.tracks);
    }

    setActiveTrack(index) {
        console.log("change active track: " + index);
        this.indexOfActiveTrack = index;
        this.activeTrack.next(this.tracks[index]);
        this.localStorageService.storeIndexOfActiveTrack(index);
        this.localStorageService.storePlayerActiveTrack(this.tracks[index]);
    }

    getActiveTrack(): Observable<any> {
        return this.activeTrack.asObservable();
    }

    getIndexOfActiveTrack() {
        return this.indexOfActiveTrack;
    }

    removeTrack(index) {
        this.tracks.splice(index, 1);
        if (this.indexOfActiveTrack == index && (index < this.tracks.length - 1))
        {
            this.setActiveTrack(index);
        }
        else if (index < this.indexOfActiveTrack)
        {
            this.setActiveTrack(index-1);
        }
        this.localStorageService.storePlaylist(this.tracks);
    }

    removeAllTracks() {
        var l = this.tracks.length;
        for (var i = 0; i < l; i++)
        {
            this.tracks.pop();
        }
    }
}
