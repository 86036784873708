import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from "../material.module";
import { AlbumComponent } from './album/album.component';
import { NewsComponent } from './news/news.component';
import { BrowseComponent } from './browse/browse.component';
import { AddComponent } from './add/add.component';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { ArtistComponent } from './artist/artist.component';


@NgModule({
    declarations: [AlbumComponent, NewsComponent, BrowseComponent, AddComponent, ArtistComponent],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        MaterialModule,
        MatSelectModule
    ]
})
export class RecordsModule { }
