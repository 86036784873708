<div class="navigation">
    <a [routerLink]="['/album', albumData?.previous]"><mat-icon >navigate_before</mat-icon></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a [routerLink]="['/album', albumData?.next]"><mat-icon>navigate_next</mat-icon></a>
</div>
<div class="album-all">
    <div class="album-container">
        <span class="title">{{ albumData?.title }}</span>
        <div class="now-playing">
            <div class="now-playing-cover">
                <img src="{{ albumData?.cover }}" alt="" />
            </div>
            <div>
                <div class="info" *ngIf="albumData">
                    <span class="artist" *ngFor="let artist of albumData.artists"><a [routerLink]="['/artist', artist.artist_id]" class="artist">{{ artist.artist_name }}</a>{{ artist.join_phrase }}</span><br>
                    <span class="album">({{ albumData?.year }})</span><br>
                    <span class="album">{{ albumData?.length }}</span><br>
                    <span class="album" *ngIf="albumData.genre">[{{ albumData.genre.genre }}]</span><br>
                    <span class="album" *ngIf="albumData.format">{{ albumData.format.type }}</span><br>
                </div>
                <button mat-button (click)="playAlbum()" *ngIf="!allTracksMissing()">
                    <mat-icon>play_arrow</mat-icon>
                </button>
                <button mat-button (click)="addAlbumToPlaylist()" *ngIf="!allTracksMissing()">
                    <mat-icon>playlist_add</mat-icon>
                </button>
            </div>
        </div>
        <div class="tracks-tables">
            <mat-list color="primary" class="playlist" *ngFor="let record of albumData?.records; let j = index">
                <h3 mat-subheader *ngIf="albumData.records.length > 1" class="recordTitle">{{ record.title }}</h3>
                <mat-action-list color="primary" class="tracklist">
                    <div class="track2">
                        <h4 class="track-no">#</h4><h4 class="track-title">Titel</h4><h4>Artist</h4><h4 class="track-length">Längd</h4><h4>&nbsp;</h4>
                    </div>
                    <div class="track2" *ngFor="let track of record.tracks; let i = index">
                        <h4 class="track-no">{{ track.no }}</h4>
                        <h4 class="track-title">{{ track.title }}</h4>
                        <h4 class="track-title">{{ getArtistJoined(track.artists) }}</h4>
                        <h4 class="track-length">{{ convertSecondsToTime(track.length) }}</h4>
                        <h4><button mat-button (click)="addTrackToPlaylist(j, i)" *ngIf="!track.missing"><mat-icon>playlist_add</mat-icon></button></h4>
                    </div>
                </mat-action-list>
            </mat-list>
        </div>
    </div>
</div>