import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from "../material.module";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowseComponent } from './browse/browse.component';
import { RouterModule } from '@angular/router';
import { LightboxModule } from '../lightbox/lightbox.module';
import { DayHistoryComponent } from './day-history/day-history.component';


@NgModule({
    declarations: [
        BrowseComponent, 
        DayHistoryComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        RouterModule,
        LightboxModule,
        MaterialModule
    ],
    exports: [
        DayHistoryComponent
    ]
})
export class GalleryModule { }
