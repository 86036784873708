import { AuthService } from '../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.scss']
})
export class RecipesComponent implements OnInit {
    public recipes: any;

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        private titleService: Title,
    ) { }

    ngOnInit(): void {
        this.titleService.setTitle("Recept");
        this.httpClient.get("https://ng.renner.se/recipes.api.php").subscribe((data)=>{
            this.recipes = data;
            console.log(this.recipes);
        });
    }
}
