<div class="blog-entry-container">
    <mat-card appearance="outlined" class="blog">
        <div class="blog-header">
            <mat-card-subtitle>{{ blogEntry?.author }}</mat-card-subtitle>
            <mat-card-subtitle>
                <span class="material-icons" [routerLink]="['/blog-edit', blogEntry?.id]" *ngIf="belongTo('admin')">edit</span>
            </mat-card-subtitle>
        </div>
        <mat-card-subtitle>{{ blogEntry?.date }}</mat-card-subtitle>
        <mat-card-title class="mat-headline-5" [routerLink]="['/blog', blogEntry?.id]">{{ blogEntry?.title }}</mat-card-title>
        <mat-card-content (click)="onClick($event)">
            <div [innerHtml]="blogEntry?.raw_entry | safeHtml"></div>
        </mat-card-content>
    </mat-card>
</div>

<div id="imgModal" class="slide-modal" @myInsertRemoveTrigger *ngIf="showModal">
    <span class="close cursor" (click)="closeModal()">&times;</span>
    <div class="slide-modal-content">
        <div class="img-slides" (click)="onClick($event)" (touchstart)="onSwipe($event, 'start')" (touchend)="onSwipe($event, 'end')">
            <img src="{{ currentSlide?.src_full }}" *ngIf="currentSlide?.type == 'image'">
            <video *ngIf="currentSlide?.type == 'video'" controls>
                <source src="{{ currentSlide?.src_full }}" type="video/mp4">
            </video>
        </div>
        <div *ngIf="!(this.platform.ANDROID || this.platform.IOS)">
            <a class="prev" (click)="previousImage()">&#10094;</a>
            <a class="next" (click)="nextImage()">&#10095;</a>
        </div>
    </div>
</div>
