import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Title } from '@angular/platform-browser';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-recipe',
  templateUrl: './recipe.component.html',
  styleUrls: ['./recipe.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RecipeComponent implements OnInit {
    public recipe: any;

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        private route: ActivatedRoute,
        private titleService: Title,
    ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            let id = parseInt(params.get('id'), 10);
            console.log("Getting recipe");
            this.getRecipe(id);
        });

    }

    getRecipe(id)
    {
        this.httpClient.get("https://ng.renner.se/recipe.api.php?id=" + id).subscribe((data)=>{
            this.recipe = data;
            this.titleService.setTitle(this.recipe.title);
            console.log(this.recipe);
        });
    }

    onPrint()
    {
        let element = document.getElementById("dynamic-menu") as HTMLElement;
        let header_display = "";
        console.log(element);
        header_display = getComputedStyle(element).display;

        window.print();

        element.style.display = header_display;
        console.log(element);
    }
}
