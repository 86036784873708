import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'gallery-day-history',
  templateUrl: './day-history.component.html',
  styleUrls: ['./day-history.component.scss']
})
export class DayHistoryComponent implements OnInit {
    public todays_picture;

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
    ) { }

    ngOnInit(): void {
        this.getTodaysPhoto();
    }

    getTodaysPhoto()
    {
        this.httpClient.get("https://ng.renner.se/gallery.api.php?todays-photo").subscribe((data)=>{
            let images: any = data;
            console.log("images: ");
            console.log(images);
            this.todays_picture = images[Math.floor(Math.random() * images.length)];
        }); 
    }

}
