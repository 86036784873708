import { Component, OnInit } from '@angular/core';
import { AudioService } from "../../services/audio.service";
import { PlaylistService } from "../../services/playlist.service";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss']
})

export class PlaylistComponent {
    activeTrack: any;
    tracks: Array<any> = [];
    icon: Array<string> = [];
    icon_default = "music_note";
    icon_hover = "play_arrow";
    icon_playing = "volume_up";

    constructor(
        private audioService: AudioService,
        private playlistService: PlaylistService,
        private titleService: Title,
    ) {
        playlistService.getAllTracks().subscribe(tracks => {
            this.tracks = tracks;
            this.resetIcons();
        });

        playlistService.getActiveTrack().subscribe(track => {
            this.activeTrack = track;
            this.titleService.setTitle("Now playing: " + track.title + " - " + track.artist);
            this.resetIcons();
            this.icon[this.getIndexOfActiveTrack()] = this.icon_playing;
        });

    }

    getTracks() {
        this.playlistService.getAllTracks();
    }

    playTrack(index) {
        this.playlistService.setActiveTrack(index);
        this.audioService.playStream(this.tracks[index].url, true, 0);
    }

    getIndexOfActiveTrack() {
        return this.playlistService.getIndexOfActiveTrack();
    }

    mouseOverItem(index) {
        if (index != this.getIndexOfActiveTrack())
        {
            this.icon[index] = this.icon_hover;
        }
    }

    mouseOutItem(index) {
        if (index != this.getIndexOfActiveTrack())
        {
            this.icon[index] = this.icon_default;
        }
    }

    removeTrack(index) {
        this.playlistService.removeTrack(index);
    }

    resetIcons() {
        for (var i = 0; i < this.tracks.length; i++)
        {
            this.icon[i] = this.icon_default;
        }
    }
}

