<div *ngIf="year" class="year-container">
    <div class="year-header"><mat-icon (click)="getYear(year.year - 1)">navigate_before</mat-icon><div class="year">{{ year.year }}</div><mat-icon (click)="getYear(year.year + 1)">navigate_next</mat-icon></div>
    <div class="month">
        <div *ngFor="let month of year.months" class="days">
            <div class="header">{{ month.name }}</div>
            <div>&nbsp;</div>
            <div class="day-header">M</div>
            <div class="day-header">T</div>
            <div class="day-header">O</div>
            <div class="day-header">T</div>
            <div class="day-header">F</div>
            <div class="day-header">L</div>
            <div class="day-header red">S</div>
            <ng-container *ngFor="let week of month?.weeks">
                <div class="week-number">{{ week.days[0].week }}</div>
                <div *ngFor="let i of [].constructor(week.days[0].wday - 1);" class="day"></div>
                <div *ngFor="let day of week.days" [ngClass]="{'red' : day.wday == 7 || day.holiday != '', 'underline' : day.holiday != ''}" matTooltip="{{day.holiday}}" matTooltipClass="tooltipClass" class="day">
                    {{ day.day }}
                </div>
            </ng-container>
        </div>
    </div>
</div>